import React from "react";
import { TextField, Box, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const InputText = ({
  label,
  type,
  disabled = false,
  InputProps = {},
  onChange,
  error,
  value,
  name,
  firstUpperCase,
  variant,
  step,
  negativeAvailable,
  placeholder,
  setValue,
  subLabel = "",
  ...rest
}) => {
  const { t } = useTranslation();

  const updateValue = (value) => {
    if (type === "text") {
      return firstUpperCase
        ? value
          .trimStart()
          .split(" ")
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(" ")
        : value?.trimStart();
    }

    if (type === "tel") {
      return value.replace(/[-]/g, "");
    }


    if (type === "customTime") {
      const days = Math.floor(value / 86400);
      value %= 86400;
      const hours = Math.floor(value / 3600);
      value %= 3600;
      const minutes = Math.floor(value / 60);
      value = `${days}дн ${hours}ч ${minutes}мин`;

      return value;
    }

    return value;
  };

  const newValue = updateValue(value);
  return (
    <Box sx={{ position: "relative" }} className="input_text">
      <InputLabel sx={{ mb: label ?"7px" : "0px", pb: '1px' }} htmlFor={name}>
        {t(label)}
      </InputLabel>
      <TextField
        type={type === "number" ? "text" : type}
        onChange={onChange}
        placeholder={t(placeholder) || ""}
        variant={variant || "outlined"}
        error={!!error && !disabled}
        helperText={error?.message ? t(`error_message:${error?.message}`) : ""}
        fullWidth
        value={newValue}
        disabled={disabled}
        InputProps={InputProps}
        InputLabelProps={{ shrink: true, }}
        {...rest}
      />

    </Box>
  );
};

export default InputText;
