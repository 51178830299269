const CreditIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.75C0 0.783502 0.783502 0 1.75 0H18.25C19.2165 0 20 0.783502 20 1.75V3H0V1.75Z" fill="#4D5C6E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 14.25C0 15.2165 0.783502 16 1.75 16H18.25C19.2165 16 20 15.2165 20 14.25V6H0V14.25ZM2 13C2 12.4477 2.44772 12 3 12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H3C2.44772 14 2 13.5523 2 13ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12H8Z"
        fill="#4D5C6E"
      />
    </svg>
  );
};

export default CreditIcon;
