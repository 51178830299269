import { Box, Input, Typography, Button } from "@mui/material";
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import React, { useState } from "react";
import { getEquipmentIcon } from "../../../../helpers/getEquipmentIcon";
import AccessibleIcon from "@mui/icons-material/Accessible";
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const {
    id,
    itemId,
    selectLesson,
    label,
    activeId,
    expandedItems,
    paddingItems,
    disabled,
    children,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  const isSelected = expandedItems?.length > 0 && expandedItems?.includes(itemId)
  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content {...getContentProps()}  style={{ padding: 0,paddingLeft:paddingItems,paddingRight:"8px",width:"396px",backgroundColor:isSelected ? "#EEF4FD" : "white",color:isSelected ? "var(--main)" : "#4D5C6E",borderRadius:isSelected ? "32px" : "0px"}}>
          <Box
            onClick={selectLesson}
            sx={{
              flexGrow: 1,
              display: "flex",
              placeItems: "center",
              fontWeight: "500",
              justifyContent: "space-between",
              minHeight: "40px",
              gap: 1,
            }}
          >
            <TreeItem2Label sx={{ alignContent: "center" }} {...getLabelProps()} />
            <TreeItem2IconContainer {...getIconContainerProps()} sx={{ alignSelf: "center" }}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
          </Box>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem;
