import { fileSizeCheck, fileTypeCheck } from "../../../../validation";
import DatePickerField from "../../../components/DatePickerField";
import InputFileSingle from "../../../components/InputFile/InputFileSingle";
import InputText from "../../../components/InputText";
import SelectSimple from "../../../components/SelectSimple";

export const formFields = {
  date: "date",
  title: "title",
  content: "content",
  city: "city",
  type: "type",
  priority: "priority",
  image: "image"
};

const selectTypeOption = [
  { name: "Прогноз", value: 1 },
  { name: "На контроле", value: 2 },
];

const selectPriorityOption = [
  { name: "Важная", value: 1 },
  { name: "Обычная", value: 2 },
];

export const formConfigBasicInformation = [
  {
    name: formFields.date,
    label: "Дата новости",
    type: "text",
    sx: { width: "100%" },
    rules: { required: true },
    input: (props) => <DatePickerField {...props} />,
  },
  {
    name: formFields.city,
    label: "Город",
    placeholder: "Введите город",
    type: "text",
    sx: { width: "100%" },
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.title,
    label: "Заголовок",
    placeholder: "Введите заголовок",
    type: "text",
    sx: { width: "100%" },
    rules: { required: true },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.priority,
    label: "Приоритет информации",
    type: "text",
    rules: { required: true },
    input: (props) => (
      <SelectSimple {...props} options={selectPriorityOption} />
    ),
  },
  {
    name: formFields.image,
    label: "Изображение для новости",
    type: "file",
    canDeleteFile: true,
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = ["image/jpeg", "image/png"];
          return fileTypeCheck(
            files?.filesToAdd,
            formFields.image,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files?.filesToAdd,
            formFields.image,
            maxAvailableFileSize
          );
        },
      },
    },
    input: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg"} />,
  },
];

export const formConfigOperativeInformation = [
  {
    name: formFields.type,
    label: "Тип информации",
    type: "text",
    rules: { required: true },
    input: (props) => <SelectSimple {...props} options={selectTypeOption} />,
  },
  {
    name: formFields.date,
    label: "Дата новости",
    type: "text",
    sx: { width: "100%" },
    rules: { required: true },
    input: (props) => <DatePickerField {...props} />,
  },
];
