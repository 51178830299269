import { ClickableNameCell } from "./Components";
import { SimpleHeader } from "../TasksList/Components";
import { Box } from "@mui/material";
import { formFieldsTable as formFields } from "./form-config";
import {
  rolesOptions,
  typeOptions,
  activeOptions,
  verifiedOptions,
} from "./options";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import {
  BlockAvatar,
  ClickableActionNameCell,
  ClickableActionListCell,
  SimpleTextCell,
  SimpleActiveTextCell,
  SelectSimpleEquipmentKinds,
  SimpleBlockTextCell,
} from "./Components";
import { t } from "i18next";
import FormItem from "../../components/FormItem";
import { Verified } from "@mui/icons-material";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import { SelectSimpleObject } from "../EquipmentList/Components";

export const getColumns = ({
  control,
  columnsFromStorage,
  // organizationTypeId,
  // handleChange,
  // activeValue,
  // changeactiveValue,
  // verifiedValue,
  // changeverifiedValue,
  searchName,
  setsearchName,
  organizationSearch,
  setorganizationSearch,
  employeePosition,
  setemployeePosition,
  login,
  setLogin,
  email,
  setEmail,
  // searchRole,
  // setsearchRole,
  rows,
  writeSearchDataToLocalStorage
}) =>
  [
    {
      id: formFields.avatarLink,
      numeric: false,
      enableSorting: false,
      label: "",
      styleTableCell: {
        verticalAlign: "top",
      },
      clickable: false,
      isVisible: columnsFromStorage[formFields.avatarLink],
      RowCell: ({ row }) => <BlockAvatar data={row[formFields.avatarLink]} />,
      HeaderCell: () => (
        <div
          style={{
            height: "50px",
            color: "var(--headers)",
            fontSize: "16px",
            fontWeight: "500",
          }}
        ></div>
      ),
    },
    {
      id: formFields.employeeFullName,
      numeric: false,
      enableSorting: false,
      label: t("label:FIO_employee"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible: columnsFromStorage[formFields.employeeFullName],
      RowCell: (props) => <ClickableActionNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.employeeFullName}`}
          sx={{
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("label:fullName")}
              className="select_in_built small-light"
              searchValue={searchName}
              changeSearchValue={setsearchName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue='searchName'
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.isActive,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isActive"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isActive],
      RowCell: (props) => <SimpleActiveTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.isActive}`}
          label={t("tableHeader:isActive")}
          type={"select-text"}
          options={activeOptions}
          input={(props) => (
            <Box
              className="medium_container"
              style={{
                padding: "13px 0",
                width: "100%",
              }}
            >
              <SelectSimpleInbuilt
                className="select_in_built small-light"
                {...props}
              />
            </Box>
          )}
        />
      ),
    },
    {
      id: formFields.employeeLogin,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeLogin"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "150px",
      isVisible:
        columnsFromStorage[formFields.employeeLogin] &&
        !rows?.hideEmployeeLogin,
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.employeeLogin}`}
          sx={{
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("signature:login")}
              className="select_in_built small-light"
              searchValue={login}
              changeSearchValue={setLogin}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.employeeEmail,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:employeeEmail"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "160px",
      isVisible: columnsFromStorage[formFields.employeeEmail],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.employeeEmail}`}
          sx={{
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:employeeEmail")}
              className="select_in_built small-light"
              searchValue={email}
              changeSearchValue={setEmail}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.employeeRoleName,
      numeric: false,
      enableSorting: false,
      label: t("label:role"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "200px",
      isVisible: columnsFromStorage[formFields.employeeRoleName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.employeeRoleName}`}
          label={t("label:role")}
          type={"select-text"}
          options={rolesOptions}
      
          input={(props) => (
            <Box
              className="medium_container"
              style={{
                width: "100%",
              }}
            >
              <SelectSimpleInbuilt
                className="select_in_built small-light"
                {...props}
              />
            </Box>
          )}
        />
      ),
    },
    {
      id: formFields.employeePosition,
      numeric: false,
      enableSorting: false,
      label: t("label:position"),
      styleTableCell: {
        verticalAlign: "top",
      },
      clickable: false,
      size: '200px',
      isVisible: columnsFromStorage[formFields.employeePosition],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.employeePosition}`}
          sx={{
            padding: "9px 0 9px 16px",
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("label:position")}
              className="select_in_built small-light"
              searchValue={employeePosition}
              changeSearchValue={setemployeePosition}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.employeeFacilities,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "260px",
      isVisible: columnsFromStorage[formFields.employeeFacilities],
      RowCell: (props) => <ClickableActionListCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label= {t("label:object")}
          name={`${formFields.searchParams}.FacilityId`}
          sx={{ padding: "13px 0 13px 16px", width: '100%',color:"#4D5C6E !important" }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.employeeEquipmentKinds,
      numeric: false,
      enableSorting: false,
      label: t("label:objectsTypes"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "250px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.employeeEquipmentKinds],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.equipKinds`}
          sx={{ padding: "13px 0 13px 16px", width: '100%' }}
          label= {t("label:equipKinds")}
          input={(props) => (
            <SelectSimpleEquipmentKinds
              {...props}
              isTableheader={true}
              className="select_in_built small-light"
              sx={{  }}
            />
          )}
        />
      ),
    },
    {
      id: formFields.organizationType,
      numeric: false,
      enableSorting: false,
      label: t("label:organizationType"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "220px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.organizationType],
      RowCell: (props) => <SimpleBlockTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.organizationType}`}
          label={t("tableHeader:organizationType")}
          type={"select-text"}
          options={typeOptions}
          sx={{
            width: "220px",
          }}
          input={(props) => (
            <SelectSimpleInbuilt
              placeholder={t("tableHeader:organizationType")}
              className="select_in_built small-light"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.organizations,
      numeric: false,
      enableSorting: false,
      label: t("label:organizationName"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "250px",
      isVisible: columnsFromStorage[formFields.organizations],
      RowCell: (props) => <ClickableActionListCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.organizations}`}
          sx={{
            padding: "9px 0 9px 16px",
            borderLeft: "none",
            width: "100%",
            borderRight: "none",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("label:organizationName")}
              className="select_in_built small-light"
              searchValue={organizationSearch}
              changeSearchValue={setorganizationSearch}
              {...props}
            />
          )}
        />
      ),
    },

    {
      id: formFields.isVerified,
      numeric: false,
      enableSorting: false,
      label: t("tableHeader:isVerified"),
      styleTableCell: {
        verticalAlign: "top",
      },
      size: "210px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.isVerified],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.isVerified}`}
          label={t("tableHeader:isVerified")}
          type={"select-text"}
          options={verifiedOptions}
          input={(props) => (
            <Box
              className="medium_container"
              style={{
                width: "210px",
              }}
            >
              <SelectSimpleInbuilt
                className="select_in_built small-light"
                {...props}
              />
            </Box>
          )}
        />
      ),
    },
    {
      id: formFields.tasks,
      numeric: false,
      label: t("tableHeader:tasks"),
      size: 80,
      isVisible: columnsFromStorage[formFields.tasks],
      mode: "diagram",
      clickable: false,
      HeaderCell: () => (
        <div
          style={{
            color: "var(--headers)",
            fontSize: "16px",
            borderLeft: "none",
            textAlign: 'center'
          }}
        >
          {t("tableHeader:tasks")}
        </div>
      ),
    },
    {
      id: formFields.edit,
      numeric: false,
      enableSorting: false,
      label: "",
      size: 50,
      mode: "edit",
      isVisible: true,
      clickable: false,
      HeaderCell: () => (
        <div
          style={{
            padding: "25px 16px",
            color: "var(--headers)",
            fontSize: "16px",
            borderLeft: "none",
          }}
        ></div>
      ),
    },
  ].filter(({ isVisible }) => isVisible);
