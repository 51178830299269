import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ObjectSettingsListItem from "./ObjectSettingsListitem";
import {
  useGetFacSystemSettingsQuery,
  useUpdateFacSystemSettingsMutation,
} from "../../../middleware/facilitiesApi";
import { useAction } from "../../../hooks/actions";
import closeicon from "../../../image/icons/closeInputFile.png"

const ObjectSettings = ({ open, onClose, objectId, objectName }) => {
  const [objectSystemsSettings, setobjectSystemsSettings] = useState(null);
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    getValues,
    watch,
    trigger,
    resetField,
  } = useForm({
    mode: "onSubmit",
  });
  const onSubmit = async (data) => {
    //let newArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
    console.log("objectSystemsSettings", objectSystemsSettings);
    editSystemSettings({
      facilityId: objectId,
      facilitySettings: objectSystemsSettings,
    }).then(() => {
      refetch();
      onClose();
    });
  };
  const [
    editSystemSettings,
    {
      error: errorEditSystemSettings,
      isSuccess: isSuccessEditSystemSettings,
      isLoading: isLoadingEditSystemSettings,
    },
  ] = useUpdateFacSystemSettingsMutation();
  const {
    data: objectSystems,
    isLoading: isLoadingObject,
    refetch,
  } = useGetFacSystemSettingsQuery(objectId, {
    skip: !objectId,
  });

  useEffect(() => {
    if (objectSystems) {
      if (!localStorage.getItem("systemSettingsAccord")) {
        localStorage.setItem(
          "systemSettingsAccord",
          JSON.stringify(
            objectSystems.map((system) => ({ id: system.id, isOpen: false }))
          )
        );
      } else {
        const mergedMap = new Map();
        JSON.parse(localStorage.getItem("systemSettingsAccord")).forEach(
          (obj) => {
            if (!mergedMap.has(obj.id)) {
              mergedMap.set(obj.id, obj);
            }
          }
        );
        /* objectSystems.forEach(obj => {
                    if (!mergedMap.has(obj.id)) {
                      mergedMap.set(obj.id, { id: obj.id, isOpen: false });
                    }
                  });*/
        console.log("mergedMap", mergedMap);
        localStorage.setItem(
          "systemSettingsAccord",
          JSON.stringify(Array.from(mergedMap))
        );
      }
      setobjectSystemsSettings(objectSystems);
    }
  }, [objectSystems]);
  console.log(
    "localStorage",
    JSON.parse(localStorage.getItem("systemSettingsAccord"))
  );
  useEffect(() => {
    setIsErrorSubmit(errorEditSystemSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorEditSystemSettings]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessEditSystemSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEditSystemSettings]);

  if (
    isLoadingObject ||
    isLoadingEditSystemSettings ||
    !objectSystemsSettings
  ) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="modal_address"

      >
        <Box className="modal_equipdialog-body" sx={{ width: "604px !important" }}>
          <Box>
            <Box className="modal_address-header_right">
              <Typography className="modal_address-body-title">
                {objectName}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>

          </Box>
          {objectSystemsSettings && objectSystemsSettings?.length > 0 ?
            <form id="myform"
              onSubmit={handleSubmit(onSubmit)} >
              <Box className="task_dialog-header" sx={{ justifyContent: "right !important" }}>
                <Button
                  form="myform"
                  className="btn btn-primary-fill"
                  type="submit"
                  disabled={isSuccessEditSystemSettings && !errorEditSystemSettings}
                >
                  {t("button:save")}
                </Button>
              </Box>
              <Box className="main-content_wrapper" sx={{ marginTop: "20px" }}>


                <List
                  sx={{ width: '600px', height: "calc(80vh - 250px)", overflowY: "auto", overflowX: "hidden", bgcolor: 'background.paper', border: "2px solid #bfbfc2", borderRadius: "10px" }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"

                >
                  {objectSystemsSettings && objectSystemsSettings.map((item) =>
                    <ObjectSettingsListItem control={control} item={item} objectId={objectId} objectSystemsSettings={objectSystemsSettings} setobjectSystemsSettings={setobjectSystemsSettings} getValues={getValues} />
                  )}


                </List>

              </Box>
            </form>
            :
            <Box >
              <Typography sx={{ placeContent: "center", textAlign: "center", fontSize: 40, fontFamily: "var(--INTER)", color: "gray", fontWeight: "500" }}>{"У объекта нет систем"}</Typography>
            </Box>


          }
        </Box>
      </Modal>
    );
  }

  

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modal_address"
    >
      <Box
        className="modal_equipdialog-body"
        sx={{ width: "604px !important" }}
      >
        <Box>
          <Box className="modal_address-header_right">
          <Box sx={{ width: "100%", alignContent: "center", textAlign: "center", ml: "40px" }}>
            <Typography className="modal_address-body-title">
              {objectName}
            </Typography>
            </Box>
            <IconButton onClick={onClose} sx={{marginBottom:"auto"}}>
              <Box
                component="img"
                src={closeicon}
                
              />
            </IconButton>
          </Box>
        </Box>
        {objectSystemsSettings && objectSystemsSettings?.length > 0 ? (
          <form id="myform" onSubmit={handleSubmit(onSubmit)}>
            <Box
              className="task_dialog-header"
              sx={{ justifyContent: "right !important" }}
            >
              <Button
                form="myform"
                className="btn btn-primary-fill"
                type="submit"
                disabled={
                  isSuccessEditSystemSettings && !errorEditSystemSettings
                }
              >
                {t("button:save")}
              </Button>
            </Box>
            <Box className="main-content_wrapper" sx={{ marginTop: "20px" }}>
              <List
                sx={{
                  width: "600px",
                  height: "calc(80vh - 400px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                  bgcolor: "background.paper",
                  border: "2px solid #bfbfc2",
                  borderRadius: "10px",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {objectSystemsSettings &&
                  objectSystemsSettings.map((item) => (
                    <ObjectSettingsListItem
                      control={control}
                      item={item}
                      objectId={objectId}
                      objectSystemsSettings={objectSystemsSettings}
                      setobjectSystemsSettings={setobjectSystemsSettings}
                      getValues={getValues}
                    />
                  ))}
              </List>
            </Box>
          </form>
        ) : (
          <Box>
            <Typography
              sx={{
                placeContent: "center",
                textAlign: "center",
                fontSize: 40,
                fontFamily: "var(--INTER)",
                color: "gray",
                fontWeight: "500",
              }}
            >
              {"У объекта нет систем"}
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ObjectSettings;
