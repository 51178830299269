import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import DateRangePickerNew from "../../../components/DateRangePickerNew/DateRangePickerNew";
import DownloadIconGrid from "../../../components/icons/Cabinet/DownloadIconGrid";
import PrintIconGrid from "../../../components/icons/Cabinet/PrintIconGrid";
import { dateFormat } from "../../../../constants";

export const PAID = {
  false: { bgColor: "var(--errorBg)", textColor: "var(--error)" },
  true: { bgColor: "var(--succesBg)", textColor: "var(--succes)" },
};

export const DateRangePicker = ({ placeholder, setValue, value }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (endDate != null) {
      if (startDate != null) setValue((prev) => [startDate, startDate]);
    }
  }, [endDate]);
  return (
    <DateRangePickerNew
      placeholder={placeholder}
      setValue={setValue}
      value={value}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  );
};

export const SimpleTextCell = ({ item, type, ...props }) => {
  console.log("type", item);
  const content = type === "date" ? dayjs(item).format(dateFormat) : type === "price" ? `${item} ₽` : item;

  return <Typography>{content}</Typography>;
};
export const WorkCompletedDownload = ({ row }) => {
  const { workCompletedActNumber, } = row;

  function downloadFile(fileName) {
    const a = document.createElement("a");
    a.href = fileName;
    a.target = "_blank";
    a.download = workCompletedActNumber?.number; // Укажите имя файла, если необходимо
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function printFile(fileName) {
    const printWindow = window.open(fileName); // Открываем файл в новом окне
    printWindow.onload = function () {
      printWindow.print(); // Открываем диалог печати после загрузки
    };
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography>{workCompletedActNumber?.number}</Typography>
      {workCompletedActNumber?.link && <Box display="flex" gap="24px">
        <Box
          sx={{
            cursor: "pointer",
            transition: ".2s ease-out",
            "&:hover": { color: "var(--accent)", transition: ".2s ease-out" },
          }}
          onClick={() => printFile(workCompletedActNumber?.link)}
        >
          <PrintIconGrid />
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            transition: ".2s ease-out",
            "&:hover": { color: "var(--accent)", transition: ".2s ease-out" },
          }}
          onClick={() => downloadFile(workCompletedActNumber?.link)}
        >
          <DownloadIconGrid />
        </Box>
      </Box>
      }
    </Box>
  );
};

export const SimpleHeader = ({ name }) => (
  <Box className="table-head-up_level">
    <Typography className="standart">{name || ""}</Typography>
  </Box>
);

export const PriorityCell = ({ item }) => {
  const { bgColor, textColor } = PAID[item] || {};

  return (
    <Box className="point_and_text_cell">
      <Typography className="point_and_text_cell-text" sx={{ color: textColor, backgroundColor: bgColor }}>
        {item ? "Оплачено" : "Не оплачено"}
      </Typography>
    </Box>
  );
};
