import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants";

export const MenuItemLink = ({ label, link, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button
      href={link}
      target="_blank"
      {...rest}
    >
      {t(label)}
    </Button>
  );
};
export const MenuItemLinkInside = ({ label, link, ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      onClick={()=>navigate(PATHS.INFO_CENTER)}
      {...rest}
    >
      {t(label)}
    </Button>
  );
};

export const MenuItemPopupHandler = ({label, Popup, ...rest}) => {
  const {t} = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);

  const handleOnClick = () => setOpenPopup(!openPopup);

  return (
    <div className="header-navbar-item">
      <Button
        onClick={handleOnClick}
        {...rest}
      >
        {t(label)}
      </Button>
      <Popup open={openPopup} setOpen={setOpenPopup}/>
    </div>
  );
}