const DefaultObjectIcon = () => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0312 12.4688H64.9687C64.9687 12.4688 71.5312 12.4688 71.5312 19.0312V64.9687C71.5312 64.9687 71.5312 71.5312 64.9687 71.5312H19.0312C19.0312 71.5312 12.4688 71.5312 12.4688 64.9687V19.0312C12.4688 19.0312 12.4688 12.4688 19.0312 12.4688Z"
        stroke="#DDDDDD"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.5938 32.1562C25.5937 33.018 25.7635 33.8714 26.0933 34.6676C26.4231 35.4638 26.9065 36.1873 27.5159 36.7966C28.1252 37.406 28.8487 37.8894 29.6449 38.2192C30.4411 38.549 31.2945 38.7187 32.1562 38.7187C33.018 38.7187 33.8714 38.549 34.6676 38.2192C35.4638 37.8894 36.1873 37.406 36.7966 36.7966C37.406 36.1873 37.8894 35.4638 38.2192 34.6676C38.549 33.8714 38.7187 33.018 38.7187 32.1562C38.7187 31.2945 38.549 30.4411 38.2192 29.6449C37.8894 28.8487 37.406 28.1252 36.7966 27.5159C36.1873 26.9065 35.4638 26.4231 34.6676 26.0933C33.8714 25.7635 33.018 25.5938 32.1562 25.5938C31.2945 25.5938 30.4411 25.7635 29.6449 26.0933C28.8487 26.4231 28.1252 26.9065 27.5159 27.5159C26.9065 28.1252 26.4231 28.8487 26.0933 29.6449C25.7635 30.4411 25.5937 31.2945 25.5938 32.1562Z"
        stroke="#DDDDDD"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.5312 51.8437L61.4053 41.7178C60.1747 40.4875 58.5058 39.7964 56.7656 39.7964C55.0255 39.7964 53.3566 40.4875 52.1259 41.7178L22.3125 71.5312"
        stroke="#DDDDDD"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DefaultObjectIcon;
