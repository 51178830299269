import CheckboxSimple from "../../../components/CheckboxSimple";
import InputMaskPhone from "../../../components/InputMaskPhone";
import InputText from "../../../components/InputText";
import SelectSimple from "../../../components/SelectSimple";

export const formFields = {
  name: "name",
  email: "email",
  phoneNumber: "phoneNumber",
  month: "month",
  isCompareWithProfile: "isCompareWithProfile",
};

const months = [
  {
    value: 1,
    name: "Январь",
  },
  {
    value: 2,
    name: "Февраль",
  },
  {
    value: 3,
    name: "Март",
  },
  {
    value: 4,
    name: "Апрель",
  },
  {
    value: 5,
    name: "Май",
  },
  {
    value: 6,
    name: "Июнь",
  },
  {
    value: 7,
    name: "Июль",
  },
  {
    value: 8,
    name: "Август",
  },
  {
    value: 9,
    name: "Сентябрь",
  },
  {
    value: 10,
    name: "Октябрь",
  },
  {
    value: 11,
    name: "Ноябрь",
  },
  {
    value: 12,
    name: "Декабрь",
  },
];

export const formConfigBasicInformation = [
  {
    name: formFields.isCompareWithProfile,
    label: "Данные совпадают с профилем",
    type: "text",
    sx: { width: "100%", color: "var(--white)" },
    rules: {},
    input: (props) => <CheckboxSimple {...props} />,
  },

  {
    name: formFields.name,
    label: "label:fullName",
    placeholder: "placeholder:fullName",
    type: "text",
    sx: { width: "100%" },
    rules: {required: true,},
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.email,
    label: "E-mail",
    placeholder: "placeholder:email",
    type: "text",
    sx: { width: "100%" },
    rules: {required: true, },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.phoneNumber,
    label: "label:phone",
    placeholder: "placeholder:phone",
    type: "number",
    rules: {required: true,},
    input: (props) => <InputMaskPhone {...props} />,
  },
  {
    name: formFields.month,
    label: "Желаемый месяц начала обучение",
    placeholder: "Выберите месяц",
    type: "text",
    displayEmpty: true,
    rules: {required: true,},
    input: (props) => <SelectSimple {...props} options={months} />,
  },
];
