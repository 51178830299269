import { Description } from "@material-ui/icons";

export const lessonTree = [
    {
        id:1,
        name:" Урок 1. Профиль и уведомления",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_1.mp4",
        description:"Тут описание к первому уроку",
        children:[]
    },
    {
        id:2,
        name:" Урок 2. Организации",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_2.mp4",
        description:"Тут описание к второму уроку",
        children:[]
    },
    {
        id:3,
        name:" Урок 3. Сотрудники",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_3.mp4",
        description:"Тут описание к третьему уроку",
        children:[]
    },
    {
        id:4,
        name:" Урок 4",
        link:"",
        description:"Тут описание к четвертому уроку",
        children:[]
    },
    {
        id:5,
        name:" Урок 5. Роли пользователей",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_5.mp4",
        description:"Тут описание к пятому уроку",
        children:[]
    },
    {
        id:6,
        name:" Урок 6. Оборудование",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_6.mp4",
        description:"Тут описание к шестому уроку",
        children:[]
    },
    {
        id:7,
        name:" Урок 7. Регламенты",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_7.mp4",
        description:"Тут описание к седьмому уроку",
        children:[]
    },
    {
        id:8,
        name:" Урок 8. Задачи",
        link:"https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_8.mp4",
        description:"Тут описание к восьмому уроку",
        children:[]
    },
]