import React from "react";

const BigGridIcon = ({ isActive }) => {
  return (

    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="24" height="17" rx="3" fill={isActive ? "#1876D2" : '#4D5C6E'} />
      <line y1="9" x2="24" y2="9" stroke="white" />
      <line x1="12" y1="17.5" x2="12" y2="0.5" stroke="white" />
    </svg>

  );
};

export default BigGridIcon;
