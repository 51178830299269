import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight } from '@material-ui/icons'
import { Box } from '@mui/material'
import React, { FC } from 'react'
import {
    BtnBulletList,
    BtnNumberedList,
    Separator,
    EditorProvider,
    Toolbar,
    Editor,
    createButton,
    createDropdown,
} from 'react-simple-wysiwyg'
import "./styles.css";

const TextEditor = ({ editorState, setEditorState, isEmptyEditoreText }) => {
    function onChange(e) {
        setEditorState(e.target.value)
    }

    const BtnUndo = createButton('Отменить', '↶', 'undo')
    const BtnRedo = createButton('Вернуть', '↷', 'redo')

    const BtnBold = createButton('Жирный', '𝐁', 'bold')
    const BtnItalic = createButton('Курсив', '𝑰', 'italic')
    const BtnUnderline = createButton('Подчеркивание', <span style={{ textDecoration: 'underline' }}>𝐔</span>, 'underline')
    const BtnStrikeThrough = createButton('Перечеркнутый текст', <s>ab</s>, 'strikeThrough')

    // export const BtnNumberedList = createButton('Нумерованный список', <OrderedListIcon />, 'insertOrderedList')

    const BtnClearFormatting = createButton('Очистить форматирование', 'T̲ₓ', 'removeFormat')
    const BtnLink = createButton('Ссылка', '🔗', ({ $selection }) => {
        if ($selection?.nodeName === 'A') {
            document.execCommand('unlink')
        } else {
            document.execCommand('createLink', false, prompt('URL', '') || undefined)
        }
    })

    const BtnAlignCenter = createButton('Выравнивание по центру', <FormatAlignCenter />, 'justifyCenter')
    const BtnAlignLeft = createButton('Выравнивание слева', <FormatAlignLeft />, 'justifyLeft')
    const BtnAlignRight = createButton('Выравнивание справа', <FormatAlignRight />, 'justifyRight')

    const FontSize = createDropdown('Размер шрифта', [
        ['12', 'fontSize', '2'],
        ['14', 'fontSize', '3'],
        ['16', 'fontSize', '4'],
        ['18', 'fontSize', '5'],
        ['20', 'fontSize', '6']
    ])
    return (
        <Box className={`text-editor ${isEmptyEditoreText ? "text-editor-error" : ''}`}>
            <EditorProvider>
                <Editor value={editorState} onChange={onChange}>
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />

                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnStrikeThrough />
                        <Separator />

                        <BtnNumberedList />
                        <BtnBulletList />
                        <Separator />

                        <BtnLink />
                        <BtnClearFormatting />
                        <Separator />

                        <BtnAlignRight />
                        <BtnAlignCenter />
                        <BtnAlignLeft />
                        <Separator />

                        <FontSize />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </Box>
    )
}

export default TextEditor;