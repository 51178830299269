import React from 'react';

export const useKeyPress = () => {
    React.useEffect(() => {
        const handleKeyPress = (event) => {
           
            if (event.key === 'Enter' && event.target.form && event.target.form?.id != "loginForm") {
                console.log("event.target.form",event.target.form?.id)
                const form = event.target.form;
                const index = Array.prototype.indexOf.call(form, event.target);
                
                // Поиск следующего элемента с tabIndex >= 0 или элементами типа select
                for (let i = index + 1; i < form.length; i++) {
                    if (form[i].tabIndex >= 0) {
                        form[i].focus();
                        break;
                    }
                }
                event.preventDefault(); // предотвращает отправку формы
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
};