import React from "react";

const PrintIconGrid = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 15.9H3C2.46957 15.9 1.96086 15.7104 1.58579 15.3728C1.21071 15.0353 1 14.5774 1 14.1V9.60005C1 9.12266 1.21071 8.66482 1.58579 8.32726C1.96086 7.98969 2.46957 7.80005 3 7.80005H19C19.5304 7.80005 20.0391 7.98969 20.4142 8.32726C20.7893 8.66482 21 9.12266 21 9.60005V14.1C21 14.5774 20.7893 15.0353 20.4142 15.3728C20.0391 15.7104 19.5304 15.9 19 15.9H17"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 7.8V2.4C5 2.28181 5.02587 2.16478 5.07612 2.05558C5.12638 1.94639 5.20003 1.84718 5.29289 1.7636C5.38575 1.68003 5.49599 1.61374 5.61732 1.56851C5.73864 1.52328 5.86868 1.5 6 1.5H16C16.2652 1.5 16.5196 1.59482 16.7071 1.7636C16.8946 1.93239 17 2.16131 17 2.4V7.8"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.3H16C16 12.3 17 12.3 17 13.2V18.6C17 18.6 17 19.5 16 19.5H6C6 19.5 5 19.5 5 18.6V13.2C5 13.2 5 12.3 6 12.3Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PrintIconGrid;
