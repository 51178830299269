import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box ,InputLabel} from "@mui/material";

import { dateFormat, dateFormatForSubmit } from "../../../constants";
import calendar from "../../../image/icons/calendar.png"

const CalendarIcon = () => (
  <img src={calendar} alt="Calendar" />
);
const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const handleStartDateChange = (date) => {
    const start = dayjs(date.$d)
    const end = dayjs(endDate)
    
    const differenceInYears = end.diff(start, 'year', true);
    console.log("newDate",differenceInYears)
    if (differenceInYears != 1) {
      const newDate = start.add(11, 'month'); 
      
      setEndDate(newDate.format(dateFormatForSubmit));
    }
    setStartDate(start.format(dateFormatForSubmit));
  };

  const handleEndDateChange = (date) => {
    setEndDate(dayjs(date.$d).format(dateFormatForSubmit));
  };
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]} sx={{padding:0,gap:"4px"}}>
          <Box sx={{ position: "relative" }} className="input_text">
            <InputLabel sx={{ mb: "8px",color:"var(--headers)",fontSize:"16px",fontWeight:500,maxWidth:"calc(100% - 45px) !important" }}>
              {"Начальная дата периода"}
            </InputLabel>
            <DatePicker
              value={startDate ? dayjs(startDate) : null}
              onChange={handleStartDateChange}
              maxDate={dayjs(endDate)}
              slots={{ openPickerIcon: CalendarIcon }}
              format={dateFormat}
            />
          </Box>
          <Box className="input_text" sx={{ position: "relative",marginLeft:0 }} >
            <InputLabel sx={{ mb: "8px",color:"var(--headers)",fontSize:"16px",fontWeight:500  }}>
              {"Конечная дата периода"}
            </InputLabel>
            <DatePicker
              value={endDate ? dayjs(endDate) : null}
              onChange={handleEndDateChange}
              minDate={dayjs(startDate)}
              maxDate={dayjs(startDate).add(11,"month").endOf('month')}
              slots={{ openPickerIcon: CalendarIcon }}
              format={dateFormat}
            />
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangePicker;
