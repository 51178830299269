const RatingCell = ({ percent, isSmall }) => {
  const getColorByPercent = (percent) => {
    if (percent >= 0 && percent <= 33) return "var(--error)";
    if (percent > 33 && percent <= 66) return "var(--accentYellow)";
    if (percent > 66) return "var(--succes)";
  };

  const containerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    height: isSmall ? "98px" : "236px",
    width: "10px",
    backgroundColor: "white",
    border: "1px solid #CFD9E8",
    borderRadius: "32px",
    overflow: "hidden",
  };

  const fillerStyle = {
    width: "100%",
    height: `${Math.round(+percent)}%`,
    background: `linear-gradient(to bottom , ${getColorByPercent(percent)}, ${percent < 33 ? "var(--error)" : "var(--accentYellow)"}, ${percent > 33 && percent < 67 ? "var(--accentYellow)" : "var(--accentYellow)"}, var(--error))`,
    borderRadius: "inherit",
    transition: "width 0.3s ease-in-out",
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyle}></div>
    </div>
  );
};

export default RatingCell;
