import React, { useMemo, useState, useEffect } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../hooks/redux";
import { useWatch } from "react-hook-form";
import { formFields } from "./options";
import SelectSimple from "../../../components/SelectSimple";
import { useLazyGetResponsibleOrganizationsUsersQuery,useGetResponsibleOrganizationsUsersQuery } from "../../../../middleware/usersApi";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import { getEquipmentTypeIdOptionsGroup } from "./helpers";
import {
  useLazyGetEquipmentGroupsQuery,
  useLazyGetEquipmentKindSelectElementsQuery,
  useLazyGetEquipmentsByGroupIdForSelectionQuery,
} from "../../../../middleware/equipmentsApi";
import SelectGroupingSystemPPZ from "../../../components/SelectGroupingSystemPPZ";
export const SimpleTextCell = ({ item }) => {
  const content = item;

  return <Typography className="standart_lg_text">{content}</Typography>;
};

export const ExecutiveUserNameSelectSimple = (props) => {
  const { control } = props;
  const facility = useWatch({
    control,
    name: formFields.ProtectionObjectId,
  });

  const [searchValue, setSearchValue] = useState("");
  const [executives, setexecutives] = useState("");
  

  const [ getRespOrg ] = useLazyGetResponsibleOrganizationsUsersQuery({
    searchValue: searchValue,
    filters: `&protectionObjectId=${facility}`,
  });
  useEffect(() => {
    if (facility) {
        getRespOrg({
          searchValue: searchValue,
          filters: `&protectionObjectId=${facility}`,
        })
        .then((res) => {
          if (res.data) {
            setexecutives(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [facility,searchValue]);
  
  const options = useMemo(() => {
    return executives
      ? executives.map(({ id, name }) => ({ value: id, name }))
      : [];
  }, [executives]);

  return (
    <SelectSimpleSearch
        {...props}
        disabled={executives ? false : true}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        options={options}
      />
  );
};

export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={facilitySelectElements} isSpecialEquipmenGroup={true} {...props} />;
};

export const SelectSimpleEquipmentsGroup = ({
  objectId,
  equipmentTypeIdJSON,
  floorInfoId,
  disabled,
  ...props
}) => {
  const [getEquipmentGroups] = useLazyGetEquipmentGroupsQuery();
  const [equipmentGroupIdList, setEquipmentGroupIdList] = useState([]);

  useEffect(() => {
    if (equipmentTypeIdJSON && objectId) {
      const { id: equipmentTypeId, parentId: equipmentKindId } =
        JSON.parse(equipmentTypeIdJSON);
      getEquipmentGroups(
        `protectionObjectId=${objectId}&equipmentKindId=${equipmentKindId}&equipmentTypeId=${equipmentTypeId}&floorInfoId=${floorInfoId}&calcCount=${true}`
      )
        .then((res) => {
          if (res.data) {
            setEquipmentGroupIdList(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [equipmentTypeIdJSON, objectId, getEquipmentGroups]);

  const options = useMemo(() => {
    if (equipmentGroupIdList) {
      return equipmentGroupIdList?.map((equipmentGroup) => {
        return {
          value: equipmentGroup.value,
          name: equipmentGroup.name,
          items: [],
          open: false,
        };
      });
    } else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentGroupIdList, setEquipmentGroupIdList]);

  return <SelectSimple options={options} isSpecialEquipmenGroup={true} disabled={disabled} {...props} />;
};

export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} isSpecialEquipmenGroup={true} {...props} />;
};

export const EquipmentTypeIdSelectGrouping = ({
  objectId,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const [getEquipmentKindSelectElements] =
    useLazyGetEquipmentKindSelectElementsQuery();

  useEffect(() => {
    objectId &&
      getEquipmentKindSelectElements({
        params: searchValue,
        useShortTitle: true,
        protectionObjectId: objectId,
        hasEquipmentOnly: true,
      })
        .then((res) => {
          setEquipmentSelectElements(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [searchValue, objectId, getEquipmentKindSelectElements]);

  const options = useMemo(() => {
    const filteredEquipmentSelectElements = equipmentSelectElements.filter(
      (item) => item.equipmentTypes.length > 0
    );
    return filteredEquipmentSelectElements
      ? getEquipmentTypeIdOptionsGroup(filteredEquipmentSelectElements)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentSelectElements, setEquipmentSelectElements]);

  return (
    <SelectGroupingSystemPPZ
      {...props}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      options={options}
      isSystemPPZ={true}
    />
  );
};

export const SelectEquipmentsName = ({
  equipmentGroupId,
  floorInfoId,
  error,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState('')

  const [getEquipmentKindSelectElements] =
    useLazyGetEquipmentsByGroupIdForSelectionQuery();

  const prepareOptionsForSelectInput = (options) => {
    return options.map((option) => {
      const { id, name, number } = option;
      return {
        value: id,
        name: `${name} ${number}`,
      };
    });
  };

  useEffect(() => {
    if (equipmentGroupId) {
      getEquipmentKindSelectElements({
        equipmentGroupId,
        floorInfoId,
        searchNumber: searchValue,
      })
        .then((res) => {
          if (res.data) {
            setOptions(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [equipmentGroupId, searchValue]);

  const onChange = (e) => {
    setSelectedEquipment(e.target.value)
  }

  return (
    <SelectSimpleSearch
      options={prepareOptionsForSelectInput(options)}
      searchValue={searchValue}
      value={selectedEquipment}
      error={error}
      onChange={onChange}
      setSearchValue={setSearchValue}
      isSpecialEquipmentNameReturn={true}
      {...props}
    />
  );
};
