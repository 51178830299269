import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateFormat, dateFormatWithTimeWithoutA } from "../../../constants";
import EquipmentDialog from "./EquipDialog";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import {
  useLazyGetEquipmentListQuery,
  useLazyPrintEquipmentListQuery,
} from "../../../middleware/equipmentsApi";
import EditButton from "../../components/EditButton";
import { ModalEquipSchema } from "../../components/Modals/ModalEquipSchema";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";
import { getColumns } from "./columns";
import { getDataToChart } from "../../../helpers/helper";
import { useLazyGetFloorOptionsSelectByFacilityQuery } from "../../../middleware/facilitiesApi";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import { formFields, getConfigFormModalSelect } from "./form-config";
import { CircularProgress } from "@mui/material";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import exporticon from "../../../image/icons/exportBtnWhite.png";
import PageheaderNav from "../../components/PageheaderNav";
import PrintIcon from "../../components/icons/PrintIcon";
import "dayjs/locale/ru";
import { dateFormatForSubmit } from "../../../constants";
import { getTableHieght } from "../../../helpers/getTableHieght";
import useScreenSize from "../../../hooks/useScreenSize";
import {
  base64toPDF,
  getQueryString,
  printBase64,
} from "../../../helpers/tableHelper";
import { NAV_ITEMS } from "./constants";

const EquipmentList = () => {
  const [getParams] = useSearchParams();
  const {
    searchNameStorage,
    searchReplacingEquipmentNameStorage,
    searchPlumeStorage,
    // searchIntegrationNumberStorage,
    searchNumberStorage,
    lastServiceDateRangeStorage,
    replacementDateRangeStorage,
    searchRoomAddressNameStorage,
    protectionObjectIdStorage,
    searchObjectIntegrationNumberStorage,
    floorIdStorage,
    defectActNumberStorage,
    // replacementTaskStorage,
    equipmentKindIdStorage,
    lifeTimeDateStorage,
  } = JSON.parse(sessionStorage.getItem("equipmentsListSearchParams")) || {};

  const objectId = getParams.has("objectId")
    ? getParams.get("objectId")
    : protectionObjectIdStorage || "";
  const equipmentGroupId = getParams.has("equipmentGroupId")
    ? getParams.get("equipmentGroupId")
    : null;

  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const { equipments } = useAppSelector((state) => state.equipments);
  const { control, setValue, watch, reset } = useForm({
    defaultValues: {
      [formFields.searchParams]: {
        [formFields.defectActNumber]: defectActNumberStorage || "",
        [formFields.floorId]: floorIdStorage || "",
        [formFields.protectionObjectId]: objectId,
        // [formFields.replacementTasks]: replacementTaskStorage || "",
        [formFields.equipmentKindId]: equipmentKindIdStorage || "",
        [formFields.explotationExceeded]: lifeTimeDateStorage || "",

        // [formFields.searchName]: searchNameStorage || "",
        // [formFields.searchNumber]: searchNumberStorage || "",
        // [formFields.searchRoomAddressName]: searchRoomAddressNameStorage || "",
        // [formFields.searchObjectIntegrationNumber]: searchObjectIntegrationNumberStorage || "",
        // [formFields.searchPlume]: searchPlumeStorage || "",
        // lastServiceDate: lastServiceDateRangeStorage || [null, null],
        // replacementDate: replacementDateRangeStorage || [null, null],
        // searchReplacingEquipmentName: searchReplacingEquipmentNameStorage || ""
      },
    },
  });
  const [pageNumber, changePageNumber] = useState(0);
  const [openSchema, setOpenSchema] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  const [searchName, setSearchName] = useState(searchNameStorage || "");
  const [searchReplacingEquipmentName, setSearchReplacingEquipmentName] =
    useState(searchReplacingEquipmentNameStorage || "");
  const [searchPlume, setSearchPlume] = useState(searchPlumeStorage || "");
  // const [searchIntegrationNumber, setSearchIntegrationNumber] = useState(
  //   searchIntegrationNumberStorage || ""
  // );
  const [searchNumber, setSearchNumber] = useState(searchNumberStorage || "");

  const [searchSystemPpz, setsearchSystemPpz] = useState("");
  const [searchRoomAddressName, setSearchRoomAddressName] = useState(
    searchRoomAddressNameStorage || ""
  );
  const [searchObjectIntegrationNumber, setSearchObjectIntegrationNumber] =
    useState(searchObjectIntegrationNumberStorage || "");
  const [lastServiceDateRange, setLastServiceDateRange] = useState(
    lastServiceDateRangeStorage?.filter(Boolean).length
      ? lastServiceDateRangeStorage
      : [null, null]
  );
  const [replacementDateRange, setReplacementDateRange] = useState(
    replacementDateRangeStorage?.filter(Boolean).length
      ? replacementDateRangeStorage
      : [null, null]
  );

  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);

  const [getUserGrid] = useLazyGetUserGridSettingQuery();
  const [getEquipmentTable, { isLoading,isFetching }] = useLazyGetEquipmentListQuery();
  const [getFloorOptions] = useLazyGetFloorOptionsSelectByFacilityQuery();
  const [printEquipmentList] = useLazyPrintEquipmentListQuery();

  const { data: gridcolumns } = useGetUserGridSettingQuery({
    gridSettingName: "EquipmentList",
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const protectionObjectId = watch(
    `${formFields.searchParams}.${formFields.protectionObjectId}`
  );

  const equipmentKindId = watch(
    `${formFields.searchParams}.${formFields.equipmentKindId}`
  );
  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage =
      JSON.parse(sessionStorage.getItem("equipmentsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem(
      "equipmentsListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
  }

  function clearFiltersHandler() {
    setSearchName("");
    setSearchReplacingEquipmentName("");
    setSearchPlume("");
    // setSearchIntegrationNumber("");
    setSearchNumber("");
    setSearchRoomAddressName("");
    setLastServiceDateRange([null, null]);
    setReplacementDateRange([null, null]);
    reset();
    sessionStorage.removeItem("equipmentsListSearchParams");
  }

  useEffect(() => {
    const filtersRes = {};
    if (!params) return;
    Object.keys(params)?.forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] != null)
        if (key === "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["kindId"] = Number(
              JSON.parse(params[key]).id.replace("parent", "")
            );
          } else {
            filtersRes["typeId"] = JSON.parse(params[key]).id;
          }
        } else if (key === "lifeTimeDate" || key === "workingCorrectly") {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else if (key === "lastServiceDate") {
          if (
            lastServiceDateRange[0] != null &&
            lastServiceDateRange[1] != null
          ) {
            filtersRes["lastServiceDateMin"] = dayjs(
              lastServiceDateRange[0]
            ).format(dateFormatForSubmit);
            filtersRes["lastServiceDateMax"] = dayjs(
              lastServiceDateRange[1]
            ).format(dateFormatForSubmit);
          }
        } else if (key === "replacementDate") {
          if (
            lastServiceDateRange[0] != null &&
            lastServiceDateRange[1] != null
          ) {
            filtersRes["replacementDateMin"] = dayjs(
              lastServiceDateRange[0]
            ).format(dateFormatForSubmit);
            filtersRes["replacementDateMax"] = dayjs(
              lastServiceDateRange[1]
            ).format(dateFormatForSubmit);
          }
        } else {
          filtersRes[key] = params[key];
        }
    });

    getEquipmentTable({
      PageNumber: pageNumber,
      filters: getQueryString(filtersRes),
    });
  }, [params]);

  useEffect(() => {
    const filtersRes = {};
    if (!params) return;
    Object.keys(params)?.forEach((key) => {
      if (params[key] != "все" && params[key] != "" && params[key] != null)
        if (key == "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["kindId"] = Number(
              JSON.parse(params[key]).id.replace("parent", "")
            );
          } else {
            filtersRes["typeId"] = JSON.parse(params[key]).id;
          }
        } else if (key == "explotationExceeded" || key == "workingCorrectly") {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else if (key == "lastServiceDate") {
          if (
            lastServiceDateRange[0] != null &&
            lastServiceDateRange[1] != null
          ) {
            filtersRes["lastServiceDateMin"] = dayjs(
              lastServiceDateRange[0]
            ).format(dateFormatForSubmit);
            filtersRes["lastServiceDateMax"] = dayjs(
              lastServiceDateRange[1]
            ).format(dateFormatForSubmit);
          }
        } else if (key == "replacementDate") {
          if (
            lastServiceDateRange[0] != null &&
            lastServiceDateRange[1] != null
          ) {
            filtersRes["replacementDateMin"] = dayjs(
              lastServiceDateRange[0]
            ).format(dateFormatForSubmit);
            filtersRes["replacementDateMax"] = dayjs(
              lastServiceDateRange[1]
            ).format(dateFormatForSubmit);
          }
        } else {
          filtersRes[key] = params[key];
        }
    });

    getEquipmentTable({
      PageNumber: pageNumber,
      filters: getQueryString(filtersRes),
    });
  }, [pageNumber]);

  useEffect(() => {
    writeSearchDataToLocalStorage("equipmentKindIdStorage", equipmentKindId);
  }, [equipmentKindId, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchNumber}`,
      searchNumber
    );
  }, [searchNumber, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchRoomAddressName}`,
      searchRoomAddressName
    );
  }, [searchRoomAddressName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchObjectIntegrationNumber}`,
      searchObjectIntegrationNumber
    );
  }, [searchObjectIntegrationNumber, setValue]);

  // useEffect(() => {
  //   writeSearchDataToLocalStorage("protectionObjectIdStorage", objectId);
  //   setValue(
  //     `${formFields.searchParams}.${formFields.protectionObjectId}`,
  //     objectId
  //   );
  // }, [objectId, setValue]);

  useEffect(() => {
    if (equipmentGroupId) {
      setValue(
        `${formFields.searchParams}.${formFields.equipmentGroupId}`,
        equipmentGroupId
      );
    } else {
      getFloorOptions(protectionObjectId).then(
        ({ data, isSuccess }) =>
          isSuccess &&
          !!data?.length &&
          setValue(`${formFields.searchParams}.${formFields.floorId}`, "все")
      );
    }
  }, [equipmentGroupId, setValue, protectionObjectId]);

  useEffect(() => {
    writeSearchDataToLocalStorage(
      "protectionObjectIdStorage",
      protectionObjectId
    );
  }, [protectionObjectId]);

  useEffect(() => {
    writeSearchDataToLocalStorage(
      "replacementDateRangeStorage",
      replacementDateRange
    );
    setValue(
      `${formFields.searchParams}.replacementDate`,
      replacementDateRange
    );
  }, [replacementDateRange, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage(
      "lastServiceDateRangeStorage",
      lastServiceDateRange
    );
    setValue(
      `${formFields.searchParams}.lastServiceDate`,
      lastServiceDateRange
    );
  }, [lastServiceDateRange, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchName}`,
      `${searchName}`
    );
  }, [searchName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchReplacingEquipmentName}`,
      `${searchReplacingEquipmentName}`
    );
  }, [searchReplacingEquipmentName, setValue]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.searchPlume}`,
      `${searchPlume}`
    );
  }, [searchPlume, setValue]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        searchNumber,
        setSearchNumber,
        // searchIntegrationNumber,
        // setSearchIntegrationNumber,
        searchRoomAddressName,
        setSearchRoomAddressName,
        searchObjectIntegrationNumber,
        lastServicedaterange: lastServiceDateRange,
        setlastServicedaterange: setLastServiceDateRange,
        replacementDatedaterange: replacementDateRange,
        setreplacementDatedaterange: setReplacementDateRange,
        setSearchObjectIntegrationNumber,
        searchName,
        setSearchName,
        setsearchSystemPpz,
        searchSystemPpz,
        searchReplacingEquipmentName,
        setSearchReplacingEquipmentName,
        searchPlume,
        setSearchPlume,
        objectId,
        writeSearchDataToLocalStorage,
        equipmentKindIdStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage, lastServiceDateRange,replacementDateRange,searchSystemPpz]
  );

  const rows = useMemo(() => {
    const { data } = equipments;
    return data?.length
      ? data.map(
          ({
            id,
            name,
            number,
            jobChart,
            lifeTimeDate,
            lefeTimeOutdated,
            objectIntegrationNumber,
            roomAddressName,
            floorNumber,
            floorComment,
            workingProperly,
            lastServiceDate,
            integrationNumber,
            plume,
            protectionObjectId,
            protectionObjectName,
            enableForEdit,
            equipmentKindName,
            replacementDate,
            replacingEquipmentId,
            replacingEquipmentName,
            isReplaced,
          }) => {
            return {
              id,
              integrationNumber,
              number,
              objectIntegrationNumber,
              roomAddressName,
              name: {
                id,
                name,
                action: () => handleOpenEquipment(id),
              },
              replacingEquipment: {
                replacingEquipmentId,
                replacingEquipmentName,
                action: () => handleOpenEquipment(replacingEquipmentId),
              },
              plume,
              floorNumber: {
                id,
                floorNumber,
                floorComment,
                action: () => handleOpenSchema(id),
              },
              protectionObjectName,
              lifeTimeDate: (
                <Typography
                  sx={{ color: lefeTimeOutdated ? "var(--error)" : "black" }}
                >
                  {lifeTimeDate ? dayjs(lifeTimeDate).format(dateFormat) : ""}
                </Typography>
              ),
              lastServiceDate: lastServiceDate ? (
                <Typography sx={{ color: "var(--succes)" }}>
                  {dayjs(lastServiceDate).format(dateFormat)}
                </Typography>
              ) : (
                <></>
              ),
              replacementDate: replacementDate ? (
                <Typography sx={{ color: "var(--error)" }}>
                  {dayjs(replacementDate).format(dateFormatWithTimeWithoutA)}
                </Typography>
              ) : (
                <></>
              ),
              workingProperly: workingProperly ? (
                <Typography sx={{ color: "var(--succes)" }}>Да</Typography>
              ) : (
                <Typography sx={{ color: "var(--error)" }}>Нет</Typography>
              ),
              isReplaced: isReplaced ? (
                <Typography sx={{ color: "var(--error)" }}>Да</Typography>
              ) : (
                <Typography sx={{ color: "var(--succes)" }}>Нет</Typography>
              ),
              equipmentKindName,
              edit: enableForEdit ? (
                <EditButton
                  handleClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))}
                />
              ) : (
                <></>
              ),
              tasks: getDataToChart({
                jobChart,
                action: `?facilityName=${protectionObjectId}&equipmentId=${id}&equipmentName=${name}`,
              }),
            };
          }
        )
      : [];
  }, [equipments]);

  const handleOpenSchema = (id) => {
    setOpenSchema(true);
    setSelectedEquipId(id);
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const recordsCount = useMemo(
    () => equipments && equipments?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [equipments]
  );
  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  const printEquipmentListWrapper = (isPrint) => {
    if (isPrint) setPrintLoading(true);
    else setLoading(true);
    if (params.equipmentKindId === "все" || !params.equipmentKindId)
      delete params.equipmentKindId;
    else if (JSON.parse(params.equipmentKindId)?.isType) {
      params["kindId"] = Number(
        JSON.parse(params.equipmentKindId).id.replace("parent", "")
      );
    } else {
      params["typeId"] = JSON.parse(params.equipmentKindId).id;
    }
    if (params.floorId === "все") delete params.floorId;
    if (params.protectionObjectId === "все" || !params.protectionObjectId)
      delete params.protectionObjectId;
    if (lastServiceDateRange[0] != null && lastServiceDateRange[1] != null) {
      params.lastServiceDateMin = dayjs(lastServiceDateRange[0]).format(
        dateFormatForSubmit
      );
      params.lastServiceDateMax = dayjs(lastServiceDateRange[1]).format(
        dateFormatForSubmit
      );
    }
    if (replacementDateRange[0] != null && replacementDateRange[1] != null) {
      params.replacementDateMin = dayjs(replacementDateRange[0]).format(
        dateFormatForSubmit
      );
      params.replacementDateMax = dayjs(replacementDateRange[1]).format(
        dateFormatForSubmit
      );
    }
    delete params.lastServiceDate;
    delete params.replacementDate;
    printEquipmentList({
      ...params,
      pageNumber,
    }).then((data) => {
      // const res = data.data;
      if (isPrint) printBase64(data.data.content, setPrintLoading);
      else base64toPDF(data.data.name, data.data.content, setLoading);
    });
  };

  return (
    <Box className="template_page">
      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}
        />
      )}
      {openSchema && (
        <ModalEquipSchema
          open={openSchema}
          setOpen={setOpenSchema}
          control={control}
          setValue={setValue}
          equipId={selectedEquipId}
          refetch={() => getEquipmentTable({ pageNumber })}
        />
      )}

      <Typography className="title-page">
        {t("title_page:digitization")}
      </Typography>
      <Box className="template_page-header">
        <PageheaderNav itemsNav={NAV_ITEMS} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {printloading ? (
            <Box className="full_screen_icon-wrapper">
              <CircularProgress
                className="full_screen_icon"
                sx={{
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                  marginTop: "5px",
                }}
              />
            </Box>
          ) : (
            <Box className="full_screen_icon-wrapper">
              <div
                onClick={() => printEquipmentListWrapper(true)}
                style={{
                  marginTop: "5px",
                  border: "0px !important",
                }}
                className="full_screen_icon"
              >
                <PrintIcon />
              </div>
            </Box>
          )}

          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printEquipmentListWrapper(false)}
            disabled={loading}
            sx={{ height: "49px" }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                <Box component="img" src={exporticon} sx={{ mr: "16px" }} />
                {t("button:create_act_pdf")}
              </>
            )}
          </Button>
          {/*   <Button
                className="btn btn-outlined-primary"
                variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => {
              navigate(PATHS.ADD_EQUIPMENT);
            }}
          >
            {t("button:add_equipment")}
          </Button>
          */}
          <Button
            className="btn btn-primary-fill"
            onClick={clearFiltersHandler}
          >
            Очистить все фильтры
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box component="img" src={settingsicon} sx={{ mr: "16px" }} />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={isLoading || isFetching}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={getTableHieght(screenSize).min}
        maxHeight={getTableHieght(screenSize).max}
      />
      {openSelectModal && (
        <ModalSelectColumnsServer
          open={openSelectModal}
          setOpen={setOpenSelectModal}
          data={columnsFromStorage}
          refetch={() => getUserGrid({ gridSettingName: "EquipmentList" })}
          nameStorage={"EquipmentList"}
          config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
        />
      )}
    </Box>
  );
};

export default EquipmentList;
