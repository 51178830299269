import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { GraduationIcon } from "../../../components/icons/InfoCenter/GraduationIcon";
import { formConfigBasicInformation, formFields } from "./form-config";
import FormItem from "../../../components/FormItem";
import { useForm } from "react-hook-form";
import {
  useAddRegisterToLearningMutation,
} from "../../../../middleware/usersApi";
import { useAction } from "../../../../hooks/actions";
import "./styles.css";

const TrainingApplication = ({ profileData }) => {
  const {firstName, lastName, surname, email, phone} = profileData || {};
  const { t } = useTranslation();
  const [addRegisterToLearning, { isLoading }] =
    useAddRegisterToLearningMutation();

  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const isCompareDataWithProfile = watch(formFields.isCompareWithProfile);

  useEffect(() => {
    if (!isCompareDataWithProfile) {
      setValue(formFields.name, "");
      setValue(formFields.email, "");
      setValue(formFields.phoneNumber, "");
      return;
    }

    setValue(formFields.name, `${firstName} ${lastName} ${surname}`);
    setValue(formFields.email, email);
    setValue(formFields.phoneNumber, phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompareDataWithProfile]);

  function onSubmit(data) {
    delete data.isCompareWithProfile;
    addRegisterToLearning(data).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
      } else {
        setIsSuccessSubmit(true);
        reset();
      }
    });
  }

  return (
    <Box className="application">
      <Box className="application-header">
        <Typography>Обучение</Typography>
        <GraduationIcon />
      </Box>
      <form
        className="application-form"
        id="addApplication"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography className="application-form-title">
          Подайте заявку на бесплатное онлайн-обучение
        </Typography>
        {formConfigBasicInformation.map(
          ({ name, label, input, ...rest }, i) => (
            <FormItem
              key={name}
              control={control}
              errors={errors}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          )
        )}

        <Button
          form="addApplication"
          className="btn add-application"
          type="submit"
          disabled={isLoading}
        >
          {t("button:save")}
        </Button>
      </form>
    </Box>
  );
};

export default TrainingApplication;
