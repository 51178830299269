export const getRequestDataFilesForAdd = (entityId, entityType, files) => {
    const formData = new FormData();

    if(!files) {
        return formData;
    }

    const { filesToAdd, filesToDelete } = files || {};
      let fileConfigIndex = 0;
      
      if(filesToAdd?.length) {
        filesToAdd.forEach((file, index) => {
            fileConfigIndex += index
            formData.append(`entityFileList[${fileConfigIndex}].EntityId`, entityId);
            formData.append(`entityFileList[${fileConfigIndex}].EntityType`, entityType);
            formData.append(`entityFileList[${fileConfigIndex}].File`, file);
            formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Add");
        })
        fileConfigIndex++
      }

      if(filesToDelete?.length) {
        filesToDelete.forEach((file, index) => {
            if (file.fileLink) {
                fileConfigIndex += index
                formData.append(`entityFileList[${fileConfigIndex}].EntityId`, entityId);
                formData.append(`entityFileList[${fileConfigIndex}].EntityType`, entityType);
                formData.append(`entityFileList[${fileConfigIndex}].ActionType`, "Delete");
            }
        })
        fileConfigIndex++;
      }
    return formData;
};