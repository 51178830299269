import React, { useEffect, useState } from "react";
import { Paper, IconButton, InputBase } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

export const SearchInbuilt = ({
  changeSearchValue,
  searchValue,
  placeholder,
  className,
  bgcolor,
  isLoading = false,
  writeSearchDataToLocalStorage,
  storageValue,
}) => {
  function useDebounce(value, delay = 300) {
    const [debounced, setDebounced] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => setDebounced(value), delay);
      return () => clearTimeout(handler);
    }, [value, delay]);
    return debounced;
  }

  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [search, setSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(search, 15000);

  useEffect(() => {
    changeSearchValue(debouncedSearch);
  }, [debouncedSearch]); // eslint-disable-line

  function saveDataToLocaleStorage(newValue) {
    writeSearchDataToLocalStorage &&
      writeSearchDataToLocalStorage(storageValue, newValue);
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        alignItems: "center",
        background: bgcolor ? bgcolor : "inherit",
      }}
    >
      <InputBase
        className={className}
        sx={{
          flex: 1,
          fontSize: "16px",
          backgroundColor: isFieldFocused ? "var(--white)" : "inherit",
        }}
        disabled={isLoading}
        placeholder={isFieldFocused ? "" : placeholder}
        value={search}
        onBlur={() => setIsFieldFocused(false)}
        onChange={(event) => {
          setSearch(event.target.value);
          saveDataToLocaleStorage(event.target.value);
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            changeSearchValue(event.target.value);
            saveDataToLocaleStorage(event.target.value);
          }
        }}
        inputRef={(input) => input}
      />
      {isLoading ? (
        <CircularProgress />
      ) : search ? (
        <IconButton
          type="button"
          aria-label="clean search"
          onClick={() => {
            changeSearchValue("");
            setSearch("");
            writeSearchDataToLocalStorage(storageValue, "", true);
            setIsFieldFocused(true);
          }}
          sx={{ padding: "0" }}
        >
          <ClearIcon sx={{ fontSize: 22 }} />
        </IconButton>
      ) : (
        <IconButton
          type="button"
          aria-label="search"
          sx={{ padding: "0" }}
          onClick={() => setIsFieldFocused((prev) => !prev)}
        >
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};
