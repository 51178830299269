import React from "react";

export const ArrowInfoCenter = ({ rotate }) => {
  return (
    <svg
      style={{transform: `rotate(${rotate}deg)`}}
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L8.83927 7.67022C9.05358 7.85225 9.05358 8.14775 8.83927 8.32978L1 15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
