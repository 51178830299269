import React from "react";
import { useAction } from "../../../hooks/actions";
import EditButton from "../../components/EditButton";
import { getDataToChart } from "../../../helpers/helper";
import { Box, Button } from "@mui/material";
import plusicon from "../../../image/icons/plusBtnWhite.png";

export const getRowsData = (data, clickLink, handleOpenDetails,handleOpenEditBalance) => {

  return data?.length
    ? data.map(
      ({ id, name, balance,showBalance, organizationAddresses, typeName, jobChart, enableForEdit, innNumber, typeId }) => ({
        id,
        innNumber: innNumber || "",
        typeName: typeName || "",
        name: {
          name,
          action: () => handleOpenDetails({ id, typeId }),
        },
        balance: showBalance ? <Button
          className="btn btn-primary-fill "
          variant="outlined"
          onClick={() => {
            handleOpenEditBalance(id)
          }}
        >
          {`${balance} ₽`}
          <Box component="img" src={plusicon} sx={{ marginLeft: "16px" }} />

        </Button> : <></>,
        organizationAddresses: organizationAddresses.map(
          ({ city, street, building, extension }) =>
            [city, street, building, extension]
              .filter((item) => !!item)
              .join(", ")
        ),
        tasks: getDataToChart({ jobChart, action: `?responsibleUserId=${id}&responsibleUser=${name}` }),
        edit: enableForEdit ? <EditButton handleClick={() => clickLink(id, typeId)} /> : <></>,
      })
    )
    : [];
};

export const getUpdatedResponsibleContracts = (organizationResponsibleContracts) => organizationResponsibleContracts
  .map((item) => ({
    ...item,
    equipmentKind: JSON.stringify({
      id: item?.equipmentKindId,
      name: item?.equipmentKindName,
    }),
    organization: JSON.stringify({
      id: item?.organizationId,
      name: item?.organizationName,
    }),
    contractFile: {
      filesPrev: item?.contractFileName
        ? [{ fileName: item?.contractFileName, fileLink: item?.contractFileId || "" }]
        : [{ fileName: "Без имени", fileLink: item?.contractFileId || "" }],
      filesToAdd: [],
      filesToDelete: [],
    },
  }));
