
import CheckboxSimple from "../../components/CheckboxSimple";

export const formFields = {
  equipmentKindAndType:"equipmentKindAndType",
  taskNumber: "taskNumber",
  completedAt: "completedAt",
  roomAddressPlume: "roomAddressPlume",
  equipmentIntegrationNumber: "equipmentIntegrationNumber",
  checklist: "checklist",
  FacilityId:"FacilityId",
  executiveUser: "executiveUser",
  isExecutiveUserSign: "isExecutiveUserSign",
  isVerifyingUserSign: "isVerifyingUserSign",
  facilityName: "facilityName",
  responsibleCustomer: "responsibleCustomer",
  executiveUserSignature:"executiveUserSignature",
  responsibleCustomerSignature:"responsibleCustomerSignature",
  equipmentName: "equipmentName",
  workScopeName:"workScopeName",
  workScopeDetails:"workScopeDetails",
  jobName: "jobName",
  searchParams: "searchParams",
  TaskName:"TaskName",
  startDate: "startDate",
  endDate: "endDate"
};

export const allColumns = [
  { name: formFields.completedAt, showName: "dateAndTime", value: true },
  { name: formFields.facilityName,showName:"protectionObjectName", value: true },
  { name: formFields.jobName,showName:"task", value: true },
  { name: formFields.workScopeName,showName:"checklist", value: true },
  { name: formFields.equipmentName, showName: "equipment", value: true },
  { name: formFields.roomAddressPlume,showName: "roomAddressPlume", value: true },
  { name: formFields.executiveUser, showName: "executive", value: true },
  { name: formFields.isExecutiveUserSign, showName: "signature_executive", value: true },
  { name: formFields.responsibleCustomer,showName: "responsibleCustomer",  value: true },
  { name: formFields.isVerifyingUserSign, showName: "signature_customer", value: true },
  { name: formFields.taskNumber, showName: "task", value: true },
];

export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));

  
  
