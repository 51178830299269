import React, { useState, useMemo, useEffect } from "react";
import { useAppSelector } from "../../../../hooks/redux";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectSimple from "../../../components/SelectSimple";
import SelectSimpleSearch from "../../../components/SelectSimple/SelectSimpleSearch";
import {
  useLazyGetEquipmentKindSelectElementsQuery,
} from '../../../../middleware/equipmentsApi'

const getEquipmentTypeIdOptionsGroup = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((el) => ({
    id: el?.id,
    name: el?.title,
    items: el?.equipmentTypes,
    open: false,
  }));
};
export const RegulationList = (props) => {
  const { select_regulations } = useAppSelector(
    (state) => state.regulations
  );
  const { searchReguLation, setsearchReguLation } = props
 
  return (
    <SelectSimpleSearch

      sx={{}}
      searchValue={searchReguLation}
      setSearchValue={setsearchReguLation}
      options={[{ value: "все", name: "все" }, ...select_regulations]}
      {...props}
    />
  );
};
export const EquipmentTypeIdSelectGrouping = ({
  objectId,
  ...props
}) => {
  
  const [searchKindType, setsearchKindType] = useState([]);
  const [equipmentSelectElements, setEquipmentSelectElements] = useState([]);
  const [getEquipmentKindSelectElements] =
    useLazyGetEquipmentKindSelectElementsQuery();

  useEffect(() => {
    objectId &&
      getEquipmentKindSelectElements({
        params: searchKindType,
        useShortTitle: true,
        protectionObjectId: objectId,
        removeEmptyKinds: true,
        hasEquipmentOnly:true, 
      })
        .then((res) => {
          setEquipmentSelectElements(res?.data);
          
        })
        .catch((err) => {
          console.log(err);
        });
  }, [searchKindType, objectId]);
  
  return (
    <SelectGrouping
      {...props}
      setSearchValue={setsearchKindType}
      searchValue={searchKindType}
      options={getEquipmentTypeIdOptionsGroup(equipmentSelectElements)}
      isAllItem
      isRegulation
      renderValue={(selected) => {

        return selected && JSON.parse(selected)?.id != null
          ? `${JSON.parse(selected)?.name}`
          : null;
      }}
    />
  );
};
export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  const { searchObject, setsearchObject } = props
  //console.log("options",options)
  return (
    <SelectGrouping
      setSearchValue={setsearchObject}
      searchValue={searchObject}
      options={[...facilitySelectElements]}
      //bgcolor={bgcolor}
      {...props}
    />
  );

};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};

