import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useChangeOrgBalanceMutation } from "../../../../middleware/billingApi";
import { useAction } from "../../../../hooks/actions";
import CloseIcon from "../../../components/icons/CloseIcon";
import FormItem from "../../../components/FormItem";
import { editbalanceFormConfig } from "./form-config";
import "./styles.css";

const EditBalanceModal = ({ orgId,open, setOpen ,refetch}) => {
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [changeBalance, { error: isErrorChangeBalance, isSuccess: isSuccessChangeBalance}] = useChangeOrgBalanceMutation();

  async function onSubmit(data) {

    const res_data = {...data,balance: Number(data?.balance), organizationId: orgId}
    changeBalance(res_data).then(() => {
      reset({})
      refetch()
    }); 
  }

  useEffect(() => {
    setIsSuccessSubmit(isSuccessChangeBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessChangeBalance]);

  useEffect(() => {
    setIsErrorSubmit(isErrorChangeBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorChangeBalance]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="tariff" width="400px">
        <Box className="tariff-title">
          <Typography>Изменить баланс</Typography>
          <Box className="tariff-close-btn" onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <DialogContent sx={{ padding: "16px 0 0px 0", width: "100%" }}>
          <form id="myform" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{  }}>
                {editbalanceFormConfig.map(({ name, label, input, ...rest }) => (
                  <FormItem
                    key={name}
                    control={control}
                    errors={errors}
                    name={name}
                    label={label}
                    input={input}
                    setValue={setValue}
                    {...rest}
                  />
                ))}
            </Box>

          </form>
        </DialogContent>
        <DialogActions sx={{ padding: "0" }}>
          <Button
            form="myform"
            type="submit"
            className="btn btn-primary-fill magazine_events-select_container-btn"
            sx={{ margin: "auto" }}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditBalanceModal;
