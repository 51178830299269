import React from "react";
import { t } from "i18next";
import { formFields } from "./form-config";
import {
  deadlineStatusOptions,
  priorityOptions,
  statusOptions,
} from "./options";
import { SearchInbuilt } from "../../components/SearchInbuilt";
import SelectSimpleInbuilt from "../../components/SelectSimpleInbuilt";
import FormItem from "../../components/FormItem";
import DatePickerInbuild from "../../components/DatePickerInbuild";
import DateRangePickerNew from "../../components/DateRangePickerNew/DateRangePickerNew";
import { EquipmentKindSelectGrouping } from "../Equipments/Components";
import { AddressInputInbuilt } from "../../components/AddressInputInbuilt";
import { SelectSimpleObject } from "../EquipmentList/Components";
import {
  PriorityCell,
  SimpleTextCell,
  StatusCell,
  ClickableReplacementJobsCell,
  ClickableEquipmentCell,
  DeadlineStatusTextCell,
  ClickableJobNameCell,
} from "./Components";

export const getColumns = ({
  control,
  columnsFromStorage,
  jobName,
  setJobName,
  equipmentNumberAndName,
  setEquipmentNumberAndName,
  executiveCustomer,
  setExecutiveCustomer,
  responsibleCustomer,
  setResponsibleCustomer,
  defectActNumber,
  setDefectActNumber,
  replacementTask,
  setReplacementTask,
  daterange,
  setdaterange,
  searchAddress,
  setsearchAddress,
  writeSearchDataToLocalStorage,
  handleChangeDeadLineDate
}) =>
  [
    {
      id: formFields.createdAtDate,
      numeric: false,
      enableSorting: false,
      size: "280px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.createdAtDate],
      RowCell: (props) => <SimpleTextCell {...props} type="dateTime" />,
      HeaderCell: () => (
        <div
          style={{
            width: "280px",
          }}
        >
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.CreatedDateRange}`}
            sx={{
              padding: "9px 0 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <DateRangePickerNew
                placeholder={t("placeholder:dateRange")}
                setdaterange={setdaterange}
                daterange={daterange}
                {...props}
              />
            )}
          />
        </div>
      ),
    },
    {
      id: formFields.jobName,
      numeric: false,
      enableSorting: false,
      size: "125px",
      clickable: true,
      isSticky: true,
      isVisible: true,
      borderHeader: true,
      RowCell: (props) => <ClickableJobNameCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.jobName}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:task")}
              className="select_in_built small-light"
              searchValue={jobName}
              changeSearchValue={setJobName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="jobNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.priority,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.priority],
      RowCell: (props) => <PriorityCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.priority}`}
          label={t("tableHeader:priority")}
          options={priorityOptions}
          sx={{
            padding: "13px 5px 13px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="priorityStorage"
              storageName="tasksListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.status,
      numeric: false,
      enableSorting: false,
      size: "150px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.status],
      RowCell: (props) => <StatusCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.status}`}
          label={t("tableHeader:status")}
          options={statusOptions}
          sx={{ padding: "13px 5px 13px 16px" }}
          width="220px"
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              placeholder={t("tableHeader:status")}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="statusStorage"
              storageName="tasksListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.deadlineStatus,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.deadlineStatus],
      RowCell: (props) => <DeadlineStatusTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.deadlineStatus}`}
          label={t("tableHeader:deadlineStatus")}
          options={deadlineStatusOptions}
          sx={{
            padding: "13px 5px 13px 16px",
          }}
          width="220px"
          input={(props) => (
            <SelectSimpleInbuilt
              {...props}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="deadlineStatusStorage"
              storageName="tasksListSearchParams"
            />
          )}
        />
      ),
    },
    {
      id: formFields.facilityName,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facilityName],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.ProtectionObjectId}`}
          label={t("label:object")}
          sx={{
            padding: "13px 0 13px 16px",
          }}
          input={(props) => (
            <SelectSimpleObject
              {...props}
              IsTableheader={true}
              className="select_in_built small-light"
            />
          )}
        />
      ),
    },
    {
      id: formFields.facilityAddress,
      numeric: false,
      enableSorting: false,
      size: "275px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.facilityAddress],
      HeaderCell: () => (
        <FormItem
          control={control}
          sx={{
            padding: "0",
            width: "300px",
          }}
          placeholder={t("tableHeader:address")}
          name={`${formFields.searchParams}.ProtectionObjectAddressFilter`}
          value={searchAddress}
          onChange={setsearchAddress}
          input={(props) => (
            <AddressInputInbuilt
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="searchAddressStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.equipmentKindAndType,
      numeric: false,
      enableSorting: false,
      size: 200,
      clickable: false,
      isVisible: columnsFromStorage[formFields.equipmentKindAndType],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          label={t("tableHeader:system")}
          name={`${formFields.searchParams}.${formFields.equipmentType}`}
          sx={{ padding: "13px 5px 13px 16px" }}
          input={(props) => (
            <EquipmentKindSelectGrouping
              itemoption={{
                id: 0,
                name: t("options_name:everything"),
              }}
              useShortTitle={true}
              isTableheader={true}
              className="select_in_built small-light"
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="equipmentTypeStorage"
              storageName="tasksListSearchParams"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.jobEquipments,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.jobEquipments],
      RowCell: (props) => <ClickableEquipmentCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.equipmentNumberAndName}`}
          sx={{
            padding: "9px 5px 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:equipment")}
              className="select_in_built small-light"
              searchValue={equipmentNumberAndName}
              changeSearchValue={setEquipmentNumberAndName}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="equipmentNumberAndNameStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.executiveUser,
      numeric: false,
      enableSorting: false,
      size: "290px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.executiveUser],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <>
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.ExecutiveUserName}`}
            sx={{
              padding: "9px 5px 9px 16px",
              width: "100%",
            }}
            input={(props) => (
              <SearchInbuilt
                placeholder={t("tableHeader:executive")}
                className="select_in_built small-light"
                searchValue={executiveCustomer}
                changeSearchValue={setExecutiveCustomer}
                writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
                storageValue="executiveCustomerStorage"
                {...props}
              />
            )}
          />
        </>
      ),
    },
    {
      id: formFields.responsibleCustomerUser,
      numeric: false,
      enableSorting: false,
      size: "270px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.responsibleCustomer],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.ResponsibleCustomerUserName}`}
          sx={{
            padding: "9px 5px 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:responsibleCustomer")}
              className="select_in_built small-light"
              searchValue={responsibleCustomer}
              changeSearchValue={setResponsibleCustomer}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="responsibleCustomerStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.deadlineDate,
      numeric: false,
      enableSorting: false,
      size: "200px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.deadlineDate],
      RowCell: (props) => <SimpleTextCell {...props} type="date" />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.deadLineDate}`}
          sx={{
            width: "100%",
            "& label": { marginLeft: "-15px" },
          }}
          input={(props) => (
            <DatePickerInbuild
              placeholder={t("label:shortDeadline")}
              className="select_in_built small-light"
              handleChangeDeadLineDate={handleChangeDeadLineDate}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.defectActNumber,
      numeric: false,
      enableSorting: false,
      size: "300px",
      clickable: false,
      isVisible: columnsFromStorage[formFields.defectActNumber],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.defectActNumber}`}
          sx={{
            padding: "9px 5px 9px 13px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:defectActNumber")}
              className="select_in_built small-light"
              searchValue={defectActNumber}
              changeSearchValue={setDefectActNumber}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="defectActNumberStorage"
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.replacementTasks,
      numeric: false,
      enableSorting: false,
      size: "320px",
      clickable: true,
      isSticky: true,
      isVisible: columnsFromStorage[formFields.replacementTasks],
      borderHeader: true,
      RowCell: (props) => <ClickableReplacementJobsCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.replacementTasks}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:replacementTasks")}
              className="select_in_built small-light"
              searchValue={replacementTask}
              changeSearchValue={setReplacementTask}
              writeSearchDataToLocalStorage={writeSearchDataToLocalStorage}
              storageValue="replacementTaskStorage"
              {...props}
            />
          )}
        />
      ),
    },
  ].filter(({ isVisible }) => isVisible);
