import CheckboxSimple from "../../../components/CheckboxSimple";

export const formFields = {
  orderNumber: "orderNumber",
  workCompletedActNumber: "workCompletedActNumber",
  isPaid: "isPaid",
  paymentDate: "paymentDate",
  searchParams:"searchParams"
};


export const getConfigFormModalSelect = (columns) =>
  columns?.map(({ name }) => ({
    name,
    label: `tableHeader:${name}`,
    type: "checkbox",
    rules: {},
    flexBasis: "50%",
    input: (props) => <CheckboxSimple {...props} />,
  }));


