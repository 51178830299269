import React from "react";

const DownloadIconGrid = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6923 2.19231C10.6923 1.80996 10.3824 1.5 10 1.5C9.61765 1.5 9.30769 1.80996 9.30769 2.19231V14.0174C9.00985 13.7151 8.65577 13.2702 8.14049 12.6203L6.85017 10.9929C6.61262 10.6933 6.17717 10.643 5.87757 10.8806C5.57797 11.1181 5.52767 11.5536 5.76522 11.8532L7.08175 13.5136C7.57392 14.1344 7.98005 14.6466 8.34355 15.0115C8.71658 15.386 9.13061 15.6964 9.65969 15.7807C9.7724 15.7987 9.88611 15.8077 10 15.8077C10.1139 15.8077 10.2276 15.7987 10.3403 15.7807C10.8694 15.6964 11.2834 15.386 11.6564 15.0115C12.02 14.6466 12.4261 14.1344 12.9182 13.5136L14.2348 11.8532C14.4723 11.5536 14.422 11.1181 14.1224 10.8806C13.8228 10.643 13.3874 10.6933 13.1498 10.9929L11.8595 12.6203C11.3442 13.2702 10.9901 13.7151 10.6923 14.0174V2.19231Z"
        fill="currentColor"
      />
      <path
        d="M17.9385 9.16114C17.7089 8.85542 17.2749 8.79374 16.9692 9.02337C16.6634 9.253 16.6018 9.68699 16.8314 9.9927C17.3238 10.6483 17.6154 11.4622 17.6154 12.3461V13.2692C17.6154 14.1532 17.6132 14.5146 17.5671 14.806C17.3012 16.4847 15.9847 17.8012 14.306 18.0671C14.0146 18.1132 13.6532 18.1154 12.7692 18.1154H7.23077C6.34677 18.1154 5.98538 18.1132 5.69399 18.0671C4.01534 17.8012 2.69879 16.4847 2.43292 14.806C2.38677 14.5146 2.38462 14.1532 2.38462 13.2692V12.3461C2.38462 11.4622 2.67615 10.6483 3.1686 9.9927C3.39823 9.68699 3.33655 9.253 3.03084 9.02337C2.72512 8.79374 2.29114 8.85542 2.06151 9.16114C1.39514 10.0483 1.00001 11.1521 1.00001 12.3461L1 13.3438C0.999941 14.1303 0.999903 14.6094 1.06535 15.0226C1.42506 17.2937 3.20627 19.0749 5.47739 19.4346C5.89063 19.5001 6.3697 19.5001 7.15619 19.5H12.8438C13.6303 19.5001 14.1094 19.5001 14.5226 19.4346C16.7937 19.0749 18.5749 17.2937 18.9346 15.0226C19.0001 14.6094 19.0001 14.1303 19 13.3438L19 12.3461C19 11.1521 18.6049 10.0483 17.9385 9.16114Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DownloadIconGrid;
