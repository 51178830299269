import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import EquipmentDetails from "../../../EquipmentDetails"
import CloseIcon from "@mui/icons-material/Close";


const EditEquipmentModal = ({
  isOpen,
  setOpen,
  setselectedequipId,
  refreshTree,
  equipId,
  setNewAddedEquipmentCount
}) => {
  const handleClose = () => {
    setOpen(false)
   // setselectedequipId(null)
  }
  return (
    <Dialog
      sx={{ padding: 10 }}
      fullWidth
      fullScreen
      className="modal"
      open={isOpen}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <DialogTitle>Редактирование оборудования</DialogTitle>
        <DialogTitle className="modal-title-right">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent sx={{padding: '0px 24px 24px 24px'}}>
          <EquipmentDetails EquiipId={equipId} modalClose={setOpen} refreshEquipment={refreshTree} setNewAddedEquipmentCount={setNewAddedEquipmentCount}/>
      </DialogContent>
    </Dialog>
  )
}

export default EditEquipmentModal