import React, { useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../components/icons/CloseIcon";
import {
  formConfigOperativeInformation,
  formFields,
} from "../AddInformationBlock/form-config";
import FormItem from "../../../components/FormItem";
import TextEditor from "../AddInformationBlock/TextEditor";
import { useAction } from "../../../../hooks/actions";
import { useEditNewsMutation } from "../../../../middleware/usersApi";
import "./styles.css";

const EditOperativeInfoModal = ({
  isVisible,
  handleClose,
  newsDataForEdit,
  refetchOperativeInfo
}) => {
  const { content, date } = newsDataForEdit || {};
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(content || "");
  const [editNews] = useEditNewsMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.date]: date || "",
    },
  });

  const onSubmit = (data) => {
    const preparedData = { ...newsDataForEdit, ...data, content: editorState };
    editNews(preparedData).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
      } else {
        setIsSuccessSubmit(true);
        reset();
        refetchOperativeInfo();
        handleClose();
      }
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="edit-news-modal">
        <Box className="edit-news-modal-header">
          <Typography>Редактирование оперативной информации МЧС</Typography>

          <Box onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <form
          className="add-news-form"
          id="editNews"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formConfigOperativeInformation
            .slice(1)
            .map(({ name, label, input, ...rest }, i) => (
              <FormItem
                key={name}
                control={control}
                errors={errors}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}

          <TextEditor
            editorState={editorState}
            setEditorState={setEditorState}
          />

          <Button
            form="editNews"
            className="btn addNews"
            type="submit"
            //   disabled={isLoading}
          >
            {t("button:save")}
          </Button>
        </form>
      </Box>
    </Dialog>
  );
};

export default EditOperativeInfoModal;
