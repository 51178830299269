import React from "react";
import Box from "@mui/material/Box";

import { fileSizeCheck, fileTypeCheck } from "../../../validation";
import { ADDRESSES_TYPES } from "./constants";

import InputText from "../../components/InputText";
import InputFileSingle from "../../components/InputFile/InputFileSingle";
// import YandexMapInput from "../../components/YandexMapInput";
import DatePickerField from "../../components/DatePickerField";
import { OrganizationsSelect, EquipmentKindSelect } from "./Components";
import { AddressInput } from "../../components/AddressInput";

export const formFieldsTable = {
  name: "name",
  executiveUsers: "executiveUsers",

  equipmentKindsAndTypes: "equipmentKindsAndTypes",
  tasks: "tasks",
  edit: "edit",

  avatarLink: "avatarLink",
  employeeEquipmentKinds: "employeeEquipmentKinds",
  employeeFacilities: "employeeFacilities",
  employeeFullName: "employeeFullName",
  employeePosition: "employeePosition",
  organizationType: "organizationType",
  organizations: "organizations",
};

export const formFields = {
  name: "name",
  ogrnNumber: "ogrnNumber",
  innNumber: "innNumber",
  kppCode: "kppCode",
  ur_address: "ur_address",
  fact_address: "fact_address",
  address: "address",
  okpoCode: "okpoCode",
  okvedCode: "okvedCode",
  licenseNumber: "licenseNumber",
  licenseExpirationDate: "licenseExpirationDate",

  file: "file",

  organization: "organization",
  equipmentKind: "equipmentKind",
  expirationDate: "expirationDate",
  contractNumber: "contractNumber",
  contractFile: "contractFile",

  //organizationResponsibleContracts: "organizationResponsibleContracts",

  bankName: "bankName",
  account: "account",
  correspondentAccount: "correspondentAccount",
  bankBIC: "bankBIC",

  organizationResponsibleAddresses: "organizationResponsibleAddresses",
  extension: "extension",
  organizationResponsibleBankDetails: "organizationResponsibleBankDetails",
  city: "city",
};

export const templateAddress = {
  address: "",
  type: "Фактический",
};

export const templateContracts = {
  [formFields.organization]: "",
  [formFields.equipmentKind]: "",
  [formFields.expirationDate]: "",
  [formFields.contractNumber]: "",
  [formFields.contractFile]: "",
};

export const formConfigOrganization = [
  {
    name: formFields.ogrnNumber,
    label: "label:ogrnNumber",
    placeholder: "placeholder:ogrn",
    type: "number",
    rules: {
      required: false,
      maxLength: (v) => v?.length <= 15,
      validate: (value) => {
        if (!value) return true;
        if (value.length === 0) return true;
        if (value.length >= 1 && value.length <= 12) return false;
        if (value.length > 12 && value.length < 16) return true;
        return false;
      } 
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => (
      <Box sx={{}}>
        <InputText {...props} />
      </Box>
    ),
  },
 /* {
    name: formFields.file,
    label: "label:orgnFile",
    rules: {
      required: false,
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.file,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.file,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: false,
    preview: false,
    input: (props) => (
      <Box sx={{}}>
        <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />
      </Box>
    ),
  },*/
  // {
  //   name: formFields.name,
  //   label: "label:name",
  //   placeholder: "placeholder:organizationName",
  //   type: "text",
  //   rules: {
  //     required: true,
  //   },
  //   input: (props) => {
  //     return (
  //       <Box sx={{ minWidth: "250px", }}>
  //         <InputText {...props} />
  //       </Box>
  //     );
  //   },
  // },
];

export const formConfigBasicInf = [
  {
    name: formFields.kppCode,
    label: "label:kppCode",
    placeholder: "placeholder:kpp",
    type: "number",
    rules: {
      required: false,
      // maxLength: (v) => v?.length <= 9,
      // validate: (value, formValues) => value?.length === 9,
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.okpoCode,
    label: "label:okpoCode",
    placeholder: "placeholder:okpo",
    type: "number",
    rules: {
      required: false
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
  {
    name: formFields.okvedCode,
    maxLength: { value: 8 },
    rules: {
      // maxLength: (v) => v?.length <= 8,
      validate: (value, formValues) => {
        console.log("formValues.okvedCode", formValues);
        return (
          !/[^0-9.]/.test(value) &&
          value?.trim().slice(-1) !== "." &&
          formValues.okvedCode?.length >= 3
        );
      },
    },
    onKeyDown: (event) => {
      /*if(isNaN(e.key) && e.key !== 'Backspace' || e.target.value.length >7 && e.key !== 'Backspace' || e.key === 'Tab') {
        e.preventDefault();
      }*/
      if (
        event.key === "Tab" ||
        event.key === "Backspace" ||
        (event.ctrlKey && event.key === "v") ||
        (event.key >= "0" && event.key <= "9" && event.target.value.length < 8)
      ) {
        return;
      } else {
        event.preventDefault();
      }
    },
    placeholder: "placeholder:okved",
    label: "label:okvedCode",
    type: "text",
    input: (props) => (
      <Box sx={{ width: "100%" }}>
        <InputText {...props} />
      </Box>
    ),
  },
];
export const formLicense = [
  {
    name: formFields.licenseNumber,
    label: "label:licence_number",
    placeholder: "placeholder:licence_number",
    sx: {
      width: "100%",
      // maxWidth: "30%",
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.licenseExpirationDate,
    label: "label:licence_period",
    type: "date",
    rules: {},
    sx: {
      width: "100%",
      // maxWidth: "33%",
      padding: "2px 0 10px",
      "& .MuiStack-root > .MuiTextField-root": {
        minWidth: 0,
      },
    },
    input: (props) => <DatePickerField {...props} />,
  },
];

export const formConfigAddress = [
  {
    name: formFields.address,
    label: "label:yandex_address",
    placeholder: "placeholder:address",
    dynamicRules: {
      [ADDRESSES_TYPES.UR]: {
        required: false,
      },
      [ADDRESSES_TYPES.FACT]: {
        required: false,
      },
    },
    shouldSetCoordinates: false,
    input: (props) => <AddressInput {...props} />,
  },
  {
    name: formFields.extension,
    type: "number",
    label: "label:extension",
    placeholder: "placeholder:place",
    rules: {
      required: false,
    },
    input: (props) => <InputText {...props} />,
  },
];

export const formConfigBank = [
  {
    name: formFields.bankBIC,
    label: "label:bik",
    type: "number",
    placeholder: "placeholder:bik",
   
    rules: {
      required: false,
      // maxLength: (v) => v.length <= 9,
      // validate: (value) => value?.length === 9,
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.bankName,
    label: "label:name_bank",
    placeholder: "placeholder:bankName",
    type: "text",
    flexBasis: "32%",
    rules: {
      required: false,
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.account,
    label: "label:rs_organization",
    placeholder: "placeholder:rbill",
    type: "number",
    flexBasis: "32%",
    rules: {
      required: false,
      // maxLength: (v) => v.length <= 20,
      // validate: (value) => value?.length === 20,
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.correspondentAccount,
    label: "label:kor_schet",
    placeholder: "placeholder:corrbill",
    type: "number",
    flexBasis: "32%",
    rules: {
      required: false,
      // maxLength: (v) => v.length <= 20,
      // validate: (value) => value?.length === 20,
    },
    onKeyDown: (e) => {
      if (
        e.key === "e" ||
        e.key === "E" ||
        e.key === "-" ||
        e.key === "+" ||
        e.key === "." ||
        e.key === ","
      ) {
        e.preventDefault();
      }
    },
    InputProps: { type: "number" },
    input: (props) => <InputText {...props} />,
  },
];

export const formConfigContracts = [
  {
    name: formFields.organization,
    label: "label:organization_section",
    type: "select-text",
    rules: {
      required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
  {
    name: formFields.equipmentKind,
    label: "label:equipmentKind",
    type: "select-text",
    rules: {
      required: true,
    },
    input: (props) => <EquipmentKindSelect {...props} />,
  },
  {
    name: formFields.contractNumber,
    label: "label:contractNumber",
    type: "text",
    rules: {
      validate: {
        required: (value, formValues) => {
          if (
            formValues.selfService ||
            formValues.equipmentContractFileIdAvailability
          ) {
            return null;
          }
          return !!value;
        },
      },
    },
    input: (props) => <InputText {...props} />,
  },
  {
    name: formFields.expirationDate,
    label: "label:termOfContract",
    type: "date",
    sx: { padding: "2px 0 10px" },
    rules: {
      required: true,
    },
    input: (props) => <DatePickerField {...props} />,
  },
  {
    name: formFields.contractFile,
    label: "",
    rules: {
      required: "required",
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.file,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.file,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: false,
    preview: false,
    width: "190px",
    input: (props) => (
      <InputFileSingle
        {...props}
        direction="column"
        accept={".png, .jpg, .jpeg, .pdf"}
      />
    ),
  },
];
