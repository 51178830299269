import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import NewsTape from "./NewsTape";
import VideosTree from "./VideosTree";
import TrainingApplication from "./TrainingApplication";
import { useAppSelector } from "../../../hooks/redux";
import AddInformationBlock from "./AddInformationBlock";
import { useGetProfileInfoQuery } from "../../../middleware/usersApi";
const InfoCenter = () => {
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.users.token);
  const { data: profileData } = useGetProfileInfoQuery(token, { skip: !token });
  const [isRefetchNewsListFlag, setIsRefetchNewsListFlag] = useState(false);
  const [isRefetchOperativeNewsFlag, setIsRefetchOperativeNewsFlag] =
    useState(false);

  const refetchNewsList = () => {
    setIsRefetchNewsListFlag((prev) => !prev);
  };

  const refetchOperativeNews = () => {
    setIsRefetchOperativeNewsFlag((prev) => !prev);
  };

  return (
    <>
      {profileData && (
        <>
          <Box>
            <Typography className="title-page">
              {t("label:infocenter")}
            </Typography>
          </Box>
          <Box className="infocenter-blocks-wrapper">
            <NewsTape
              isRefetchFlag={isRefetchNewsListFlag}
              isRefetchOperativeNewsFlag={isRefetchOperativeNewsFlag}
              profileData={profileData}
            />
            {profileData?.role === "Суперадмин" && (
              <AddInformationBlock
                refetchNewsList={refetchNewsList}
                refetchOperativeNews={refetchOperativeNews}
              />
            )}
            <VideosTree />
            <TrainingApplication profileData={profileData} />
          </Box>
        </>
      )}
    </>
  );
};

export default InfoCenter;
