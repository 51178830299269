import { Box, Input, Typography, Tooltip } from '@mui/material'
import { TreeItem2Content, TreeItem2GroupTransition, TreeItem2Icon, TreeItem2IconContainer, TreeItem2Label, TreeItem2Provider, TreeItem2Root } from '@mui/x-tree-view'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2'
import React from 'react';
import MoredataIcon from '../../../../image/icons/moreData.svg';

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, minScale, scale, itemId, EditMode, item, label, activeId, disabled, children, isGroup, isSystem, countNonPlaced, equipIndex, countPlaced, equipmentTypeId, equipmentId, deleteEquip, canEdit, editEquip, canDelete, hasPlacement, onAddEquipment, isForEdit, onChangeName, onOpenEditReglament, isKind, expandItems, placedItems, moreDatafunc, ishasMorelastIndex, isLoading, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  const isSelectedTreeRow = expandItems?.length > 0 && expandItems?.includes(itemId)
  const itemTypePL = itemId.includes('equipment') ? "56px" : itemId.includes('kind') ? itemId.includes('group') ? '40px' : '24px' : "8px";
  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)} style={{ padding: 0 }}>
        <TreeItem2Content {...getContentProps()} style={{ padding: 0, paddingLeft: itemTypePL, paddingRight: "16px", backgroundColor: isSelectedTreeRow ? "#EEF4FD" : "white" }}>

          <Box sx={{ flexGrow: 1, display: 'flex', placeItems: "center", height: isSystem ? "59px" : "60px", padding: "0px", backgroundColor: isSelectedTreeRow ? "#EEF4FD" : "white", borderBottom: isSystem ? "1px solid #cfd9e8" : "0px" }}>

            {equipIndex &&
              <Typography sx={{ alignContent: "center", marginRight: "5px", fontFamily: "var(--Inter)", color: "#051d39" }}>{`${equipIndex}. `}</Typography>
            }
            {equipIndex ?
              <Tooltip title={label}>
                <TreeItem2Label sx={{ alignContent: "center",whiteSpace: "nowrap", textOverflow: "ellipsis", width: "150px", overflow: "hidden", fontFamily: "var(--Inter)", color: "#051d39" }} {...getLabelProps()} />
              </Tooltip>

              :
              <TreeItem2Label sx={{ alignContent: "center", fontFamily: "var(--Inter)", color: "#051d39" }} {...getLabelProps()} />
            }

            {
              !!item?.number && (
                <Tooltip title={item.number}>
                  <Typography sx={{ alignContent: "center",whiteSpace: "nowrap", width: "130px", textOverflow: "ellipsis", overflow: "hidden", fontFamily: "var(--Inter)", color: "#051d39", margin: "0px 24px" }}>{item.number}</Typography>
                </Tooltip>
              )
            }
            {(ishasMorelastIndex && isLoading) &&
              <Box

                onClick={(e) => { moreDatafunc() }}
                component="img"
                src={MoredataIcon}

              />
            }
            {/*isGroup && <Box sx={{ display: 'flex', }}>
              <Typography sx={{ color: "green" }}>{countPlaced}</Typography>
              <Typography>/</Typography>
              <Typography sx={{ color: "red" }}>{countNonPlaced}</Typography>
            </Box>
            */}
            <TreeItem2IconContainer {...getIconContainerProps()} sx={{ alignSelf: "center" }}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>

          </Box>

        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default CustomTreeItem