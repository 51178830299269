import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLazyGetAddressSelectQuery } from "../../../middleware/addressDataApi";
import { useAppSelector } from "../../../hooks/redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import selectArrowUp from "../../../image/icons/selectArrowUp.png";
import selectArrowDown from "../../../image/icons/selectArrowDown.png";

import "./style.css";
export const AddressInputInbuilt = ({
  label,
  disabled = false,
  onChange,
  error,
  value,
  shouldSetCoordinates,
  writeSearchDataToLocalStorage,
  storageValue
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const [getAddressSelect] = useLazyGetAddressSelectQuery();
  const { addressData } = useAppSelector((state) => state.addressData);
  const getRequestNewValue = (newValue) => {
    const { data } = newValue || {};
    const requestData = {
      city: data?.city || "",
      street: data?.street || "",
      building: data?.house || "",
    };
    if (shouldSetCoordinates) {
      requestData.coordinates = {
        longitude: data?.geo_lon,
        latitude: data?.geo_lat,
      };
    }
    return requestData;
  };

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      getAddressSelect("");
      return undefined;
    }

    if (active) {
      getAddressSelect(inputValue);
    }

    return () => {
      active = false;
    };
  }, [inputValue, getAddressSelect]);


  return (
    <Autocomplete
      id="address"
      getOptionLabel={(option) => option.value}
      ListboxProps={{ style: { maxHeight: "100px" } }}
      options={addressData?.suggestions || []}
      size="small"
      filterOptions={(x) => x}
      className="adress-filter-in-table"
      autoComplete
      filterSelectedOptions
      noOptionsText="Введите адрес"
      disabled={disabled}
      defaultValue={() =>
        value?.city
          ? {
            data: {},
            unrestricted_value: "",
            value: `${value.city} ${value.street} ${value.building}`,
          }
          : null
      }
      onChange={(event, newValue) => {
        const value = getRequestNewValue(newValue)
        onChange(value);
        writeSearchDataToLocalStorage && writeSearchDataToLocalStorage(storageValue, value)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        writeSearchDataToLocalStorage && writeSearchDataToLocalStorage(storageValue, value)
      }}
      renderInput={(params) => (
        <TextField
          placeholder="Адрес"
          {...params}
          sx={{
            color:"black",
            display: "flex",
            "& .MuiInputBase-root": {
              lineHeight: "24px",
              backgroundColor: "transparent",
              fontSize: "16px",
            },
            "& .MuiOutlinedInput-input": {
              display: "flex",
              lineHeight: "24px",
              marginLeft: "-20px !important",
              fontSize: "16px",
              color: "var(--headers)",
              border: "none",
            },
            "& .MuiInputBase-root .MuiOutlinedInput-input::placeholder": {
              color: "#4D5C6E  !important",
              fontWeight: "500"
              
            },
            "& fieldset": { border: "none" },
          }}
          label={t(label)}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <Box
                  sx={{ cursor: "pointer", display: 'flex' }}
                  onClick={() => {
                    setIsOpen((prev) => !prev);
                  }}
                >
                  {isOpen ? (
                    <img src={selectArrowUp} />
                  ) : (
                    <img src={selectArrowDown} />
                  )}
                </Box>
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
