import dayjs from "dayjs";
import { dateFormatForSubmit } from "../../../constants";

export const getTasksChartBarData = (data) => {
  const {
    completedInTimeJobsAmount,
    completedNotInTimeJobsAmount,
    needsAttentionJobsAmount,
    overduedJobsAmount,
  } = data || {};
  return [
    {
      label: "label:completedInTimeJobsAmount",
      data: completedInTimeJobsAmount || 0,
      backgroundColor: "#3DC39B",
    },
    {
      label: "label:needsAttentionJobsAmount",
      data: needsAttentionJobsAmount || 0,
      backgroundColor: "#F59E0B",
    },
    {
      label: "label:completedNotInTimeJobsAmount",
      data: completedNotInTimeJobsAmount || 0,
      backgroundColor: "#FB6A6A",
    },
    {
      label: "label:overduedJobsAmount",
      data: overduedJobsAmount || 0,
      backgroundColor: "#B90C2B",
    },
  ];
};

export const getDefaultSatrtDate = () =>{
  const startDate = dayjs().date(1);
  return startDate.subtract(3, "month").format(dateFormatForSubmit)
}
  

export const getDefaultEndDate = () => {
  const endDate = dayjs().add(8, "month");
  return endDate.endOf('month').format(dateFormatForSubmit)
}