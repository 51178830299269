import { COLORS_DONUT_CHART, globalFields, ICON_SIZE } from "../constants";

export const getMaskPhone = (phone) => {
  const numbers = phone?.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  return !!numbers
    ? `+${numbers[1]} (${numbers[2]}) ${numbers[3]} ${numbers[4]} ${numbers[5]}`
    : phone;
};

const fields = {
  completedJobsAmount: "alternative_completedJobsAmount",
  needsAttentionJobsAmount: "alternative_needsAttentionJobsAmount",
  overduedJobsAmount: "alternative_overduedJobsAmount",
};

export const getDataToChart = (data) => {
  const { jobChart, action } = data || {};

  return jobChart
    ? Object.entries(jobChart).map(([key, value]) => ({
      value,
      color: COLORS_DONUT_CHART[globalFields[key]],
      name: globalFields[key],
      alternativeName: fields[key],
      action,
    }))
    : [];
};

export const loadImg = (src, height, width,x,y) => {
  return new Promise((resolve, reject) => {
    if (!src) return;
    const img = new Image();
    img.src = src;
    if (height) {
      img.height = height;
    }
    if (width) {
      img.width = width;
    }

    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
};

export const getRectCoordinates = (
  { x, y, width, height },
  scale = { x: 1, y: 1 }
) => {
  x *= scale.x;
  y *= scale.y;
  width *= scale.x;
  height *= scale.y;

  const rightX = x + width;
  const lowerY = y + height;
  return [x, y, rightX, y, rightX, lowerY, x, lowerY];
};

export const getRoomAddressForScheme = (
  roomAddresses = [],
  scale = { x: 1, y: 1 }
) => {
  return roomAddresses.map((address) => ({
    ...address,
    coordinates: address?.coordinates
      .map((e) => e)
      .sort((a, b) => a.order - b.order)
      .map(({ coordinateX, coordinateY }) => [
        coordinateX * scale.x,
        coordinateY * scale.y,
      ])
      .flat(),
  }));
};
export const getRoomAddressForSchemeStaging = (
  roomAddresses = [],
  scale = { x: 1, y: 1 }
) => {
  return roomAddresses.map((address) => ({
    ...address,
    coordinates: address?.coordinates
      .map((e) => e)
      .sort((a, b) => a.order - b.order)
      .map(({ coordinateX, coordinateY }) => [
        coordinateX * scale.x - 200,
        coordinateY * scale.y - 200,
      ])
      .flat(),
  }));
};
export const getRoomAddressForRequest = (
  roomAddresses = [],
  scale = { x: 1, y: 1 }
) => {
  return roomAddresses.map((address) => ({
    ...address,
    coordinates: address?.coordinates
      .map((e) => e)
      .reduce((acc, e, i, arr) => {
        return i % 2 === 0
          ? [
            ...acc,
            { coordinateX: e * scale.x, coordinateY: arr[i + 1] * scale.y },
          ]
          : [...acc];
      }, [])
      .map((e, i) => ({ ...e, order: i })),
  }));
};

export const getIsInsidePoint = ({ x, y, roomAddresses }) => {
  const coordinates = roomAddresses.map(({ coordinates }) => coordinates);

  const updatedCoordinates = coordinates.map((elem) =>
    elem.reduce((acc, e, i, arr) => {
      return i % 2 === 0
        ? [...acc, { coordinateX: e, coordinateY: arr[i + 1] }]
        : [...acc];
    }, [])
  );
  const result = updatedCoordinates.map((e) =>
    e
      .reduce((acc, el, i, arr) => {
        return i !== arr.length - 1
          ? [
            ...acc,
            {
              start: { x: el.coordinateX, y: el.coordinateY },
              end: { x: arr[i + 1].coordinateX, y: arr[i + 1].coordinateY },
            },
          ]
          : [
            ...acc,
            {
              start: { x: el.coordinateX, y: el.coordinateY },
              end: { x: arr[0].coordinateX, y: arr[0].coordinateY },
            },
          ];
      }, [])
      .filter(({ start, end }) => !(start.x === end.x && start.y === end.y)) // for polygon
      .map(({ start, end }) => {
        
        const D =
          (end.x - start.x) * (y - start.y) - (x - start.x) * (end.y - start.y);
        return D >= 0; // inside
      })
      .some((e) => !e)
  );

  return result.some((e) => !e);
};

export const getEquipmentsCoordinatesWithRoomId = ({
  equipmentsCoordinates,
  rooms,
}) => {
  return equipmentsCoordinates?.map((equipment) => {
    return { ...equipment, roomAddressId: null };
  }, []);
};

export const getOnCommonBorderIdRooms = ({ x, y, roomAddresses = [] }) => {
  const coordinatesCornersIcon = [
    { x: x - ICON_SIZE.WIDTH / 2, y: y - ICON_SIZE.HEIGHT / 2 },
    { x: x + ICON_SIZE.WIDTH / 2, y: y - ICON_SIZE.HEIGHT / 2 },
    { x: x + ICON_SIZE.WIDTH / 2, y: y + ICON_SIZE.HEIGHT / 2 },
    { x: x - ICON_SIZE.WIDTH / 2, y: y + ICON_SIZE.HEIGHT / 2 },
  ];
  const updatedRooms = roomAddresses.map((address) => ({
    ...address,
    coordinates: address?.coordinates
      .map((e) => e)
      .reduce((acc, e, i, arr) => {
        return i % 2 === 0
          ? [...acc, { coordinateX: e, coordinateY: arr[i + 1] }]
          : [...acc];
      }, [])
      .map((e, i) => ({ ...e, order: i }))
      .reduce((acc, el, i, arr) => {
        return i !== arr.length - 1
          ? [
            ...acc,
            {
              start: { x: el.coordinateX, y: el.coordinateY },
              end: { x: arr[i + 1].coordinateX, y: arr[i + 1].coordinateY },
            },
          ]
          : [
            ...acc,
            {
              start: { x: el.coordinateX, y: el.coordinateY },
              end: { x: arr[0].coordinateX, y: arr[0].coordinateY },
            },
          ];
      }, [])
      .filter(({ start, end }) => !(start.x === end.x && start.y === end.y)), // for polygon
  }));

  const getInside = ({ x, y, room }) => {
    const result = room.coordinates.map(({ start, end }) => {
      const D =
        (end.x - start.x) * (y - start.y) - (x - start.x) * (end.y - start.y);
      return D >= 0;
    });

    return !result.some((e) => !e) ? room.id : null;
  };

  const corners = coordinatesCornersIcon.map(({ x, y }) => {
    const result = updatedRooms.map((room) => {
      return getInside({ x, y, room });
    });

    return result.filter((e) => e).join("");
  });

  return Array.from(new Set(corners)).filter((e) => e);
};

export const parseJsonToken = ({ token, setPermissions }) => {
  try {
    const base64Url = token?.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    if (base64) {
      const jsonPayload = decodeURIComponent(escape(window.atob(base64)));
      const parsedToken = JSON.parse(jsonPayload);

      const permissionKeys = parsedToken?.permissions.map((item) => {

          for (const [key, value] of Object.entries(item)) {
           
             return value.map((itemm)=>{
                if(itemm == "all")
                    return `${Object.keys(item)}`
                else
                    return `${Object.keys(item)}${itemm}`
              }
                  
              )
          }
       

      }

      );
      setPermissions([...permissionKeys.flat()]);
      return parsedToken;
    }
  } catch (error) {
    console.error("Failed to parse JSON token:", error);
    return null;
  }
}
