import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useAppSelector } from "../../../../hooks/redux";
import { useAction } from "../../../../hooks/actions";
import useScreenSize from "../../../../hooks/useScreenSize";
import { useLazyGetFacilitiesBillingsQuery } from "../../../../middleware/facilitiesApi";
import { useGenerateCommonBillDocumentMutation, useMarkInvoiceAsExcludedMutation } from "../../../../middleware/billingApi";
import { useGetUserGridSettingQuery, useLazyGetUserGridSettingQuery } from "../../../../middleware/usersApi";
import TableBasic from "../../../components/TableBasic";
import { getTableHieght } from "../../../../helpers/getTableHieght";
import { getColumns } from "./columns";
import { formFields,joinToTotalBill } from "./form-config";
import { dateFormatForSubmit } from "../../../../constants";
import DownloadIconGrid from "../../../components/icons/Cabinet/DownloadIconGrid";
import Switcher from "../../../components/Switcher";

const BillingsList = () => {
  const navigate = useNavigate();
  const { facilitiesBillings } = useAppSelector((state) => state.facilities);
  const [PageNumber, changePageNumber] = useState(0);
  const [searchObject, setsearchObject] = useState("");
  const [loading, setLoading] = useState(false);
  const [daterange, setdaterange] = useState([null, null]);

  const { control, setValue, getValues, reset, watch } = useForm();
  const screenSize = useScreenSize();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "JobList" });

  const [getUserGrid, { error: errorgrid, refetch: refetchUserGrid /* isLoading */ }] =
    useLazyGetUserGridSettingQuery();

  const [generateCommonBillingDocument, { error: isErrorBill, isLoading: isLoadingBill, isSuccess: isSuccessBill }] =
    useGenerateCommonBillDocumentMutation();

  const [getFacilitiesBillingsTable, { error, isLoading: isLoadingBillings, refetch: refetchBillingsTable, isFetching }] =
    useLazyGetFacilitiesBillingsQuery();

  const [markInvoice, { error: isErrorMark, isSuccess: isSuccessMark }] =
    useMarkInvoiceAsExcludedMutation();

  const markInvoiceObject = (event, id) => {

    markInvoice({ id: id }).then(() => {
      const payDate = getValues(`${formFields.searchParams}.${formFields.paymentDate}`);
      if (params?.paymentDate) delete params.paymentDate;
      let dateFilter = {};
      if (payDate) {
        if (payDate[1] != null) dateFilter["paymentDateMax"] = dayjs(payDate[1]).format(dateFormatForSubmit);
        if (payDate[0] != null) dateFilter["paymentDateMin"] = dayjs(payDate[0]).format(dateFormatForSubmit);
      }
      getFacilitiesBillingsTable({
        ...params,
        ...dateFilter,
        PageNumber,
      });
    })
  }

  useEffect(() => {
    setIsSuccessSubmit(isSuccessBill || isSuccessMark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessBill,isSuccessMark]);

  useEffect(() => {
    setIsErrorSubmit(isErrorBill || error || isErrorMark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorBill, error,isErrorMark]);

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });
  const base64toPDF = (data) => {
    const { name, contentType, content } = data
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: `application/${contentType}` });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false);
  };

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const downloadFullBill = () => {
    setLoading(true)
    generateCommonBillingDocument().then(({ data }) => {
      base64toPDF(data)
    })
  }

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.faciltyName}`, searchObject);
  }, [searchObject, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.paymentDate}`, daterange);
  }, [daterange, setValue]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce((acc, { name, visible }) => ({ ...acc, [name]: visible }), {});
  })();

  const columns = useMemo(
    () =>
      getColumns({
        control,
        navigate,
        columnsFromStorage,
        searchObject,
        setsearchObject,
        daterange,
        setdaterange,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const rows = useMemo(() => {
    const { data } = facilitiesBillings;

    return data?.length
      ? data.map(
        ({ id, includeInCommonOrder, number, name, orderNumber, orderFileLink, paymentDate, price, tariffType }) => {
          return {
            id,
            index: number,
            name,
            tarif: price,
            paymentDate,
            totalBill: <Box width="100%" sx={{ placeItems: "center" }}>
              <Switcher style={{ placeContent: "center" }} onChange={(event) => markInvoiceObject(event, id)} value={includeInCommonOrder} options={joinToTotalBill} />
            </Box>,
            bill: {
              orderNumber,
              orderFileLink,
            },
          };
        }
      ).sort((a, b) => a.index - b.index)
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitiesBillings]);

  const recordsCount = useMemo(() => facilitiesBillings && facilitiesBillings?.recordsCount, [facilitiesBillings]);

  useEffect(() => {
    const payDate = getValues(`${formFields.searchParams}.${formFields.paymentDate}`);
    if (params?.paymentDate) delete params.paymentDate;
    let dateFilter = {};
    if (payDate) {
      if (payDate[1] != null) dateFilter["paymentDateMax"] = dayjs(payDate[1]).format(dateFormatForSubmit);
      if (payDate[0] != null) dateFilter["paymentDateMin"] = dayjs(payDate[0]).format(dateFormatForSubmit);
    }
    changePageNumber(0);
    getFacilitiesBillingsTable({
      ...params,
      ...dateFilter,
      PageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const payDate = getValues(`${formFields.searchParams}.${formFields.paymentDate}`);
    if (params?.paymentDate) delete params.paymentDate;
    let dateFilter = {};
    if (payDate) {
      if (payDate[1] != null) dateFilter["paymentDateMax"] = dayjs(payDate[1]).format(dateFormatForSubmit);
      if (payDate[0] != null) dateFilter["paymentDateMin"] = dayjs(payDate[0]).format(dateFormatForSubmit);
    }
    getFacilitiesBillingsTable({
      ...params,
      ...dateFilter,
      PageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PageNumber]);


  return (
    <Box className="template_page">
      <Box sx={{ width: "100%", paddingTop: "12px", textAlign: "right" }}>
        <Button
          className="btn btn-primary-fill magazine_events-select_container-btn"
          variant="outlined"
          onClick={downloadFullBill}
          disabled={loading || isLoadingBill}
          sx={{ height: "43px", width: "248px", fontSize: "14px !important" }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Box sx={{ alignItems: "center", gap: "16px", display: "flex" }}>
              <Typography fontSize={16}>{"Скачать общий счет"}</Typography>
              <Box
                sx={{
                  color: "white"
                }}
              >
                <DownloadIconGrid />
              </Box>
            </Box>

          )}

        </Button>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => { }}
        withSearchField={false}
        isLoading={isLoadingBillings || isFetching}
        pageNumber={PageNumber}
        changePageNumber={changePageNumber}
        count={recordsCount}
        minHeight={getTableHieght(screenSize, true).min}
        maxHeight={getTableHieght(screenSize, true).max}
      />
    </Box>
  );
};

export default BillingsList;
