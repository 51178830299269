import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAction } from "../../../../../hooks/actions";
import { useAppSelector } from "../../../../../hooks/redux";
import { useGetdateRangeTariffQuery } from "../../../../../middleware/billingApi";
import { useChangeTariffMutation, useGetFacilitiesGetTariffsQuery } from "../../../../../middleware/facilitiesApi";
import CloseIcon from "../../../../components/icons/CloseIcon";
import DefaultObjectIcon from "../../../../components/icons/Cabinet/DefaultObjectIcon";
import { customTarrifField } from "./form-config"
import FormItem from "../../../../components/FormItem";
import "./styles.css";

const ChangeTariffModal = ({ isVisible, handleClose, facility }) => {
  const { t } = useTranslation();
  const { roleId } = useAppSelector((state) => state.users.info);
  const { control, setValue, watch, handleSubmit, resetField, formState: errors } = useForm();
  const { setIsErrorRequest, setIsSuccessSubmit } = useAction();
  const { address, name, photoLink, id, tariff, paymentPeriodEndDate } = facility;
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isVisibleSpecialTariffModal, setIsVisibleSpecialTariffModal] = useState(false);
  const { data: tariffsInfo, isLoading } = useGetFacilitiesGetTariffsQuery(id);
  const { data: dateRange, isLoadingdateRangeTariff } = useGetdateRangeTariffQuery({ facilityId: id });
  const [changeTariff, { isError: isErrordeleteEquip, isSuccess: isSuccessdeleteEquip }] = useChangeTariffMutation();



  function saveSelectedTariff() {
    changeTariff({ facilityId: id, tariff: selectedTariff }).then((_) => {
      setSelectedTariff(null);
      handleClose();
    });
  }

  function onSubmit(data) {
    const { availableEquipment, individualPeriodDays, individualPeriodMonths } = data
    const res_data = {
      facilityId: id,
      tariff: selectedTariff,
      availableEquipment: Number(availableEquipment),
      individualPeriodDays: Number(individualPeriodDays),
      individualPeriodMonths: Number(individualPeriodMonths)
    }
    changeTariff(res_data).then((_) => {
      setSelectedTariff(null);
      handleClose();
    });
  }

  function formatTextForRender(text, index) {
    return text.split(" ")[index];
  }

  function handleToogleConfirmModal(isActive) {
    if (!selectedTariff) {
      handleClose();
      setSelectedTariff(null);
      return
    }

    if (selectedTariff === 5 && roleId === 1) {
      setIsVisibleSpecialTariffModal(true)
      return
    }
    setConfirmModal(isActive);
  }

  function convertData(isoDate) {
    const normalDate = new Date(isoDate);
    return normalDate.toLocaleDateString("ru-Ru");
  }

  useEffect(() => {
    setIsSuccessSubmit(isSuccessdeleteEquip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessdeleteEquip]);

  useEffect(() => {
    setIsErrorRequest(isErrordeleteEquip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrordeleteEquip]);

  return (
    <>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="tariff">
          <Box className="tariff-title">
            <Typography>Изменить тариф</Typography>
            <Box className="tariff-close-btn" onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box>

          <DialogContent sx={{ padding: "16px 0 0px 0", width: "100%" }}>
            <Box className="tariff-header">
              <Box className="tariff-img">
                {photoLink ? (
                  <img src={photoLink} alt="Объект" />
                ) : (
                  <Box className="tariff-default-icon">
                    <DefaultObjectIcon />
                  </Box>
                )}
              </Box>
              <Box className="tariff-object-info">
                <Box className="tariff-info">
                  <Typography>{t("label:protectionObjectId")}</Typography>
                  <Typography>{name}</Typography>
                </Box>
                <Box className="tariff-info">
                  <Typography>{t("label:address")}</Typography>
                  <Typography>{address}</Typography>
                </Box>
              </Box>
            </Box>

            <Box className="tariff-body">
              <Typography>Тарифы:</Typography>
              <Box className="tariff-blocks-container">
                {!isLoading &&
                  tariffsInfo.map((tariffItem) => (
                    <Box
                      key={tariffItem.tariff}
                      onClick={() => setSelectedTariff(tariffItem.tariff)}
                      className="tariff-block-wrapper"
                    >
                      <Box className={`tariff-block ${selectedTariff === tariffItem.tariff && "active"}`}>
                        <Box className="tariff-selected-wrapper">
                          <Typography>{formatTextForRender(tariffItem.name, 0)}</Typography>
                          {tariffItem.tariff === tariff && <Box className="tariff-block-selected">Выбрано</Box>}
                        </Box>
                        <Box className="tariff-block-info">
                          <Typography>{formatTextForRender(tariffItem.name, 1)}</Typography>
                          <Typography>элементов</Typography>
                        </Box>
                      </Box>
                      <Box className="tariff-cost">{tariffItem.price} ₽/мес</Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: "0" }}>
            <Button
              onClick={() => handleToogleConfirmModal(true)}
              className="btn btn-primary-fill magazine_events-select_container-btn"

              sx={{ margin: "auto" }}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {confirmModal && (
        <Dialog
          open={confirmModal}
          onClose={() => handleToogleConfirmModal(false)}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className="tariff">
            <Box className="tariff-title">
              <Typography>Перейти на этот тариф?</Typography>
              <Box className="tariff-close-btn" onClick={() => handleToogleConfirmModal(false)}>
                <CloseIcon />
              </Box>
            </Box>

            <DialogContent sx={{ padding: "32px 0 32px 0", width: "334px", textAlign: "center" }}>
              <Typography>
                Переход на этот тариф будет осуществлен как только закончится оплаченный период по текущему тарифу с
              </Typography>

              <Typography>{convertData(dateRange?.date)}</Typography>
              <Typography>
                Осталось {dateRange?.days} дней
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: "0" }}>
              <Button
                onClick={saveSelectedTariff}
                className="btn btn-primary-fill magazine_events-select_container-btn"
                sx={{ margin: "auto" }}
              >
                Перейти
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}

      {isVisibleSpecialTariffModal && (
        <Dialog
          open={isVisibleSpecialTariffModal}
          onClose={() => setIsVisibleSpecialTariffModal(false)}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className="tariff">
            <Box className="tariff-title">
              <Typography>Редактирование индивидуального тарифа</Typography>
              <Box className="tariff-close-btn" onClick={() => setIsVisibleSpecialTariffModal(false)}>
                <CloseIcon />
              </Box>
            </Box>

            <DialogContent sx={{ padding: "32px 0 32px 0", width: "500px", }}>
              <form id="customTariffForm" onSubmit={handleSubmit(onSubmit)} className="pay_box_form">
                {customTarrifField.map(({ id, name, input, ...rest }) => (
                  <FormItem key={id} control={control} errors={errors} name={name} input={input} {...rest} />
                ))}
              </form>
            </DialogContent>
            <DialogActions sx={{ padding: "0" }}>
              <Button
                type="submit"
                form="customTariffForm"
                className="btn btn-primary-fill magazine_events-select_container-btn"
                sx={{ margin: "auto" }}
              >
                Отправить
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ChangeTariffModal;
