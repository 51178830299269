const DefaultUserAvatar = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#EEF4FD" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10C17.292 10 15.0968 12.0748 15.0968 14.6341C15.0968 17.1935 17.292 19.2683 20 19.2683C22.708 19.2683 24.9032 17.1935 24.9032 14.6341C24.9032 12.0748 22.708 10 20 10ZM16.6452 14.6341C16.6452 12.883 18.1472 11.4634 20 11.4634C21.8528 11.4634 23.3548 12.883 23.3548 14.6341C23.3548 16.3853 21.8528 17.8049 20 17.8049C18.1472 17.8049 16.6452 16.3853 16.6452 14.6341Z"
        fill="#4D5C6E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9032 20.7317C14.1952 20.7317 12 22.8065 12 25.3659C12 27.9252 14.1952 30 16.9032 30H23.0968C25.8048 30 28 27.9252 28 25.3659C28 22.8065 25.8048 20.7317 23.0968 20.7317H16.9032ZM13.5484 25.3659C13.5484 23.6147 15.0504 22.1951 16.9032 22.1951H23.0968C24.9496 22.1951 26.4516 23.6147 26.4516 25.3659C26.4516 27.117 24.9496 28.5366 23.0968 28.5366H16.9032C15.0504 28.5366 13.5484 27.117 13.5484 25.3659Z"
        fill="#4D5C6E"
      />
    </svg>
  );
};

export default DefaultUserAvatar;
