const DropdownIcon = ({rotateDegree}) => {
  return (
    <svg style={{transform: `rotate(${rotateDegree}deg)`}} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7732 1.39414C12.0775 1.07319 12.0753 0.555156 11.7683 0.237065C11.4613 -0.0810266 10.9658 -0.0787153 10.6615 0.242227L8.83826 2.16547C8.09829 2.946 7.58903 3.48142 7.15753 3.84372C6.73837 4.19564 6.46913 4.31637 6.228 4.3485C6.0766 4.36868 5.9234 4.36868 5.772 4.34851C5.53087 4.31637 5.26164 4.19564 4.84248 3.84372C4.41097 3.48142 3.90171 2.94601 3.16174 2.16547L1.33846 0.242228C1.0342 -0.0787146 0.538683 -0.0810257 0.231695 0.237065C-0.075293 0.555157 -0.0775037 1.0732 0.226757 1.39414L2.08288 3.35202C2.78195 4.08945 3.35331 4.69215 3.86239 5.11958C4.39178 5.56404 4.9291 5.88579 5.57409 5.97174C5.8569 6.00942 6.1431 6.00942 6.42591 5.97174C7.0709 5.88579 7.60823 5.56404 8.13761 5.11957C8.64669 4.69215 9.21804 4.08945 9.91711 3.35203L11.7732 1.39414Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DropdownIcon;
