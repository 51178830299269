import { Box, Typography } from "@mui/material";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditPen from "../../../components/icons/Editpen";
import { useAppSelector } from "../../../../hooks/redux";
import { OpenInNew } from "@material-ui/icons";
import priorityNewIcon from "./../../../../image/priorityNews.png";
import { PushPinRounded } from "@mui/icons-material";

const NewsPanel = ({
  news,
  handleVisibleNewsModal,
  handleDeleteNews,
  handleEditNews,
  handleChangeNewsPriority,
}) => {
  const { date, city, title, content, id, priority } = news;
  const { role } = useAppSelector((state) => state.users.info);
  return (
    <Box
      className="news-panel"
      onClick={() => handleVisibleNewsModal(true, news)}
    >
      <Box className="news-panel-adress">
        <Typography>
          {new Date(date).toLocaleDateString("ru-Ru")}, {city}{" "}
          {priority === 1 && (
            <Box className="news-panel-important-news">
              <img src={priorityNewIcon} alt="Важная новость" />
            </Box>
          )}
        </Typography>
        <Box className="news-panel-btns-wrapper">
          {role === "Суперадмин" && (
            <>
              <Box
                className="news-panel-edit"
                onClick={(e) => handleChangeNewsPriority(e, priority, id)}
              >
                <PushPinRounded />
              </Box>

              <Box
                className="news-panel-delete"
                onClick={(e) => handleDeleteNews(e, news)}
              >
                <DeleteIcon />
              </Box>
              <Box
                className="news-panel-edit"
                onClick={(e) => handleEditNews(e, news)}
              >
                <EditPen />
              </Box>
            </>
          )}
          <Box className="news-panel-open-icon">
            <OpenInNew />
          </Box>
        </Box>
      </Box>

      <Typography className="news-panel-title">{title}</Typography>

      <Typography
        className="news-panel-text truncate"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
};

export default NewsPanel;
