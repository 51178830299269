import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox, Typography, Box } from "@mui/material";
import CheckBoxChecked from "../icons/CheckBoxChecked";
import CheckBox from "../icons/CheckBox";

const CheckboxSimple = ({
  label,
  onChange,
  value,
  disabled,
  tLabel = true,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      className="checkbox"
      label={
        <Typography className={`checkbox-label ${className}`}>
          {tLabel ? t(label) : label}
        </Typography>
      }
      control={
        <Checkbox
          checked={!!value}
          onChange={onChange}
          disabled={disabled}
          sx={{ borderRadius: 0, mr: "8px", "& .MuiCheckbox-root": {padding: "0px !important"} }}
          // icon={<Box component="img" src={checkImage} />}
          icon={<Box><CheckBox /></Box>}
          // // checkedIcon={<Box component="img" src={checkImagechecked} />}
          checkedIcon={<Box sx={{color: "var(--headers)"}}><CheckBoxChecked /></Box>}
        />
      }
    />
  );
};

export default CheckboxSimple;
