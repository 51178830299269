import InputText from "../../../components/InputText";
import { ObjectsSelect } from "../../EmployeeList/form-config";
import SelectSimple from "../../../components/SelectSimple";
import {Box, Typography, Button} from "@mui/material";

export const formFields = {
  name: "name",
  type:"type",
  protectionObjectIds: "protectionObjectIds",
};
export const typeOptions = [
  {value:"Contact ID",name:"Contact ID"},
  {value:"Орион",name:"Орион"}
]
export const config = [
  {
    name: formFields.name,
    label: "tableHeader:name",
    type: "text",
    required:true,
 
    input: (props) =>  <Box sx={{ width: "300px" }}><InputText {...props} /></Box>,
  },
  {
    name: formFields.type,
    label: "label:controllerType",
    type: "select-number",
    options: typeOptions,
 
    defaultValue:"Contact ID",
    rules: {},
    input: (props) =>  <Box sx={{ width: "300px" }}><SelectSimple {...props} /></Box> ,
  },
  {
    name: formFields.protectionObjectIds,
    type: "select-multi",
    label: "label:objects",
    input: (props) => <Box sx={{ width: "300px" }}><ObjectsSelect {...props} /></Box>,
  },
];
