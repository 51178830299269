import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import { Stage, Layer, Line, Group, Image, Rect, Text, Circle } from "react-konva";
import { useWatch } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";

import { loadImg } from "../../../helpers/helper";
import { getParamsTextWithScale } from "./helpers";
import { ICON_SIZE } from "../../../constants";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";

import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Equipments/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";

const SchemaWithEquipmentsAddEquip = ({
  name,
  width,
  height,
  focusId,
  schemaImg,
  roomAddresses = [],
  setValue,
  openEditModal,
  control,
  draggable = false,
  withScrollbars,
  title,
  canvasRef = null,
  isPlan = false,
  xyGroup,
  minScale,
  equipmentCoordinatesChange,
  setisloadScheme,
  isloadScheme,
  isChangeFloor,
  setequipmentCoordinatesChange,
  transformCanvasData,
  setTransformCanvasData,
  widthWorkplace,
  heightWorkplace,
  imageRef,
  image,
  setImage,
  setscaleAddEquip,
  scaleAddEquip,
  setoffsetAddEquip,
  offsetAddEquip,
  scaleRoom,
  setxyGroup,
  fullscreen = false,
  setFullScreen,
  onRightClick,
}) => {
  // const canvasRefFF =  canvasRef ? canvasRef :useRef(null);
  const { equipmentsCoordinates: data } = useWatch({
    control,
  });

  const [icons, setIcons] = useState([]);
  const [iconsSize, seticonsSize] = useState({ width: 25, height: 25 });
  const [ChartSize, setChartSize] = useState({ width: 22, height: 22 });
  const [alarmSize, setalarmSize] = useState({ width: 17, height: 17 });
  const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const { SCHEMA_CANVAS1 } = useAppSelector((state) => state.settings);
  const { setCanvasSize1 } = useAction();
  const stageRef = useRef(null);
  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });
  const shadowProps = {
    x: 12,
    y: 12,
    shadowColor: 'blue',
    shadowBlur: 50,
    shadowEnabled: true,
    shadowOpacity: 1,
    shadowOffset: { x: 2, y: 3 }
  }
  useEffect(() => {
    seticonsSize({ width: 25 / window.devicePixelRatio, height: 25 / window.devicePixelRatio })
    setalarmSize({ width: 17 / window.devicePixelRatio, height: 17 / window.devicePixelRatio })
    setChartSize({ width: 22 / window.devicePixelRatio, height: 22 / window.devicePixelRatio })
  }, [window.devicePixelRatio])
  const drawIcons = useCallback(async (data, height = SCHEMA_CANVAS1.HEIGHT, width = SCHEMA_CANVAS1.WIDTH, ischangeData) => {
    if (scaleAddEquip >= minScale && icons.length == 0 || ischangeData) {

      if (scaleAddEquip >= minScale && data?.length) {

        const response = await Promise.all(
          data.map(async ({ id, floorSchemeCoordinateX: x, floorSchemeCoordinateY: y, typeId, workingCorrectly, jobChart }, index) => {
            return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
              const scaleX = width / SCHEMA_CANVAS1.WIDTH;
              const scaleY = height / SCHEMA_CANVAS1.HEIGHT;
              icon.width = iconsSize.width// Math.min(Math.max(countEquip,8),12);
              icon.height = iconsSize.height //Math.min(Math.max(countEquip,8),12)

              //   console.log("icon",icon.width,icon.height,(scale-minScale))
              // icon.width = icon.width > 7 ? icon.width :7
              // icon.height = icon.height > 7 ? icon.height : 7
              const sizeIcon = icon.width;

              const iconWithChart = {
                id,
                x: x * scaleX,
                y: y * scaleY,
                img: icon,

              };

              return iconWithChart;

            });
          })
        );
        setIcons(response);
      } else {

        setIcons([]);
      }
    } else if (scaleAddEquip < minScale && !ischangeData) {

      setIcons([]);
    }

  }, [SCHEMA_CANVAS1, getEquipmentIcon, getSrcSVG, loadImg]);
  const drawScheme = useCallback(async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize1({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);

    });
  }, [loadImg]);

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);

  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      drawIcons(newData, height, width, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleAddEquip, SCHEMA_CANVAS1]);

  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      SCHEMA_CANVAS1.WIDTH && drawIcons(newData, height, width, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, minScale, data]);


  const handleMouseOver = () => {
    if (draggable) document.body.style.cursor = "pointer";
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
  };

  const handleDragEnd = (e, id) => {
    const node = e.target;
    const scaleX = SCHEMA_CANVAS1.WIDTH / width;
    const scaleY = SCHEMA_CANVAS1.HEIGHT / height;

    setequipmentCoordinatesChange(prev => [...prev, id]);

    const requestData = data.map((item) => {
      return item.id === id
        ? {
          ...item,
          floorSchemeCoordinateX: node.x() * scaleX / scaleAddEquip,
          floorSchemeCoordinateY: node.y() * scaleY / scaleAddEquip,
        }
        : item;
    });

    setValue && setValue(name, requestData);
  };
  const findValueById = (dataArray, id, coor) => {
    const foundObject = dataArray?.find(item => item.id === id);
    return foundObject ? coor == "x" ? foundObject.x : foundObject.y : 0; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const findcoordById = (dataArray, id) => {
    const foundObject = xyGroup?.find(item => item.id === id);
    let resArray = dataArray
    if (foundObject)
      resArray = dataArray.map((item, index) => index % 2 === 0 ? item * foundObject.scaleX : item * foundObject.scaleY)
    return resArray; // Возвращает значение, если объект найден, иначе возвращает null или другое значение по умолчанию
  };
  const handleWheel = (e) => {
    if (!isPlan) return


    e.evt.preventDefault();
    const stage = stageRef.current;
    const canvas = canvasRef.current;
    let newScale = scaleAddEquip;
    if (e.evt.deltaY < 0) {
      newScale = scaleAddEquip <= minScale + 5 ? scaleAddEquip + 0.5 : scaleAddEquip; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scaleAddEquip > 0.5 ? scaleAddEquip - 0.5 : scaleAddEquip; // Ограничение минимального зума (0.5 в данном случае)
    }
    setscaleAddEquip((prev) => newScale);

    const pointTo = {
      x: stage.getPointerPosition().x / scaleAddEquip - canvas.x() / scaleAddEquip,
      y: stage.getPointerPosition().y / scaleAddEquip - canvas.y() / scaleAddEquip,
    };
    //setoffsetAddEquip((prev) => stageposition)
    setoffsetAddEquip({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });

  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if(!canvas) return
    const transformData = canvas.getTransform().m

    setTransformCanvasData({
      x: transformData[4],
      y: transformData[5],
    })
  }, [offsetAddEquip])
  const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
  }
  const handleDragEndStage = (e) => {

    const stage = canvasRef.current;

    const coordinates = [-stage.x() / scaleAddEquip, -stage.y() / scaleAddEquip, (width - stage.x()) / scaleAddEquip, (height - stage.y()) / scaleAddEquip];
    setoffsetAddEquip({ x: stage.x(), y: stage.y() })
    //  scrollRef.current.scrollTop = stage.y()
    //scrollRef.current.scrollLeft  = stage.x()
  }

  const handleDragMove = (e) => {
    const newX = e.target.x();
    const newY = e.target.y();

    // console.log("clampedX",e.target.y(),e.target.x())
    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scaleAddEquip - width);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scaleAddEquip - height);

    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);

    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });

  }

  const handleScrollbarMouseUp = (e, typeScroll) => {
    if (typeScroll == "vertical") {
      setoffsetAddEquip({ ...offsetAddEquip, y: -e.target.y() * scaleAddEquip });
    } else {
      setoffsetAddEquip({ ...offsetAddEquip, x: -e.target.x() * scaleAddEquip });
    }
    /* const canvas = canvasRef.current;
 
     const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
     groupClick(coordinates, null, scalecanvas, image.height, image.width, false, true);
     */
  };
  const handleScrollbarMouseMove = (e, typeScroll) => {

    const canvas = canvasRef.current
    if (typeScroll == "vertical") {
      canvas.position({ x: canvas.x(), y: -e.target.y() * scaleAddEquip })
    } else {
      canvas.position({ y: canvas.y(), x: -e.target.x() * scaleAddEquip })
    }
  };

  const handleShapeMouseMove = (e, text, coordinates) => {

    const mousePos = e.target.getStage().getPointerPosition();
    setTooltipText(text);
    setTooltipPosition({ x: mousePos.x + 5, y: mousePos.y + 5 }); // Обновляем позицию tooltip
    setTooltipVisible(true);
  };

  const handleShapeMouseOut = () => {
    setTooltipVisible(false);
  };
  return (

    <Box ref={scrollRef} sx={{ position: "relative", border: "1px solid #CFD9E8", borderRadius: "16px", padding: "10px", width: widthWorkplace ? widthWorkplace - 20 : width - 20, height: heightWorkplace ? heightWorkplace - 20 : height - 20 }}>{/*,overflow: 'auto'}}    onScroll={handleScroll} >*/}
      {( isChangeFloor || minScale == null) ? 
        <Box sx={{height:"100%",alignContent: "center",textAlign: "center"}}><CircularProgress/></Box>
      :

        <Stage
          width={widthWorkplace ? widthWorkplace - 20 : width - 20}
          height={heightWorkplace ? heightWorkplace - 20 : height - 20}
          ref={stageRef}

          onMouseEnter={e => {
            const container = e.target.getStage().container();
            container.style.cursor = 'grab';

          }}
          x={0}
          y={0}
        >
          <Layer ref={canvasRef}
            draggable
            //  style={stageStyles}
            onWheel={handleWheel}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEndStage}
            dragBoundFunc={pos => {
              //   console.log("pos",pos)
              const widthPos = widthWorkplace ? widthWorkplace : width
              const HeightPos = heightWorkplace ? heightWorkplace : height
              const offsetX = widthPos - image.width * scaleAddEquip
              const offsetY = HeightPos - image.height * scaleAddEquip
              //  console.log("stage",pos,image.width*scale,offsetX)

              if (offsetX <= 0) {
                if (pos.x <= offsetX) pos.x = offsetX;
                if (pos.x > 0) pos.x = 0;

              } else {
                if (pos.x > offsetX) pos.x = offsetX;
                if (pos.x <= 0) pos.x = 0;
              }

              if (offsetY <= 0) {
                if (pos.y <= offsetY) pos.y = offsetY;
                if (pos.y > 0) pos.y = 0;

              } else {
                if (pos.y > offsetY) pos.y = offsetY;
                if (pos.y <= 0) pos.y = 0;
              }
              return pos;
            }}

            x={offsetAddEquip.x}
            y={offsetAddEquip.y}
          >
            <Group
              scaleX={scaleAddEquip}
              scaleY={scaleAddEquip}
            >
              <Image ref={imageRef} image={image} x={0} y={0} />
              {roomAddresses?.map(({ id, coordinates, name, plumes, borderColor, color }, index) => (
                <Group key={id || index}

                  onMouseMove={(e) => handleShapeMouseMove(e, `${name}`, coordinates)}
                  onMouseOut={handleShapeMouseOut}
                >
                  <Line
                    points={coordinates}
                    stroke={borderColor ? borderColor : "#1876D2"}
                    fill={color ? hexToRgbA(color, 0.5) : "rgba(24,118,210,0.3)"}
                    strokeWidth={3 / scaleAddEquip}
                    closed={true}
                  />
                  {/* <Text {...getParamsTextWithScale({ coordinates:coordinates, text: name, line: 0, scale: scaleAddEquip })} /> 
                {scaleAddEquip >= 6 && <Text {...getParamsTextWithScale({ coordinates: coordinates, text: plumes[0]?.name || plumes[0], line: 1, scale: scaleAddEquip })} />}*/}
                </Group>
              ))}
            </Group>
            {!!icons?.length &&
              icons.map(({ id, x, y, img, chart, alarm }, index) => (
                <Group
                  onContextMenu={(e) => {
                    e.evt.preventDefault();
                    onRightClick && onRightClick(e, { id, x, y, img, chart, alarm }, index)
                  }}
                  key={id || index}
                  draggable={draggable}
                  onDragEnd={(e) => handleDragEnd(e, id)}
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  onDblClick={() => openEditModal(id)}

                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'default';

                  }}
                  x={!isNaN(x) ? x * scaleAddEquip : 0}
                  y={!isNaN(y) ? y * scaleAddEquip : 0}
                >

                  <Circle
                    {...(focusId === id ? shadowProps : {})}
                    width={focusId === id ? img.width + 50 : img.width}
                    strokeWidth={focusId === id ? 3 : 0}
                    stroke={"red"}
                    height={focusId === id ? img.height + 50 : img.height}
                    fill={focusId === id ? "rgba(57,57,242,0.4)" : "transparent"}

                  />
                  <Image image={img} x={0} y={0} onClick={() => { if (!isPlan) navigate(PATHS.GET_EQUIPMENT_DETAILS(id)) }} />
                </Group>
              ))}
          </Layer>
          <Layer>
            <Group>
              {image?.height * scaleAddEquip > heightWorkplace &&
                <Rect
                  width={25}
                  height={heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip}
                  fill="#1876D2"
                  opacity={0.8}
                  x={widthWorkplace - 15 - 20}
                  y={-(transformCanvasData.y) / scaleAddEquip}
                  draggable
                  dragBoundFunc={(pos) => {
                    pos.x = widthWorkplace - 15 - 20;
                    const offsetY = heightWorkplace - (image.height * scaleAddEquip - heightWorkplace) / scaleAddEquip
                    if (pos.y + offsetY > heightWorkplace) pos.y = heightWorkplace - offsetY - 5
                    if (pos.y < 5) pos.y = 5
                    return pos;
                  }}
                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'grab';

                  }}
                  onDragMove={(e) => {
                    handleScrollbarMouseMove(e, "vertical")
                  }}
                  onDragEnd={(e) => { handleScrollbarMouseUp(e, "vertical") }}
                />
              }
              {image?.width * scaleAddEquip > widthWorkplace &&
                <Rect
                  width={widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip}
                  height={25}
                  fill="#1876D2"
                  opacity={0.8}
                  y={heightWorkplace - 20 - 15}
                  x={-(transformCanvasData.x) / scaleAddEquip}
                  draggable
                  dragBoundFunc={(pos) => {
                    pos.y = heightWorkplace - 20 - 15;
                    const offsetX = widthWorkplace - (image.width * scaleAddEquip - widthWorkplace) / scaleAddEquip
                    if (pos.x + offsetX > widthWorkplace) pos.x = widthWorkplace - offsetX - 5
                    if (pos.x < 5) pos.x = 5
                    return pos;
                  }}
                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'grab';

                  }}
                  onDragMove={(e) => {
                    handleScrollbarMouseMove(e, "horizontal")
                  }}
                  onDragEnd={(e) => { handleScrollbarMouseUp(e, "horizontal") }}
                />
              }
            </Group>

          </Layer>
          <Layer>
            {tooltipVisible && (
              <Text
                text={tooltipText}
                fontSize={14}
                padding={10}
                textFill="white"
                fill="black"
                fontStyle="bold"
                alpha={1}
                visible={tooltipVisible}
                x={tooltipPosition.x} // Устанавливаем позицию по x
                y={tooltipPosition.y} // Устанавливаем позицию по y
              />
            )}

          </Layer>
        </Stage>
      }
    </Box>

  );
};

export default memo(SchemaWithEquipmentsAddEquip)