import React,{useState,useMemo,useEffect} from "react";
import { useAppSelector } from "../../../../hooks/redux";
import SelectGrouping from "../../../components/SelectGrouping";
import SelectSimple from "../../../components/SelectSimple";


export const SelectSimpleObject = (props) => {
  const { facilitySelectElements } = useAppSelector(
    (state) => state.facilities
  );
  const { searchObject,setsearchObject} = props

  return (
    <SelectGrouping
      
      setSearchValue={setsearchObject}
      searchValue={searchObject}
      options={[...facilitySelectElements]}
      //bgcolor={bgcolor}
      {...props}
    />
  );

};
export const SelectSimpleFloor = (props) => {
  const { floorOptionsSelectByFacility } = useAppSelector(
    (state) => state.facilities
  );

  return <SelectSimple options={floorOptionsSelectByFacility} {...props} />;
};

