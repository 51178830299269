import { Tab, Tabs, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchInbuilt } from "../../../components/SearchInbuilt";
import "./style.css"
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const PageTabs = ({ tabValue, handleChangeTab }) => {
  const { t } = useTranslation();
  return (
    <Tabs value={tabValue} onChange={handleChangeTab} className="objects-tabs" sx={{ mb: 0 }}>
      <Tab className="label" label={t("label:objects")} />
      <Tab className="label" label={t("label:billing")} />
      <Tab className="label" label={t("label:documents")} />
    </Tabs>
  );
};

export const TabsTitle = ({ tabValue, searchFacility, setSearchFacility }) => {
  const { t } = useTranslation();
  return (
    <>
      <TabPanel value={tabValue} index={0} className="scrolled-form scrolled-tab_panel" >
        <Box sx={{ display: "flex", flexFirection: "row !important", justifyContent: "space-between",alignItems:"center" }}>
          <Typography className="title-page">{t("label:objects")}</Typography>
          <Box className="search-facility" sx={{mr:"24px",alignContent: "center"}}>
            <SearchInbuilt
              placeholder={t("placeholder:facilitySearch")}
              searchValue={searchFacility}
              
              changeSearchValue={setSearchFacility}
            />
          </Box>

        </Box>

      </TabPanel>
      <TabPanel value={tabValue} index={1} className="scrolled-form scrolled-tab_panel">
        <Typography className="title-page">{t("label:billing")}</Typography>
      </TabPanel>
      <TabPanel value={tabValue} index={2} className="scrolled-form scrolled-tab_panel">
        <Typography className="title-page">{t("label:documents")}</Typography>
      </TabPanel>
    </>
  );
};;

