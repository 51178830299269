import SelectSimple from "../../../components/SelectSimple";
import TaskDetailsForm from "./TaskDetailsForm";
import { t } from "i18next";
import { formFields } from "./options";
import DatePickerField from "../../../components/DatePickerField";
import InputTextarea from "../../../components/InputTextarea";
import { InputFileMultiple } from "../../../components/InputFile/InputFileMultiple";
import { fileTypeCheck, fileSizeCheck } from "../../../../validation";
import { FilesForm } from "./FilesForm";
import { Box } from "@mui/material";
import {
  ExecutiveUserNameSelectSimple,
  EquipmentTypeIdSelectGrouping,
  SelectSimpleEquipmentsGroup,
  SelectEquipmentsName,
} from "./Components";
// import SelectMultipleChipDeletable from "../../../components/SelectMultipleChipDeletable";
import { priorityOptions } from "./options";
import { SelectSimpleObject, SelectSimpleFloor } from "./Components";
/*const JobStandardsSelect = (props) => {
    const options = [];
  
    return <SelectMultipleChipDeletable {...props} options={options} />;
  };
  const EquipmentsSelect = (props) => {
    const options = [];
  
    return <SelectMultipleChipDeletable {...props} options={options} />;
  };*/
/*export const checkListColumns = ({ control }) => [
    {
      id: formFields.isCompleted,
      type: "checkbox",
      rules: {},
      label: "Не/Выполнено",
      clickable: true,
      RowCell: (props) => (
        <FormItem
          name={`${formFields.jobChecklist}[${props.index}].${formFields.isCompleted}`}
          control={control}
          input={(props) => <CheckboxSimple {...props} />}
        />
      ),
    },
    {
      id: formFields.name,
      numeric: false,
      label: "Название",
      enableSorting: false,
      clickable: true,
      RowCell: (props) => <InputText {...props} />,
    },
    {
      id: formFields.description,
      numeric: false,
      label: "Описание",
      enableSorting: false,
      clickable: true,
      RowCell: (props) => <InputTextarea {...props} />,
    },
    {
      id: formFields.files,
      numeric: false,
      label: "Описание",
      enableSorting: false,
      clickable: true,
      RowCell: (props) => <InputFileSingle {...props} accept={".png, .jpg, .jpeg, .pdf"} />,
    },
   
    
  ];*/
export const formConfigFloorAndObject = (
  isActive,
  dynamicData,
  isSuccess,
  facilityId,
  setValue,
  isCanDisabled,
  resetField
) => [
    {
      name: formFields.ProtectionObjectId,
      label: `label:${formFields.ProtectionObjectId}`,
      type: "select-text",
      rules: {
        required: true,
      },
      width: "100%",
      displayEmpty: true,
      placeholder: "placeholder:object_select",
      isCanDisabled: false,
      input: (props) => <SelectSimpleObject {...props} />,
    },
    {
      name: formFields.FloorInfoId,
      label: `label:${formFields.FloorInfoId}`,
      type: "select-number",
      width: "100%",
      displayEmpty: true,
      placeholder: "placeholder:floor_select",
      isCanDisabled: true,
      input: (props) => <SelectSimpleFloor {...props} />,
    },
  ];

export const formConfigEquipmentPPZ = (
  objectId,
  equipmentTypeIdJSON,
  equipmentGroupId,
  floorInfoId,
  error,
  setValue
) => [
    {
      name: formFields.equipmentTypeId,
      label: "label:equipmentTypeId",
      type: "select-text",
      flexBasis: "100%",
      rules: {
        required: false,
      },
      placeholder: "placeholder:systemPPZ",
      disabled: !objectId,
      input: (props) => (
        <EquipmentTypeIdSelectGrouping objectId={objectId} {...props} />
      ),
    },
    {
      name: formFields.equipmentGroupId,
      label: "label:equipmentGroup",
      placeholder: "placeholder:chooseEquipmentGroup",
      type: "select-text",
      flexBasis: "100%",
      rules: {
        required: false,
      },
      disabled: !equipmentTypeIdJSON,
      displayEmpty: true,
      input: (props) => (
        <SelectSimpleEquipmentsGroup
          equipmentTypeIdJSON={equipmentTypeIdJSON}
          floorInfoId={floorInfoId}
          objectId={objectId}
          {...props}
        />
      ),
    },
    {
      name: formFields.jobEquipments,
      label: "label:nameEquipment",
      placeholder: "placeholder:chooseEquipment",
      type: "text",
      flexBasis: "49%",
      rules: {
        required: true,
      },
      displayEmpty: true,
      // disabled: !equipmentGroupId,
      input: (props) => (
        <SelectEquipmentsName
          floorInfoId={floorInfoId}
          equipmentGroupId={equipmentGroupId}
          error={error}
          {...props}
        />
      ),
    },
  ];

export const formConfigPriority = (
  isActive,
  dynamicData,
  isSuccess,
  objectId,
  setValue,
  isCanDisabled,
  resetField
) => [
    /*{
          name: formFields.name,
          label: "label:taskName",
          type: "text",
          rules: {
              required: true,
          },
          disabled: false,
          input: (props) => <InputText {...props} />,
        },
        */
    {
      name: formFields.priority,
      label: "tableHeader:priority",
      type: "text",
      height: "56px",
      rules: {
        required: true,
      },
      disabled: false,
      //value:PRIORITY_ID[fields.lower],
      input: (props) => <SelectSimple {...props} options={priorityOptions} />,
    },
    /*{
          name: formFields.jobType,
          label: "tableHeader:jobType",
          type: "text",
          height: "56px",
          rules: {
              required: true,
          },
          disabled: false,
          input: (props) => <SelectSimple {...props} options={jobTypeOptions} />,
        },*/
    /* {
          name: formFields.fireCompartmentId,
          label: "label:fireCompartmentId",
          type: "select-text",
          rules: {
            
          },
          input: (props) => (
            <EquipmentTypeIdSelectGrouping {...props} />
          ),
        },
         /* {
            name: formFields.fireCompartmentId,
            label: "label:fireCompartmentId",
            type: "select-text",
            rules: {
              
            },
            input: (props) => (
              <FacilityAndFireCompartmentSelectGrouping {...props} />
            ),
          },
          
          /*{
              name: formFields.jobStandards,
              label: t("label:GOST"),
              type: "select-multi",
              disabled: false,
              input: (props) => <JobStandardsSelect {...props} />,
            },
            
            {
              id: 1,
              name: formFields.jobFiles,
              type: "file",
              rules: {
                validate: {
                  type: (files) => {
                    const availableFileTypes = [
                      "image/jpeg",
                      "image/png",
                      "application/pdf",
                    ];
                    return fileTypeCheck(
                      files.filesToAdd,
                      formFields.jobFiles,
                      availableFileTypes
                    );
                  },
                  size: (files) => {
                    const maxAvailableFileSize = 10; // MBs
                    return fileSizeCheck(
                      files.filesToAdd,
                      formFields.jobFiles,
                      maxAvailableFileSize
                    );
                  },
                },
              },
              canDeleteFile: true,PRIORITY_IDdead
              input: (props) => (
                <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} />
              ),
            },
          /*{
              name: formFields.fireCompartmentId,
              label: "label:fireCompartmentId",
              type: "select-text",
              rules: {
                required: true,
              },
              input: (props) => (
                <EquipmentTypeIdSelectGrouping {...props} />
              ),
            },
          */
  ];
export const equipmentsConfig = ({ control }) => [
  {
    name: formFields.jobEquipments,
    label: t("label:jobEquipments"),
    type: "select-text",

    rules: {},
    input: (props) => <EquipmentTypeIdSelectGrouping {...props} />,
  },
];
export const taskDetailsConfig = [
  /* {
        name: formFields.responsibleOrganizationId,
        label: t("label:performer"),
        type: "select-text",
        disabled: true,
        rules: {
          validate: {
            required: (value, formValues) => {
              if (formValues.selfService) {
                return null;
              }
              return !!value;
            },
          },
        },
        input: (props) => <PerformerSelectGrouping {...props} />,
      },
      */
  {
    name: formFields.responsibleCustomerUserId,
    label: t("label:resCreator"),
    type: "select-text",
    disabled: true,
    displayEmpty: true,
    placeholder: "placeholder:resUser",
    rules: {
      required: true,
    },
    input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
  },
  {
    name: formFields.executiveUserId,
    label: t("label:executiveUserName"),
    type: "select-text",
    disabled: true,
    displayEmpty: true,
    placeholder: "placeholder:executive_user",
    rules: {
      required: true,
    },
    sx: { width: "100%" },
    input: (props) => <ExecutiveUserNameSelectSimple {...props} />,
  },
  {
    name: formFields.deadline,
    label: t("label:deadline"),
    type: "date",
    rules: {},
    disabled: true,
    input: (props) => (
      <Box sx={{ maxWidth: "50%" }}>
        <DatePickerField {...props} />
      </Box>
    ),
  },
  /*{
        name: formFields.initialEstimate,
        label: t("label:initialEstimate"),
        type: "customTime",
        rules: {},
        disabled: true,
        input: (props) => (
          <InputHandleEdit
            style={{ maxWidth: "49%" }}
            {...props}
            Component={InputText}
          />
        ),
      },
      {
        name: formFields.actualTimeSpent,
        label: t("label:actualTimeSpent"),
        type: "customTime",
        rules: {},
        disabled: true,
        input: (props) => (
          <InputHandleEdit
            style={{ maxWidth: "49%" }}
            {...props}
            Component={InputText}
          />
        ),
      },
    s
   /* {
      name: formFields.comments,
      label: t("label:comment"),
      type: "text",
      disabled: false,
      input: (props) => <InputTextarea {...props} />,
    },*/
  {
    name: formFields.description,
    label: t("label:description"),
    type: "text",
    placeholder: "placeholder:description",
    rules: {},
    disabled: true,
    input: (props) => <InputTextarea {...props} />,
  },
];
export const filesConfig = [
  {
    id: 3,
    name: formFields.jobFiles,
    type: "file",
    rules: {
      validate: {
        type: (files) => {
          const availableFileTypes = [
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return fileTypeCheck(
            files.filesToAdd,
            formFields.jobFiles,
            availableFileTypes
          );
        },
        size: (files) => {
          const maxAvailableFileSize = 10; // MBs
          return fileSizeCheck(
            files.filesToAdd,
            formFields.jobFiles,
            maxAvailableFileSize
          );
        },
      },
    },
    canDeleteFile: true,
    input: (props) => (
      <InputFileMultiple {...props} accept={".png, .jpg, .jpeg, .pdf"} />
    ),
  },
];
export const sections = ({ control, isHidden }) => [
  /*   {
      id: 1,
      title: "equipments",
      Component: EquipmentsForm,
      config: equipmentsConfig({ control }),
    },*/
  {
    id: 2,
    title: "tasks_details",
    Component: TaskDetailsForm,
    config: taskDetailsConfig,
  },
  {
    id: 33,
    title: "files",
    Component: FilesForm,
    config: filesConfig,
  },

  /*{
      id: 4,
      title: "check_list",
      Component: ChecklistForm,
      config: checkListColumns({ control }),
    },*/
];
