import React, { useState,useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks/redux";
import { PageTabs, TabsTitle } from "./TabsComponents";
import PayBox from "./PayBox";
import GridType from "./GridType";
import ObjectsList from "./ObjectsList";
import BillingsList from "./BillingsList";
import DocumentsTable from "./DocumentsTable";

const Cabinet = () => {
  const { t } = useTranslation();
  const $header = useRef(null)
  const token = useAppSelector((state) => state.users.token);
  const [tabValue, setTabValue] = useState(0);
  const [gridTypeValue, setGridTypeValue] = useState(0);
  const [isOpenPayForm, setIsOpenPayForm] = useState(false);
  const [searchFacility, setSearchFacility] = useState("");

  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeGridType = (newValue) => {
    setGridTypeValue(newValue);
  };

  const toggleVisiblePayform = (isVisible) => {
    setIsOpenPayForm(isVisible);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box ref={$header}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: "40px", alignItems: "flex-end " }}>
          <Box>
            <Typography className="title-page" sx={{ mt: "10px !important", mb: "41px !important" }}>
              {t("label:cabinet")}
            </Typography>
            <PageTabs tabValue={tabValue} handleChangeTab={handleChangeTab} />
          </Box>
          <Box sx={{ display: "flex", gap: "30px" }}>
            {tabValue === 0 && <GridType gridTypeValue={gridTypeValue} handleChangeGridType={handleChangeGridType} />}
            <PayBox
              toggleVisiblePayform={toggleVisiblePayform}
              isOpenPayForm={isOpenPayForm}
            />
          </Box>
        </Box>

        <TabsTitle tabValue={tabValue} searchFacility={searchFacility} setSearchFacility={setSearchFacility} />
      </Box>


      {tabValue === 0 && <ObjectsList gridTypeValue={gridTypeValue} headerRef={$header} searchFacility={searchFacility}/>}
      {tabValue === 1 && <BillingsList />}
      {tabValue === 2 && <DocumentsTable />}
    </Box>
  );
};

export default Cabinet;
