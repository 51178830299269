import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";

import "./styles/style.css";
import {
  // allColumns,
  formFields,
  getConfigFormModalSelect,
} from "./table-config";
import { useAction } from "../../../hooks/actions";
import { getColumns } from "./columns";
import { useAppSelector } from "../../../hooks/redux";
import {
  useLazyGetLogOfWorkDoneOnTasksQuery,
  useLazyPrintLogsListQuery,
} from "../../../middleware/logsApi";
// import { getBgColor } from "./helpers";
import EquipmentDialog from "../EquipmentList/EquipDialog";
// import FormItem from "../../components/FormItem";
// import SelectSimple from "../../components/SelectSimple";
import TableBasic from "../../components/TableBasic";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import CircularProgress from "@mui/material/CircularProgress";
import TaskDialog from "../TasksList/TaskDialog/TaskDialog";
import settingsicon from "../../../image/icons/settingsButtonWhite.png";
import exporticon from "../../../image/icons/exportBtnWhite.png";
import PageheaderNav from "../../components/PageheaderNav";
import PrintIcon from "../../components/icons/PrintIcon";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { dateFormatForSubmit } from "../../../constants";
import { getTableHieght } from "../../../helpers/getTableHieght";
import useScreenSize from "../../../hooks/useScreenSize";

export const MagazineFinishedTasks = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues, reset, watch } = useForm({
    mode: "onChange",
  });

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const facilityId = watch(
    `${formFields.searchParams}.${formFields.FacilityId}`
  );

  const {
    searchWorkScopeNameStorage,
    roomPlumeStorage,
    equipNameStorage,
    searchWorkScopeDetailsStorage,
    executiveUserStorage,
    responsibleUserStorage,
    taskNameStorage,
    dateRangeStorage,
  } =
    JSON.parse(
      sessionStorage.getItem("magazineFinishedEventsListSearchParams")
    ) || {};

  const { allLogOfWorkDoneOnTasks } = useAppSelector((state) => state.logs);
  const { setIsErrorRequest } = useAction();
  // const [openObjectDialog, setOpenObjectDialog] = useState(false);
  // const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [facilities, setFacilities] = useState(""); //mock
  const [pageNumber, changePageNumber] = useState(0);
  // const [searchJobname, setsearchJobname] = useState("");
  // const [searchValue, changeSearchValue] = useState("");
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  const [taskName, setTaskName] = useState(taskNameStorage || "");
  const [equipName, setequipName] = useState(equipNameStorage || "");
  const [roomPlume, setroomPlume] = useState(roomPlumeStorage || "");
  const [executiveUser, setexecutiveUser] = useState(
    executiveUserStorage || ""
  );
  const [responsibleUser, setresponsibleUser] = useState(
    responsibleUserStorage || ""
  );
  const [searchworkScopeName, setsearchworkScopeName] = useState(
    searchWorkScopeNameStorage || ""
  );
  const [searchworkScopeDetails, setsearchworkScopeDetails] = useState(
    searchWorkScopeDetailsStorage || ""
  );

  const [loading, setLoading] = useState(false);
  const [printloading, setPrintLoading] = useState(false);
  const [dateRange, setDateRange] = useState(dateRangeStorage || [null, null]);

  const screenSize = useScreenSize();

  const {
    data: gridcolumns,
    error: errorgridColumns,
    isLoading: isLoadingerrorColumns,
  } = useGetUserGridSettingQuery({ gridSettingName: "CompletedJobJournal" });

  const [
    getUserGrid,
    { error: errorgrid, refetch: refetchUserGrid /* isLoading */ },
  ] = useLazyGetUserGridSettingQuery();
  const [getLogOfWorkDoneOnTasks, { error, isLoading }] =
    useLazyGetLogOfWorkDoneOnTasksQuery();
  const [printLogsList, { errorPrint, isLoadingPrint }] =
    useLazyPrintLogsListQuery();

  const handleOpenTaskDialogState = (id) => {
    setOpenTaskDialog(true);
    setSelectedTaskId(id);
  };

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage =
      JSON.parse(
        sessionStorage.getItem("magazineFinishedEventsListSearchParams")
      ) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem(
      "magazineFinishedEventsListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
  }

  function clearFiltersHandler() {
    setTaskName("");
    setequipName("");
    setroomPlume("");
    setexecutiveUser("");
    setresponsibleUser("");
    setsearchworkScopeName("");
    setsearchworkScopeDetails("");
    setDateRange([null, null]);
    reset();
    sessionStorage.removeItem("magazineFinishedEventsListSearchParams");
  }

  const rows = useMemo(() => {
    const { data } = allLogOfWorkDoneOnTasks || {};

    return data?.length
      ? data.map(
          ({
            workScopeName,
            completedAt,
            roomAddressPlume,
            executiveUser,
            facilityName,
            isExecutiveUserSign,
            isVerifyingUserSign,
            jobDescription,
            jobId,
            jobName,
            equipmentKindAndType,
            responsibleCustomer,
            workScopeDetails,
            responsibleCustomerSignature,
            executiveUserSignature,
            jobNumber = "",
            equipmentId,
            equipmentName = "",
          }) => {
            return {
              jobName:
                jobId && jobId != null ? (
                  <Button
                    className="table-row-cell-link"
                    sx={{ verticalAlign: "initial" }}
                    onClick={() => handleOpenTaskDialogState(jobId)}
                  >
                    {`${jobName}`}
                  </Button>
                ) : (
                  <></>
                ),
              workScopeName,
              workScopeDetails,
              completedAt,
              equipmentKindAndType,
              roomAddressPlume: roomAddressPlume || t("label:doesntHave"),
              executiveUser,
              responsibleCustomer,
              facilityName: facilityName || t("label:doesntHave"),
              isExecutiveUserSign,
              isVerifyingUserSign,
              jobDescription,
              responsibleCustomerSignature,
              executiveUserSignature,
              equipmentName: (
                <Button
                  className="table-row-cell-link"
                  sx={{ verticalAlign: "initial", textAlign: "left" }}
                  onClick={() => handleOpenEquipment(equipmentId)}
                >
                  {equipmentName}
                </Button>
              ),
            };
          }
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLogOfWorkDoneOnTasks]);

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };

  const columns = useMemo(
    () =>
      getColumns({
        control,
        columnsFromStorage,
        jobName: taskName,
        setJobName: setTaskName,
        searchworkScopeName,
        setsearchworkScopeName,
        searchworkScopeDetails,
        setsearchworkScopeDetails,
        equipName,
        setequipName,
        roomPlume,
        setroomPlume,
        daterange: dateRange,
        setdaterange: setDateRange,
        executiveUser,
        facilities,
        setFacilities,
        setexecutiveUser,
        responsibleUser,
        setresponsibleUser,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const recordsCount = useMemo(
    () => allLogOfWorkDoneOnTasks && allLogOfWorkDoneOnTasks?.recordsCount,
    [allLogOfWorkDoneOnTasks]
  );

  const handleOpenSelectColumnsModal = () => {
    setOpenSelectModal(true);
  };

  useEffect(() => {
    const { facilityIdStorage } =
      JSON.parse(
        sessionStorage.getItem("magazineFinishedEventsListSearchParams")
      ) || {};
    setValue(
      `${formFields.searchParams}.${formFields.FacilityId}`,
      facilityIdStorage || ""
    );
  }, []);

  useEffect(() => {
    writeSearchDataToLocalStorage("facilityIdStorage", facilityId);
  }, [facilityId]);

  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.workScopeName}`,
      searchworkScopeName
    );
  }, [searchworkScopeName, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.${formFields.workScopeDetails}`,
      searchworkScopeDetails
    );
  }, [searchworkScopeDetails, setValue]);
  useEffect(() => {
    writeSearchDataToLocalStorage("dateRangeStorage", dateRange);
    setValue(`${formFields.searchParams}.CompletedDateRange`, dateRange);
  }, [dateRange, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.${formFields.TaskName}`, taskName);
  }, [taskName, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.RoomPlume`, roomPlume);
  }, [roomPlume, setValue]);
  useEffect(() => {
    setValue(`${formFields.searchParams}.EquipmentNameAndNumber`, equipName);
  }, [equipName, setValue]);

  useEffect(() => {
    setValue(`${formFields.searchParams}.ExecutiveUser`, executiveUser);
  }, [executiveUser, setValue]);
  useEffect(() => {
    setValue(
      `${formFields.searchParams}.ResponsibleCustomerUser`,
      responsibleUser
    );
  }, [responsibleUser, setValue]);

  useEffect(() => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentKindAndType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(
      `${formFields.searchParams}.${formFields.FacilityId}`
    );
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    const facility =
      facilId == "все" || !facilId
        ? { FacilityId: "" }
        : { FacilityId: facilId };
    if (params?.CompletedDateRange) delete params.CompletedDateRange;
    if (params?.FacilityId) delete params.FacilityId;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null)
        dd["CompletedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CompletedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    changePageNumber(0);
    getLogOfWorkDoneOnTasks({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...facility,
      ...params,
      pageNumber: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentKindAndType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(
      `${formFields.searchParams}.${formFields.FacilityId}`
    );
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    const facility =
      facilId == "все" || !facilId
        ? { FacilityId: "" }
        : { FacilityId: facilId };
    if (params?.CompletedDateRange) delete params.CompletedDateRange;
    if (params?.FacilityId) delete params.FacilityId;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null)
        dd["CompletedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CompletedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    getLogOfWorkDoneOnTasks({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...facility,
      ...params,
      pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setIsErrorRequest(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const base64toPDF = (name, content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false);
  };
  const printBase64 = (content) => {
    var bufferArray = base64ToArrayBuffer(content);
    var blob = new Blob([bufferArray], { type: "application/pdf" }); //this make the magic
    var blobURL = URL.createObjectURL(blob);

    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
    setPrintLoading(false);
  };

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const printLogs = (isPrint) => {
    if (isPrint) setPrintLoading(true);
    else setLoading(true);
    const equipmentTypeorKind = getValues(
      `${formFields.searchParams}.${formFields.equipmentKindAndType}`
    );
    let equipmentType = "";
    let equipmentKind = "";
    const createdDate = getValues(
      `${formFields.searchParams}.CompletedDateRange`
    );
    const facilId = getValues(
      `${formFields.searchParams}.${formFields.FacilityId}`
    );
    if (equipmentTypeorKind) {
      if (equipmentTypeorKind != "все")
        if (JSON.parse(equipmentTypeorKind).id != 0) {
          if (JSON.parse(equipmentTypeorKind)?.isType) {
            equipmentKind = Number(
              JSON.parse(equipmentTypeorKind).id.replace("parent", "")
            );
          } else {
            equipmentType = JSON.parse(equipmentTypeorKind).id;
          }
        }
    }
    const facility =
      facilId == "все" || !facilId
        ? { FacilityId: "" }
        : { FacilityId: facilId };
    if (params?.CompletedDateRange) delete params.CompletedDateRange;
    if (params?.FacilityId) delete params.FacilityId;
    let dd = {};
    if (createdDate) {
      if (createdDate[1] != null)
        dd["CompletedDateRange.MaxValue"] = dayjs(createdDate[1]).format(
          dateFormatForSubmit
        );
      if (createdDate[0] != null)
        dd["CompletedDateRange.MinValue"] = dayjs(createdDate[0]).format(
          dateFormatForSubmit
        );
    }
    printLogsList({
      EquipmentKindId: equipmentKind,
      EquipmentTypeId: equipmentType,
      ...dd,
      ...params,
      ...facility,
      pageNumber,
    }).then((data) => {
      const res = data.data;
      if (isPrint) printBase64(data.data.content);
      else base64toPDF(data.data.name, data.data.content);
    });
  };
  const itemsNav = ["magazines_events", "magazines_finished_tasks"];
  return (
    <Box className="template_page magazine_events">
      <Typography className="title-page">
        {t("title_page:magazines")}
      </Typography>
      <Box className="template_page-header">
        <PageheaderNav itemsNav={itemsNav} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {printloading ? (
            <Box className="full_screen_icon-wrapper">
              <CircularProgress
                className="full_screen_icon"
                sx={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5px",
                }}
              />
            </Box>
          ) : (
            <Box className="full_screen_icon-wrapper">
              <div
                onClick={() => printLogs(true)}
                style={{
                  marginTop: "5px",
                  border: "0px !important",
                }}
                className="full_screen_icon"
              >
                <PrintIcon />
              </div>
            </Box>
          )}
          <Button
            className="btn btn-primary-fill"
            onClick={clearFiltersHandler}
          >
            Очистить все фильтры
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={() => printLogs(false)}
            disabled={loading}
            sx={{ height: "49px" }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                <Box
                  component="img"
                  src={exporticon}
                  sx={{ marginRight: "16px" }}
                />
                {t("button:create_act_pdf")}
              </>
            )}
          </Button>
          <Button
            className="btn btn-primary-fill magazine_events-select_container-btn"
            variant="outlined"
            onClick={handleOpenSelectColumnsModal}
            sx={{ height: "52px" }}
          >
            <Box
              component="img"
              src={settingsicon}
              sx={{ marginRight: "16px" }}
            />
            {t("button:customize_fields_to_display")}
          </Button>
        </Box>
      </Box>

        {/* <Box className="magazine_events-select_container">
          <FormItem
            control={control}
            name={`${formFields.searchParams}.${formFields.eventsFilter}`}
            label={t("label:events")}
            type={"select-text"}
            options={eventsOptions}
            input={(props) => (
              <Box className="medium_container">
                <SelectSimple {...props} />
              </Box>
            )}
          />
        </Box>
            */}
        <TableBasic
          columns={columns}
          rows={rows}
          handleClickCell={() => {}}
          withSearchField={false}
          isLoading={isLoading}
          pageNumber={pageNumber}
          changePageNumber={changePageNumber}
          count={recordsCount}
          clearFiltersHandler={clearFiltersHandler}
          //  getCustomBgColorRow={getBgColor(bgColors)}
          minHeight={getTableHieght(screenSize).min}
          maxHeight={getTableHieght(screenSize).max}
        />

      <ModalSelectColumnsServer
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        data={columnsFromStorage}
        refetch={() => getUserGrid({ gridSettingName: "CompletedJobJournal" })}
        nameStorage={"CompletedJobJournal"}
        config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
      />

      {openTaskDialog && (
        <TaskDialog
          open={openTaskDialog}
          setOpen={setOpenTaskDialog}
          taskId={selectedTaskId}
        />
      )}

      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
        />
      )}
    </Box>
  );
};
