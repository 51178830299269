import { Box, CircularProgress } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import { Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { getDefaultSatrtDate, getDefaultEndDate } from "../../Analytics/helpers";
import {
  useLazyGetPlanDataForKindQuery,
  useLazyGetPlanDataForTypeQuery,
  useLazyGetPlanDataForGroupQuery,
  useGetPlanDataForEquipmentPostMutation,
  useUpdateEquipmentPlanPeriodRegulationsMutation,
  useLazyGetEquipmentGroupsQuery,
  useLazyGetEquipmentKindSelectElementsQuery, useLazyGetEquipmentsForTreeQuery,
} from '../../../../middleware/equipmentsApi';
import { ModalConfirmPagePlanning } from "../../../components/Modals/ModalConfirmPagePlanning";
import { TimelineThing } from "../../TimeTable/TimelineThing";
import {

  useLazyGetFacIntNumbersQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,

} from "../../../../middleware/facilitiesApi";
import CustomTreeItem from './CustomTreeItem';
import { formFields } from "./form-config";
import FormItem from "../../../components/FormItem";
import { SelectSimpleObject } from "./Components";
import SelectSimple from "../../../components/SelectSimple";
import { useTranslation } from "react-i18next";
import { RegulationList, EquipmentTypeIdSelectGrouping } from "./Components";
import { useForm } from "react-hook-form";
import { useAction } from "../../../../hooks/actions";
import Header from "../../Acts/Header";
import expandArrow from "../../../../image/icons/expandTreeArrow.png"
import collapseArrow from "../../../../image/icons/collapseTreeArrow.png"
import PageheaderNav from "../../../components/PageheaderNav";
import arrowLeft from "../../../../image/icons/leftArrow.png"
import arrowRight from "../../../../image/icons/rightArrow.png"
import editIconWhite from "../../../../image/icons/editIconWhite.png"
import {
  useLazyGetRegulationsforSelectionQuery,
} from "../../../../middleware/regulationsApi";
import dayjs from "dayjs";
import "dayjs/locale/ru";

export const config = [
  {
    id: 1,
    name: formFields.object,
    label: `label:${formFields.object}`,
    type: "select-text",
    rules: {},
    width: "320px",
    sx: { padding: "3px 0px 0px 0px" },
    isCanDisabled: true,
    input: (props) => <SelectSimpleObject {...props} />,
  },


];

const CollapseIcon = () => (
  <img src={collapseArrow} alt="collapseIcon" />
);
const ExpandIcon = () => (
  <img src={expandArrow} alt="collapseIcon" />
);

const ActsPlanning = ({ }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
    },
  });
  const { setIsErrorSubmit, setIsSuccessSubmit } =
    useAction();

  const [startDate, setStartDate] = useState(getDefaultSatrtDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [treeItems, setTreeItems] = useState([]);
  const [searchObject, setsearchObject] = useState("");
  const [searchKindType, setsearchKindType] = useState("");
  const [searchReguLation, setsearchReguLation] = useState("");
  const [selectedItemId, setSelectedItemId] = useState({ id: -1, periodsDates: [] });
  const [runOnce, setrunOnce] = useState(true);
  const [EditMode, setEditMode] = useState(false);
  const [searchIntNum, setsearchIntNum] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [scaleAddEquip, setscaleAddEquip] = useState(1);
  const [PageNumber, setPageNumber] = useState([]);
  const [loading, setLoading] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [expandedItems, setExpandedItems] = useState({});

  const [plansData, setplansData] = useState([]);
  const [expandedNodesHistory, setExpandedNodesHistory] = useState(new Set());
  const [newItems, setnewItems] = useState([]);

  const facilityId = watch(formFields.object);
  const regulationId = watch(formFields.regulations_equip);
  const equipmentTypeId = watch(formFields.equipmentTypeId);
  const monthsRu = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ]

  const configAfter = [
    {
      name: formFields.equipmentTypeId,
      label: "label:equipmentTypeId",
      type: "select-text",
      width: "380px",
      placeholder: "placeholder:systemPPZ",
      input: (props) => <EquipmentTypeIdSelectGrouping objectId={facilityId} {...props} />,
    },

    {
      id: 1,
      name: formFields.regulations_equip,
      label: `label:${formFields.regulations_equip}`,
      type: "select-text",
      rules: {},
      width: "320px",
      isCanDisabled: true,
      input: (props) => <RegulationList {...props} />,
    },

  ];
  const [
    getfacIntNum,
    { isError: isErrorgetfacIntNum, isLoading: isLoadinggetfacIntNum },
  ] = useLazyGetFacIntNumbersQuery();
  const [getEquipmentKinds] = useLazyGetEquipmentKindSelectElementsQuery();

  const [getEquipmentGroups] = useLazyGetEquipmentGroupsQuery();
  const [getEquipmentsForTree] = useLazyGetEquipmentsForTreeQuery();

  const [
    getPlanDataForKind,
    { isError: isErrorgetPlanDataForKind, isLoading: isLoadinggetPlanDataForKind },
  ] = useLazyGetPlanDataForKindQuery();

  const [
    getPlanDataForType,
    { isError: isErrorgetPlanDataForType, isLoading: isLoadinggetPlanDataForType },
  ] = useLazyGetPlanDataForTypeQuery();

  const [
    getPlanDataForGroup,
    { isError: isErrorgetPlanDataForGroup, isLoading: isLoadinggetPlanDataForGroup },
  ] = useLazyGetPlanDataForGroupQuery();


  const [
    getPlanDataForEquipmentPost,
    {
      error: errorgetPlanDataForEquipmentPost,
      isSuccess: isSuccessgetPlanDataForEquipmentPost,
      isLoadinggetPlanDataForEquipmentPost,
      isError: isErrorgetPlanDataForEquipmentPost,
    },
  ] = useGetPlanDataForEquipmentPostMutation();
  const [
    UpdateEquipmentPlanPeriodRegulations,
    {
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      isLoadingUpdate,
      isError: isErrorUpdate,
    },
  ] = useUpdateEquipmentPlanPeriodRegulationsMutation();

  console.log("handleClose",equipmentTypeId)
  const [
    getFacilitySelectElems,
    { isError: isErrorgetFacilitySelectElems, isLoading: isLoadinggetFacilitySelectElems },
  ] = useLazyGetFacilitySelectElementsWithSearchQuery();

  const [
    getRugulationsSelect,
    { isError: isErrorgetRugulationsSelect, isLoading: isLoadinggetRugulationsSelect },
  ] = useLazyGetRegulationsforSelectionQuery();

  useEffect(() => {
    setIsErrorSubmit(errorUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate]);


  const onToggleTree = (id, itemType, rawId) => {
    const existingIds = new Set(plansData.map(item => item.id));
    const equipTypeId = equipmentTypeId ? JSON.parse(equipmentTypeId)?.id == "все" ? "" : JSON.parse(equipmentTypeId)?.parentId : ""
    if (itemType === 'system') {
      getPlanDataForType({
        protectionObjectId: facilityId, startDate: startDate, endTime: endDate, regulationId: regulationId == "все" ? "" : regulationId, equipmentTypeId: equipTypeId, equipmentKindId: id
      }).then(({ data }) => {

        if (data?.length > 0) {
          let typePlans = []
          data.forEach(typeData => {
            if (!existingIds.has(typeData.id)) {
              typePlans.push({
                ...typeData,
                group: `kind_${typeData?.equipmentTypeId}_system_${id}`,
              });
            }
          });
          setplansData((prev) => [...prev, ...typePlans])
        }

      })

    }
    if (itemType === 'kind') {
      const systemId = rawId.split('_')[3];

      getEquipmentGroups(`protectionObjectId=${facilityId}&equipmentKindId=${systemId}&equipmentTypeId=${id}&calcCount=${true}`).then(res => {
        if (res.data) {
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => String(eq.id) === String(id) ? ({
                ...eq,
                groups: res.data
              }) : eq)
            } : item)
          }));
          getPlanDataForGroup({
            protectionObjectId: facilityId, startDate: startDate, endTime: endDate, regulationId: regulationId == "все" ? "" : regulationId, equipmentTypeId: id, equipmentKindId: systemId, equipmentGroupId: ""
          }).then(({ data }) => {
            if (data?.length > 0) {
              let groupPlans = []
              data?.map((groupData) => {
                groupPlans.push({
                  ...groupData,
                  group: `group_${groupData.equipmentGroupId}_kind_${id}_system_${systemId}`,
                })
              })
              setplansData((prev) => Array.from(new Map([...prev, ...groupPlans]?.map(item => [item.id, item])).values()))
            }

          })
        }
      });
    }
    if (itemType === 'group') {
      const systemId = rawId.split('_')[5];
      const kindId = rawId.split('_')[3];
      getEquipmentsForTree({
        equipmentGroupId: id,
        pageNumber: 0,
      }).then(res => {
        if (res.data?.data) {

          const findIndex = PageNumber.findIndex(obj => obj.id === id)
          let buffArr = PageNumber
          if (findIndex !== -1) {
            buffArr[findIndex].pageNumber = 0;
            setPageNumber(prev => buffArr)
          } else {
            setPageNumber(prev => [...prev, { id: id, pageNumber: 0 }])
          }
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => {
                return String(eq.id) === String(kindId) ? ({
                  ...eq,
                  equipments: {
                    ...eq.equipments,
                    [id]: { equipData: res.data.data, hasMore: res.data.hasMoreData }
                  }
                }) : eq
              })
            } : item)
          }))
          const equipIds = res?.data?.data?.map((item) => item.id)
          getPlanDataForEquipmentPost({
            protectionObjectId: facilityId, startDate: startDate, regulationId: regulationId == "все" ? null : regulationId, endDate: endDate, equipmentIds: equipIds
          }).then(({ data }) => {
            if (data?.length > 0) {
              let equipmentPlans = []
              data?.map((equipmentData) => {
                equipmentPlans.push({
                  ...equipmentData,
                  group: `equipmentId_${equipmentData.equipmentId}_group_${id}_kind_${kindId}_system_${systemId}`,
                })
              })
              setplansData((prev) => Array.from(new Map([...prev, ...equipmentPlans]?.map(item => [item.id, item])).values()))
            }

          })
        }
      });
    }

  };


  const groupsCalendar = () => {
    const flatArray = [];
    // Проходим по каждому элементу и добавляем в плоский массив
    treeItems?.forEach(item => {
      flatArray.push({
        id: `system_${item.id}`,
        content: item.title
      });
      if (expandedItems[facilityId])
        item.equipmentTypes.forEach(equipmentType => {
          if (expandedItems[facilityId].includes(`system_${item.id}`)) {
            flatArray.push({
              id: `kind_${equipmentType.id}_system_${item.id}`,
              content: equipmentType.name,
            });
          }
          if (expandedItems[facilityId].includes(`kind_${equipmentType.id}_system_${item.id}`)) {
            if (equipmentType?.groups) {
              // equipmentType?.groups
              equipmentType.groups.forEach(group => {

                flatArray.push({
                  id: `group_${group.value}_kind_${equipmentType.id}_system_${item.id}`,
                  content: group.name,
                });

                if (expandedItems[facilityId].includes(`group_${group.value}_kind_${equipmentType.id}_system_${item.id}`)) {
                  if (equipmentType?.equipments) {
                    if (equipmentType?.equipments[group.value]) {
                      const equipIds = equipmentType.equipments[group.value].equipData?.map((item) => item.id)
                      equipmentType.equipments[group.value].equipData.forEach(equipment => {
                        flatArray.push({
                          id: `equipmentId_${equipment.id}_group_${group.value}_kind_${equipmentType.id}_system_${item.id}`,
                          content: equipment.name,
                        });
                      })
                    }

                  }
                }
              })
            }
          }
        });

    });
    return flatArray
  }
  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [facilityId]: itemIds,
    }));

  };
  const handleItemExpansionToggle = (event, id, isSelected) => {
    if (!id.includes('equipment')) {
      if (isSelected) {
        setExpandedItems(prevState => ({
          ...prevState,
          [facilityId]: prevState[facilityId] ? [...prevState[facilityId], id] : [id]
        }));
      } else {
        setExpandedItems(prevState => ({
          ...prevState,
          [facilityId]: prevState[facilityId]?.filter((item) => !item.includes(id))
        }));
      }
    }

    if (!expandedNodesHistory.has(id) && isSelected && !id.includes('equipment')) {
      // Если ветка уже раскрыта, просто закрываем её
      expandedNodesHistory.add(id);
      setExpandedNodesHistory(new Set(expandedNodesHistory));
      const itemType = id.includes('kind') ? !id.includes('group') ? 'kind' : 'group' : "system";
      onToggleTree(id.split('_')[1], itemType, id);
    }
  };

  useEffect(() => {
    getFacilitySelectElems({ name: searchObject }).then(
      ({ data, isSuccess }) => {
        if (!!data?.length) {
          if (runOnce) {
            if (localStorage.getItem("currentObjectPlanning")) {
              setValue(formFields.object, localStorage.getItem("currentObjectPlanning"));
            } else {
              setValue(formFields.object, data[0].id)
            }
          }

          setrunOnce(false)
        }
      }
    );
  }, [searchObject])


  useEffect(() => {
    if (facilityId)
      getRugulationsSelect({ protectionObjectId: facilityId, regulationName: searchReguLation, forPlanning: true })
  }, [searchReguLation])


  const refreshcalendar = () => {
    const equipKindId = equipmentTypeId ? JSON.parse(equipmentTypeId)?.id == "все" ? "" : JSON.parse(equipmentTypeId)?.parentId : ""
    getPlanDataForKind({
      protectionObjectId: facilityId, startDate: startDate, endTime: endDate, regulationId: regulationId == "все" ? "" : regulationId, equipmentKindId: equipKindId
    }).then(({ data }) => {

      let kindPlans = []
      if (data?.length > 0) {
        data?.map((kindData) => {
          kindPlans.push({
            ...kindData,
            group: `system_${kindData.equipmentKindId}`,
          })
        })
        setplansData((prev) => [...prev, ...kindPlans])
      }
      if (expandedItems[facilityId]) {
        treeItems.forEach(item => {
          item.equipmentTypes.forEach(equipmentType => {
            if (expandedItems[facilityId].includes(`system_${item.id}`)) {
              getPlanDataForType({
                protectionObjectId: facilityId, startDate: startDate, endTime: endDate, regulationId: regulationId == "все" ? "" : regulationId, equipmentKindId: item.id, equipmentTypeId: equipmentType.id
              }).then(({ data }) => {

                if (data?.length > 0) {
                  let typePlans = []
                  data?.map((typeData) => {
                    typePlans.push({
                      ...typeData,
                      group: `kind_${typeData?.equipmentTypeId}_system_${item.id}`,
                    })
                  })
                  setplansData((prev) => Array.from(new Map([...prev, ...typePlans]?.map(item => [item.id, item])).values()))
                }

              })
            }
            if (expandedItems[facilityId].includes(`kind_${equipmentType.id}_system_${item.id}`)) {

              if (equipmentType?.groups) {
                getPlanDataForGroup({
                  protectionObjectId: facilityId, startDate: startDate, endTime: endDate, regulationId: regulationId == "все" ? "" : regulationId, equipmentKindId: item.id, equipmentTypeId: equipmentType.id, equipmentGroupId: ""
                }).then(({ data }) => {
                  if (data?.length > 0) {
                    let groupPlans = []
                    data?.map((groupData) => {
                      groupPlans.push({
                        ...groupData,
                        group: `group_${groupData.equipmentGroupId}_kind_${equipmentType.id}_system_${item.id}`,
                      })
                    })
                    setplansData((prev) => Array.from(new Map([...prev, ...groupPlans]?.map(item => [item.id, item])).values()))
                  }

                })
                equipmentType.groups.forEach(group => {
                  
                  if (expandedItems[facilityId].includes(`group_${group.value}_kind_${equipmentType.id}_system_${item.id}`)) {

                    if (equipmentType?.equipments) {
                      if (equipmentType?.equipments[group.value]) {
                        const equipIds = equipmentType.equipments[group.value].equipData?.map((item) => item.id)
                        getPlanDataForEquipmentPost({
                          protectionObjectId: facilityId, startDate: startDate, regulationId: regulationId == "все" ? null : regulationId, endDate: endDate, equipmentIds: equipIds
                        }).then(({ data }) => {
                          if (data?.length > 0) {
                            let equipmentPlans = []
                            data?.map((equipmentData) => {
                              equipmentPlans.push({
                                ...equipmentData,
                                group: `equipmentId_${equipmentData.equipmentId}_group_${group.value}_kind_${equipmentType.id}_system_${item.id}`,
                              })
                            })

                            setplansData((prev) => Array.from(new Map([...prev, ...equipmentPlans]?.map(item => [item.id, item])).values()))
                          }

                        })

                      }

                    }
                  }
                })
              }
            }
          });

        });
      }

    })
  }

  useEffect(() => {
    if (facilityId) {
      setplansData([])
      setExpandedNodesHistory(new Set())
      refreshcalendar()
    }
  }, [regulationId, startDate, endDate]);

  useEffect(() => {
    if (facilityId) {

      const equipTypeId = equipmentTypeId ? JSON.parse(equipmentTypeId)?.id == "все" ? "" : JSON.parse(equipmentTypeId)?.id : ""
      console.log("ff", equipmentTypeId)
      setplansData([])
      setExpandedItems((prevState) => ({
        ...prevState,
        [facilityId]: [],
      }));
      localStorage.setItem(
        "currentObjectPlanning",
        facilityId
      );

      setExpandedNodesHistory(new Set())
      getRugulationsSelect({ protectionObjectId: facilityId, equipmentTypeId: equipTypeId, regulationName: searchReguLation, forPlanning: true }).then(
        ({ data, isSuccess }) => {
          if (!!data?.length) {
            setValue(formFields.regulations_equip, "все")
          }
        }
      );
      getEquipmentKinds({ params: '', removeEmptyKinds: true, equipmentTypeId: equipTypeId, useShortTitle: true, protectionObjectId: facilityId, hasEquipmentOnly: true }).then(
        ({ data, isSuccess }) => {

          setTreeItems(data)

          refreshcalendar()

        }
      );
    }

  }, [facilityId, equipmentTypeId]);
  useEffect(() => {
    if (facilityId) {
      setValue(formFields.equipmentTypeId, JSON.stringify({ id: "все", name: "все" }))
      if(newItems?.length>0) setOpenConfirm(true)
    }

  }, [facilityId]);

  const $container = useRef(null);

  useEffect(() => {
    if ($container.current) {
      // Функция для обновления ширины
      const handleResize = () => {
        if ($container.current) {
          setWidth($container.current.offsetWidth);
          setHeight($container.current.offsetHeight);
        }
      };

      // Создаем ResizeObserver
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe($container.current);

      // Инициализируем ширину
      handleResize();

      // Очистка при разборке компонента
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const TreeItemsfetchData = (groupId, systemId, kindId, foundEquipment) => {
    if (loading.find((obj) => obj.id == groupId)) {
      const findObj = loading.map(item => {
        if (item.id === groupId) {
          return { ...item, loading: true };
        } else {
          return item;
        }
      });
      setLoading(prev => findObj)
    }
    setLoading(prev => [...prev, { id: groupId, loading: true }])

    setTimeout(() => {
      getEquipmentsForTree({
        equipmentGroupId: groupId,
        pageNumber: PageNumber.find((item) => item.id == groupId).pageNumber + 1,
      }).then(res => {
        if (res.data?.data) {
          const findObj = PageNumber.map(item => {
            if (item.id === groupId) {
              return { ...item, pageNumber: item.pageNumber + 1 };
            } else {
              return item;
            }
          });
          setPageNumber(prev => findObj)
          setTreeItems(prev => prev.map(item => {
            return (String(item.id) === String(systemId) ? {
              ...item,
              equipmentTypes: item.equipmentTypes.map(eq => {
                return String(eq.id) === String(kindId) ? ({
                  ...eq,
                  equipments: {
                    ...eq.equipments,
                    [groupId]: { hasMore: res.data.hasMoreData, equipData: [...foundEquipment.equipData, ...res.data.data] }
                  }
                }) : eq
              })
            } : item)
          }))
          const equipIds = res.data?.data?.map((item) => item.id)
          getPlanDataForEquipmentPost({
            protectionObjectId: facilityId, startDate: startDate, endDate: endDate, regulationId: regulationId == "все" ? null : regulationId, equipmentIds: equipIds
          }).then(({ data }) => {
            if (data?.length > 0) {
              let plans = []
              data?.map((equipmentData) => {
                plans.push({
                  ...equipmentData,
                  group: `equipmentId_${equipmentData.equipmentId}_group_${groupId}_kind_${kindId}_system_${systemId}`
                })
              })
              setplansData(prev => [...new Set([...prev, ...plans])])
            }

          })
          const findObjLoad = loading.map(item => {
            if (item.id === groupId) {
              return { ...item, loading: false };
            } else {
              return item;
            }
          });
          setLoading(prev => findObjLoad)
        }
      });
    }, 1000);

  }
  const handleMoreDateClick = (groupId, systemId, kindId) => {
    const foundSystem = treeItems.find((item) => item.id == systemId)

    const foundKind = foundSystem != null ? foundSystem.equipmentTypes.find((item) => item.id == kindId) : null

    const foundEquipment = foundKind != null ? foundKind.equipments[groupId] : null
    if (
      (!loading.find((item) => item.id == groupId) ||
        !loading.find((item) => item.id == groupId).loading) && foundEquipment?.hasMore) {
      TreeItemsfetchData(groupId, systemId, kindId, foundEquipment)
    }
  };
  const updateCalendar = () => {
    UpdateEquipmentPlanPeriodRegulations(newItems).then((data) => {
      setnewItems([])
      setEditMode(false)
      setplansData([])
      setExpandedNodesHistory(new Set())
      refreshcalendar()
    })
  }
  const closeConfrim = () => {
    setOpenConfirm(false);
    setnewItems([])
    setEditMode(false)
  }
  function getMonthsBetweenDates() {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const months = [];

    // Перебираем месяцы от start до end
    for (let m = start; m.isBefore(end) || m.isSame(end, 'month'); m = m.add(1, 'month')) {
      months.push(monthsRu[Number(m.format('MM')) - 1]); // Добавляем название месяца
    }

    return months;
  }
  const itemsNav = [
    "plume_and_address",
    "func_tests",
    "documents",
    "planning"
  ]

  return (

    <Box ref={$container} sx={{ width: "100%", height: "calc(100vh - 260px)" }}>
      <ModalConfirmPagePlanning
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleClose={closeConfrim}
        onSubmit={updateCalendar}
      />

      <Header nameForm="planning" />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <PageheaderNav itemsNav={itemsNav} />
        <Box alignContent="center">
          {EditMode ? (
            <Button
              className="btn btn-primary-fill"
              sx={{ height: "52px", alignSelf: "flex-end" }}
              onClick={() => { updateCalendar() }}
            >
              {t("button:save")}
            </Button>
          ) : (
            <Button
              className="btn btn-primary-fill"
              sx={{ height: "52px", alignSelf: "flex-end" }}
              onClick={() => { setEditMode(true) }}
            >
              <Box
                component="img"
                sx={{ marginRight: "16px" }}
                src={editIconWhite}

              />
              {t("button:edit")}
            </Button>
          )
          }
        </Box>
      </Box>
      <Box>


        <Box className={`addEquipment-wrapper-buttons`} sx={{ justifyContent: "flex-start", padding: "24px 32px" }}>

          {config.map(({ id, name, label, type, isCanDisabled, ...rest }) => (
            <FormItem
              key={id}
              name={name}
              control={control}
              label={label}
              type={type}

              searchObject={searchObject}
              setsearchObject={setsearchObject}
              input={(props) => <SelectSimple sx={{ marginRight: "20px" }} {...props} />}
              {...rest}
            />
          ))}
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          {configAfter.map(({ id, name, label, input, type, isCanDisabled, ...rest }) => (
            <FormItem
              key={id}
              name={name}
              control={control}
              label={label}
              type={type}
              searchKindType={searchKindType}
              setsearchKindType={setsearchKindType}
              searchReguLation={searchReguLation}
              setsearchReguLation={setsearchReguLation}
              input={input}
              {...rest}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ overflowX: "scroll", }}>
        <Box sx={{ display: 'flex', width: "100%" }}>
          <Typography sx={{ minWidth: "428px", padding: "24px 32px 0px 32px", fontSize: "20px", }}>Системы</Typography>
          <Box sx={{ alignItems: "center", minWidth: "1000px", width: `${width - 512}px`, }}>
            <Box sx={{ display: "flex", margin: "8px 0px", placeContent: "center", gap: "24px", padding: "10px 0px", borderRadius: "40px", backgroundColor: "#EEF4FD", fontWeight: "500" }}>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {

                  setStartDate(prev => `${Number(prev.split("-")[0]) - 1}-${prev.split("-")[1]}-${prev.split("-")[2]}`)
                  setEndDate(prev => `${Number(prev.split("-")[0]) - 1}-${prev.split("-")[1]}-${prev.split("-")[2]}`)
                }}
                component="img"
                src={arrowLeft}
              />

              <Typography sx={{ fontWeight: "500" }}>
                {startDate.split("-")[0] == endDate.split("-")[0] ? startDate.split("-")[0] : `${startDate.split("-")[0]} - ${endDate.split("-")[0]}`}
              </Typography>
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setStartDate(prev => `${Number(prev.split("-")[0]) + 1}-${prev.split("-")[1]}-${prev.split("-")[2]}`)
                  setEndDate(prev => `${Number(prev.split("-")[0]) + 1}-${prev.split("-")[1]}-${prev.split("-")[2]}`)
                }}
                component="img"
                src={arrowRight}
              />
            </Box>
            <Box sx={{ display: "flex", margin: "8px 0px", padding: "10px 0px", borderRadius: "40px", backgroundColor: "#EEF4FD" }}>
              {getMonthsBetweenDates()?.map((month) => (
                <Box flex={1} sx={{ fontWeight: "500", textAlign: "center" }}><Typography>{month}</Typography></Box>
              ))

              }
            </Box>
          </Box>
        </Box>
        <Box id={"conntainer"} sx={{ display: 'flex', height: `${height - 280}px`, overflowY: selectedItemId?.id == -1 ? 'scroll' : "hidden", minWidth: "1511px", width: `${width}px` }}>
          <Box sx={{ minWidth: "428px", width: "428px", padding: "0px 32px" }}>

            <SimpleTreeView
              expandedItems={expandedItems[facilityId]}
              //onExpandedItemsChange={handleExpandedItemsChange}
              onItemExpansionToggle={handleItemExpansionToggle}
              //onItemSelectionToggle={handleItemExpansionToggle}
              slots={{
                collapseIcon: CollapseIcon,
                expandIcon: ExpandIcon
              }}
            >
              {
                !!treeItems?.length && treeItems.map((system, sysIdx) => (
                  <CustomTreeItem
                    isSystem
                    key={`system_${system.id}`}
                    label={system.title}
                    expandItems={expandedItems[facilityId]}
                    itemId={`system_${system.id}`}
                  >

                    {
                      !!system?.equipmentTypes?.length && system.equipmentTypes.map((el, index) => (
                        <CustomTreeItem
                          isKind
                          key={`kind_${el.id}_system_${system.id}`}
                          label={el.name}
                          expandItems={expandedItems[facilityId]}
                          itemId={`kind_${el.id}_system_${system.id}`}
                        >
                          {
                            el.groups && el.groups.map((g, gIdx) => (
                              <CustomTreeItem
                                isGroup
                                EditMode={EditMode}
                                key={g.value}
                                countPlaced={g.countPlaced}
                                countNonPlaced={g.countNonPlaced}
                                label={g.name}
                                expandItems={expandedItems[facilityId]}
                                itemId={`group_${g.value}_kind_${el.id}_system_${system.id}`}
                              >
                                <Box>
                                  {el.equipments && el.equipments[g.value] && el.equipments[g.value].equipData.map((e, eqIdx) => (
                                    <React.Fragment key={`equipment_${e.id}_eqTypeId_${e.typeId}`}>
                                      <CustomTreeItem EditMode={EditMode} scale={scaleAddEquip} equipmentId={e.id} equipIndex={eqIdx + 1} item={e} isLoading={!loading.find((item) => item.id == g.value)?.loading} moreDatafunc={() => handleMoreDateClick(g.value, system.id, el.id)} ishasMorelastIndex={el.equipments && el.equipments[g.value] && el.equipments[g.value]?.hasMore && eqIdx == el.equipments[g.value].equipData?.length - 1} hasPlacement={e.hasPlacement} equipmentTypeId={e.typeId} isForEdit={e.isForEdit} key={e.id} label={e.name} itemId={`floor_${e.floorInfoId}_equipment_${e.id}_eqTypeId_${e.typeId}_sysIdx_${sysIdx}_kindIdx_${index}_gIdx_${gIdx}_eqIdx_${eqIdx}`} />

                                    </React.Fragment>
                                  )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />}
                                  {loading.find((item) => item.id == g.value)?.loading && (
                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <CircularProgress />
                                    </Box>
                                  )}
                                </Box>
                              </CustomTreeItem>
                            )) || <CircularProgress sx={{ marginTop: 1 }} color="primary" size={24} />
                          }
                        </CustomTreeItem>
                      ))
                    }
                  </CustomTreeItem>
                ))
              }
            </SimpleTreeView>
          </Box>

          <Box sx={{ width: `${width - 519}px`, minWidth: "1000px", border: "0.5px solid #cfd9e8", }}>

            <TimelineThing rows={groupsCalendar()} selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId} newItems={newItems} isEdit={EditMode} objectId={facilityId} setData={setnewItems} history={expandedNodesHistory} expandNames={expandedItems[facilityId]} items={plansData} setitems={setplansData} startDate={startDate} endDate={endDate} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActsPlanning;
