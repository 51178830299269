import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const usersApi = createApi({
  reducerPath: "users/api",
  tagTypes: ["Users"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUsersToken: build.query({
      query: () => ({
        url: `api/users/get-admin-token`,
      }),
    }),
    getAbleToAddUsers: build.query({
      query: (token) => ({
        url: `api/users/check-able-to-add-users`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    checkUsersLogin: build.query({
      query: (data) => ({
        url: `api/users/check-user-login`,
        params: data,
      }),
    }),
    getUsersByPassword: build.mutation({
      query: (data) => ({
        url: `api/users/login-user-by-password`,
        method: "POST",
        responseHandler: "content-type",
        body: data,
      }),
    }),
    sendRecoveryMail: build.mutation({
      query: (data) => ({
        url: `api/users/send-recovery-mail`,
        method: "POST",
        params: data,
      }),
    }),
    setNewPassword: build.mutation({
      query: (data) => ({
        url: `api/users/set-new-password`,
        method: "PUT",
        params: data,
      }),
    }),
    getProfileInfo: build.query({
      query: (token) => ({
        url: `api/users/get-user-profile-info`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: (result, error, arg) => [{ type: "Users", id: arg }],
    }),
    getProfileInfoById: build.query({
      query: ({ token, employeeId }) => ({
        url: `api/users/get-user-profile-by-id?profileId=${employeeId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    getUser: build.query({
      query: ({ token, employeeId }) => ({
        url: `api/users/get-user?Id=${employeeId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    editProfileInfo: build.mutation({
      query: ({ token, data }) => ({
        url: `api/users/edit-user-profile-info`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Users", id: arg.id }],
    }),
    updateProfileInfoPassword: build.mutation({
      query: ({ token, data }) => ({
        url: `api/users/update-user-profile-password`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }),
    }),
    addNewUser: build.mutation({
      query: ({ token, data }) => ({
        url: `api/users/add-new-user`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    editUser: build.mutation({
      query: ({ token, data }) => ({
        url: `api/users/edit-user`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    registrateNewUser: build.mutation({
      query: (data) => ({
        url: `api/users/registrate`,
        method: "POST",
        body: data,
      }),
    }),
    getUserRolesSelect: build.query({
      query: () => ({
        url: `api/users/get-roles-select`,
      }),
    }),
    getRegions: build.query({
      query: ({ name }) => ({
        url: `api/users/get-regions?name=${name}`,
      }),
    }),

    getUserJobTitlesSelect: build.query({
      query: () => ({
        url: `api/users/get-job-titles-select`,
      }),
    }),
    getUsersForOrganization: build.query({
      query: ({ searchValue }) => ({
        url: `api/users/get-users-for-organization?searchValue=${searchValue}`,
      }),
    }),
    getUserGridSetting: build.query({
      query: ({ gridSettingName }) => ({
        url: `api/users/get-user-grid-setting?gridSettingName=${gridSettingName}`,
      }),
    }),
    confirmVerification: build.mutation({
      query: (data) => ({
        url: `api/users/confirm-verification`,
        method: "POST",
        body: data,
      }),
    }),
    setUserGrid: build.mutation({
      query: (data) => ({
        url: `api/users/set-user-grid-setting`,
        method: "POST",
        body: data,
      }),
    }),
    addLogClientError: build.mutation({
      query: (data) => ({
        url: "/api/users/log-client-error",
        method: "POST",
        body: data,
      }),
    }),
    getResponsibleOrganizationsUsers: build.query({
      query: ({ searchValue, filters }) => ({
        url: `api/users/get-responsible-organizations-users?searchValue=${searchValue}${filters}`,
      }),
    }),
    addRegisterToLearning: build.mutation({
      query: (data) => ({
        url: `api/users/register-learning`,
        method: "POST",
        body: data,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          RegisterAuthorizationKey:
            "e89bb915132a004c9f528a94896db0e178767878b08a6c4ad8303dff9db796ac610a4e8e8bbfe67081850df26d4eba3dee578d15a6b2e53ef31097b43b0305df",
        },
      }),
    }),
    addNews: build.mutation({
      query: (data) => ({
        url: `api/users/add-news`,
        method: "POST",
        body: data,
      }),
    }),
    getNewsList: build.query({
      query: ({ pageNumber, type }) => ({
        url: `api/users/get-news-list?pageNumber=${pageNumber}&type=${type}`,
      }),
    }),
    getOperativeInfo: build.query({
      query: ({ type }) => ({
        url: `api/users/get-news-first?type=${type}`,
      }),
    }),
    hasAccesTocabinet: build.query({
      query: () => ({
        url: `api/users/has-access-to-personal-cabinet`,
      }),
    }),
    
    deleteNews: build.mutation({
      query: (id) => ({
        url: `/api/users/delete-news/${id}`,
        method: "DELETE",
      }),
    }),
    editNews: build.mutation({
      query: (data) => ({
        url: `/api/users/edit-news`,
        method: "POST",
        body: data,
      }),
    }),
    editNewsPriority: build.mutation({
      query: (data) => ({
        url: `/api/users/change-news-priority`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useHasAccesTocabinetQuery,
  useAddLogClientErrorMutation,
  useEditNewsMutation,
  useEditNewsPriorityMutation,
  useDeleteNewsMutation,
  useGetNewsListQuery,
  useGetOperativeInfoQuery,
  useAddNewsMutation,
  useAddRegisterToLearningMutation,
  useGetUsersTokenQuery,
  useGetAbleToAddUsersQuery,
  useLazyCheckUsersLoginQuery,
  useGetProfileInfoQuery,
  useLazyGetProfileInfoQuery,
  useConfirmVerificationMutation,
  useGetUsersByPasswordMutation,
  useGetProfileInfoByIdQuery,
  useSendRecoveryMailMutation,
  useGetUserQuery,
  useEditUserMutation,
  useLazyGetRegionsQuery,
  useGetRegionsQuery,
  useLazyGetUserGridSettingQuery,
  useGetUserGridSettingQuery,
  useSetUserGridMutation,
  useSetNewPasswordMutation,
  useEditProfileInfoMutation,
  useRegistrateNewUserMutation,
  useUpdateProfileInfoPasswordMutation,
  useAddNewUserMutation,
  useGetUserRolesSelectQuery,
  useGetUserJobTitlesSelectQuery,
  useGetUsersForOrganizationQuery,
  useLazyGetResponsibleOrganizationsUsersQuery,
  useGetResponsibleOrganizationsUsersQuery,
} = usersApi;
