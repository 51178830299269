import { Button } from "@mui/material";
import TextEditor from "./TextEditor";
import { formConfigBasicInformation } from "./form-config";
import FormItem from "../../../components/FormItem";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";

const AddNewsForm = ({ onSubmit, editorState, setEditorState, isLoading, isEmptyEditoreText }) => {
    const { control, handleSubmit, formState: { errors }, setValue } = useFormContext();
  return (
    <form
      className="add-news-form"
      id="addNews"
      onSubmit={handleSubmit(onSubmit)}
    >
      {formConfigBasicInformation.map(({ name, label, input, type, rules, ...rest }, i) => (
        <FormItem
          key={name}
          control={control}
          errors={errors}
          name={name}
          label={label}
          input={input}
          rules={rules}
          type={type}
          setValue={setValue}
          {...rest}
        />
      ))}

      <TextEditor editorState={editorState} setEditorState={setEditorState} isEmptyEditoreText={isEmptyEditoreText}/>

      <Button
        form="addNews"
        className="btn addNews"
        type="submit"
        disabled={isLoading}
      >
        {t("button:save")}
      </Button>
    </form>
  );
};

export default AddNewsForm;
