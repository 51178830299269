import { InputAdornment, Typography } from "@mui/material";
import InputText from "../../../components/InputText";
export const formFields = {
  amount: "amount",
};
export const amountNumberField = [
  {
    id: 1,
    name: formFields.amount,
    type: "number",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    sx: {
      input: { width: "calc(100% - 60px)", fontSize: "16px", fontWeight: "500", padding: "5px 0 5px 24px", textAlign: "center" },
      "& .input-wrapper": { padding: "0px" },
    },
    input: (props) => <InputText {...props} />,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">₽</Typography>
        </InputAdornment>
      ),
    },
  },
];
