
import React from "react";

const SmallGridIcon = ({ isActive }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="18" height="3" rx="1.5" fill={isActive ? "#1876D2" : '#4D5C6E'} />
      <rect y="5.5" width="18" height="3" rx="1.5" fill={isActive ? "#1876D2" : '#4D5C6E'} />
      <rect y="10.5" width="18" height="3" rx="1.5" fill={isActive ? "#1876D2" : '#4D5C6E'} />
    </svg>

  );
};

export default SmallGridIcon;
