import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";

export const facilitiesApi = createApi({
  reducerPath: "facilities/api",
  tagTypes: ["Facilities"],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().users;
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getFacility: build.query({
      query: (id) => ({
        url: `api/facilities/get/${id}`,
      }),
    }),
    addFacility: build.mutation({
      query: (data) => ({
        url: "/api/facilities/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    updateFacSystemSettings: build.mutation({
      query: (data) => ({
        url: "/api/facilities/update-facility-system-settings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    linkFacilitiesWithConsole: build.mutation({
      query: (data) => ({
        url: "/api/facilities/link-facilities-with-console",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    setFacilityInTest: build.mutation({
      query: ({ protectionObjectId }) => ({
        url: `/api/facilities/set-facility-in-test?protectionObjectId=${protectionObjectId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Facilities"],
    }),
    updateFacilityInTest: build.mutation({
      query: ({ id, isInTest }) => ({
        url: "/api/facilities/update-facility-is-in-test",
        method: "PUT",
        body: { id, isInTest },
      }),
      invalidatesTags: ["Facilities"],
    }),
    editFacility: build.mutation({
      query: (data) => ({
        url: "/api/facilities/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Facilities", id: arg.id }],
    }),
    deleteFacility: build.mutation({
      query: (id) => ({
        url: `api/facilities/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Facilities"],
    }),
    getFacilitySelectElements: build.query({
      query: () => ({
        url: `api/facilities/get-facility-select-elements`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ facilityId, facilityName }) => ({
          value: facilityId,
          name: facilityName,
        })),
    }),
    getFacilitySelectElementsWithSearch: build.query({
      query: ({ name }) => ({
        url: `api/facilities/get-facility-select-elements?name=${name}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ facilityId, facilityName }) => ({
          id: facilityId,
          name: facilityName,
        })),
    }),
    getFacilitySelectElementsWithoutCache: build.query({
      query: () => ({
        url: `api/facilities/get-facility-select-elements`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ facilityId, facilityName }) => ({
          value: facilityId,
          name: facilityName,
        })),
    }),
    getFacIntNumbers: build.query({
      query: ({ protectionObjectId, number }) => ({
        url: `api/facilities/get-facilty-integr-number-alts?protectionObjectId=${protectionObjectId}&number=${number}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, number }) => ({
          id: id,
          name: number,
        })),
    }),
    getFacFireCompSelectElements: build.query({
      query: (params = "") => ({
        url: `api/facilities/get-facfirecomp-select-elements?searchValue=${params}`,
      }),
    }),
    getFacSystemSettings: build.query({
      query: (params = "") => ({
        url: `api/facilities/get-facility-system-settings?facilityId=${params}`,
      }),
    }),
    getFacilitiesMapElementsInfo: build.query({
      query: () => ({
        url: `api/facilities/get-facilities-map-elements-info`,
      }),
      providesTags: ["Facilities"],
    }),
    resetPlanning: build.mutation({
      query: (data) => ({
        url: "/api/facilities/reset-plan-periods",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    getFacilityOnMapInfo: build.query({
      query: (params) => ({
        url: `api/facilities/get-facility-on-map-info?facilityId=${params}`,
      }),
      providesTags: ["Facilities"],
    }),
    getAllFacilities: build.query({
      query: ({ Name = "", Address, phoneNumber, IntegrationNumber, pageNumber }) => ({
        url: `api/facilities/get-facilities-list?pageNumber=${pageNumber}&Name=${Name}&phoneNumber=${phoneNumber}&IntegrationNumber=${IntegrationNumber}${Address}`,
      }),
      providesTags: ["Facilities"],
    }),
    getFacilitiesNotInTest: build.query({
      query: () => ({
        url: `api/facilities/get-facilities-not-in-test`,
      }),
      providesTags: ["Facilities"],
    }),
    getFacilitiesForTest: build.query({
      query: ({ pageNumber, protectionObjectId }) => ({
        url: `api/facilities/get-facilities-with-in-test-info?pageNumber=${pageNumber}&protectionObjectId=${protectionObjectId}`,
      }),
      providesTags: ["Facilities"],
    }),
    getFloorOptionsSelectByFacility: build.query({
      query: (facilityId) => ({
        url: `api/facilities/get-floor-info-select-by-facility?facilityId=${facilityId}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, floorNumber, comment }) => ({
          value: id,
          name: floorNumber + (comment && comment !== "" ? ", " + comment : ""),
        })),
    }),
    getFacilitiesSelectWithAddress: build.query({
      query: (params = "") => ({
        url: `api/facilities/get-facilities-with-addresses`,
      }),
    }),
    getFacilitiesSelectWithAddressForAct: build.query({
      query: ({ actType, facilityName = "" }) => ({
        url: `api/facilities/get-facilities-with-addresses-for-act?actType=${actType}&facilityName=${facilityName}`,
      }),
    }),

    getEquipmentKindsFacility: build.query({
      query: (facilityId) => ({
        url: `api/facilities/get-equipment-kinds-facility-planning?facilityId=${facilityId}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id, fullTitle }) => ({
          value: id,
          name: fullTitle,
        })),
    }),

    moveEquipBetweenPeriods: build.mutation({
      query: (data) => ({
        url: "/api/facilities/move-equipments-between-plan-periods",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    addEquipForPeriods: build.mutation({
      query: (data) => ({
        url: "/api/facilities/add-equipments-to-plan-period",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    hasfacilitiesforplaning: build.query({
      query: () => ({
        url: `api/facilities/has-facilities-for-planning`,
      }),
    }),
    GetFacilitiesForPlaning: build.query({
      query: ({ searchValue }) => ({
        url: `api/facilities/get-facility-select-elements?name=${searchValue}&criterias=3`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ facilityId, facilityName }) => ({
          id: facilityId,
          name: facilityName,
        })),
    }),
    deleteEquipForPeriods: build.mutation({
      query: (data) => ({
        url: "/api/facilities/delete-equipments-from-plan-period",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    getPlanPeriods: build.query({
      query: ({ protectionObjectId, equipmentKindId }) => ({
        url: `api/facilities/get-plan-periods?protectionObjectId=${protectionObjectId}&equipmentKindId=${equipmentKindId}`,
      }),
    }),
    getFacilitiesListOption: build.query({
      query: ({facilityName, pageNumber}) => ({
        url: `api/facilities/get-facilities-list-option?pageNumber=${pageNumber}&facilityName=${facilityName}`,
      }),
    }),
    getFacilitiesOptionsForSelect: build.query({
      query: ({ searchValue }) => ({
        url: `api/facilities/get-facilities-option-for-selection?faciltyName=${searchValue}`,
      }),
      transformResponse: (baseQueryReturnValue) =>
        baseQueryReturnValue.map(({ id,name,address }) => ({
          value: id,
          name: name,
          address:address,
        })),
    }),


    getFacilitiesBillings: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        
        return {
          url: `api/facilities/get-facilities-billings?${stringParams}`,
        };
      },
    }),
    
    getFacilitiesGetTariffs: build.query({
      query: (facilityId) => ({
        url: `api/facilities/get-tariffs?facilityId=${facilityId}`,
      }),
      // transformResponse: (baseQueryReturnValue) =>
      //   baseQueryReturnValue.map(({ tariff, fullTitle }) => ({
      //     value: id,
      //     name: fullTitle,
      //   })),
    }),
    changeTariff: build.mutation({
      query: (data) => ({
        url: "/api/facilities/change-tariff",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    deleteFacilityByRequest: build.mutation({
      query: (data) => ({
        url: "/api/facilities/delete-facility-by-request",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }),
    addOption: build.mutation({
      query: (data) => ({
        url: "/api/facilities/add-option",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Facilities"],
    }), 
    getBillingDocuments: build.query({
      query: (params) => {
        const stringParams = Object.entries(params).reduce(
          (acc, [key, value]) => `${acc ? acc + "&" : ""}${key}=${value}`,
          ""
        );
        
        return {
          url: `api/facilities/get-billing-documents?${stringParams}`,
        };
      },
    }),
  }),
});

export const {
  useLazyGetBillingDocumentsQuery,
  useAddOptionMutation,
  useDeleteFacilityByRequestMutation,
  useLazyGetFacilitiesListOptionQuery,
  useChangeTariffMutation,
  useGetFacilitiesGetTariffsQuery,
  useGetFacilityQuery,
  useAddFacilityMutation,
  useGetFacilitiesOptionsForSelectQuery,
  useEditFacilityMutation,
  useResetPlanningMutation,
  useSetFacilityInTestMutation,
  useUpdateFacilityInTestMutation,
  useGetFacilitiesNotInTestQuery,
  useLazyGetFacilitiesForTestQuery,
  useLazyGetFacilitiesBillingsQuery,
  useDeleteFacilityMutation,
  useHasfacilitiesforplaningQuery,
  useGetFacilitiesForPlaningQuery,
  useLazyGetFacIntNumbersQuery,
  useLazyGetFacilitiesForPlaningQuery,
  useGetFacSystemSettingsQuery,
  useUpdateFacSystemSettingsMutation,
  useAddEquipForPeriodsMutation,
  useDeleteEquipForPeriodsMutation,
  useMoveEquipBetweenPeriodsMutation,
  useLinkFacilitiesWithConsoleMutation,
  useGetFacilitySelectElementsQuery,
  useGetFacilitySelectElementsWithSearchQuery,
  useLazyGetFacilitySelectElementsWithSearchQuery,
  useGetFacilitySelectElementsWithoutCacheQuery,
  useGetEquipmentKindsFacilityQuery,
  useLazyGetEquipmentKindsFacilityQuery,
  useLazyGetPlanPeriodsQuery,
  useGetFacFireCompSelectElementsQuery,
  useLazyGetFacilitiesMapElementsInfoQuery,
  useGetFacilitiesMapElementsInfoQuery,
  useGetFacilityOnMapInfoQuery,
  useLazyGetAllFacilitiesQuery,
  useLazyGetFloorOptionsSelectByFacilityQuery,
  useGetFacilitiesSelectWithAddressQuery,
  useGetFacilitiesSelectWithAddressForActQuery,
} = facilitiesApi;
