
import InputText from "../../../components/InputText";
import InputTextarea from "../../../components/InputTextarea";
import { InputAdornment, Typography } from "@mui/material"

const formFields = {
  balance: "balance",
  billingPeriod: "billingPeriod",
  comment: "comment",
};

export const editbalanceFormConfig = [
  {
    name: formFields.balance,
    type: "number",
    label: "label:sum_balance",
    placeholder: "placeholder:sum_balance",
    rules: {
      required: true,
      integer: (v) => !!Number(v) && !/\s/g.test(v) && !/\./g.test(v),
    },
    input: (props) => <InputText {...props} />,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Typography className="adornment">₽</Typography>
        </InputAdornment>
      ),
    },
  },
  {
    name: formFields.comment,
    label: "label:comment",
    placeholder: "placeholder:comment",
    type: "text",
    rules: { required: false },
    input: (props) => <InputTextarea {...props} />,
  },

];
