import React, { useState, useEffect,useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import CustomTreeItem from "./CustomTreeItem";
import expandArrow from "../../../../image/icons/expandTreeArrow.png";
import collapseArrow from "../../../../image/icons/collapseTreeArrow.png";
import Plyr from "plyr-react";
import { lessonTree } from './data'
import "plyr-react/plyr.css";
import "../style.css";

const CollapseIcon = () => (
    <img style={{ height: "20px" }} src={collapseArrow} alt="collapseIcon" />
);
const ExpandIcon = () => (
    <img style={{ height: "20px" }} src={expandArrow} alt="collapseIcon" />
);
const VideoPlayer = React.memo(({ videoLink,isAutoplay }) => {
    // Мемоизация источника видео
    const source = useMemo(() => ({
      type: 'video',
      autoplay:isAutoplay,
      sources: [
        {
          src: videoLink,
          type: 'video/mp4',
        },
      ],
    }), [videoLink]); // Зависимость от videoId
  
    return <Plyr source={source} />;
  });
const VideosTree = () => {
    const [selectedVideo, setselectedVideo] = useState("");
    const [selectedDesc, setselectedDesc] = useState("");
    const [selectedName, setselectedName] = useState("");
    const [isAutoplay, setisAutoplay] = useState(false);
    
    const [expandedItems, setExpandedItems] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleItemExpansionToggle = (event, id, isSelected) => {

        if (isSelected) {
            setExpandedItems(prevState => (
                prevState ? [...prevState, id] : [id]
            ));
        } else {
            setExpandedItems(prevState => (
                prevState?.filter((item) => item != id)
            ));
        }
    };
    useEffect(() => {
        if (localStorage.getItem("currentLesson")) {
            const savedLesson = JSON.parse(localStorage.getItem("currentLesson"))
            setselectedVideo(savedLesson?.link)
            setselectedDesc(savedLesson?.description)
            setselectedName(savedLesson?.name)
        } else {
            setselectedVideo("https://storage.yandexcloud.net/prod-bucket-dozor-1/lession_videos/lesson_1.mp4")
            setselectedDesc("Тут описание к первому уроку")
            setselectedName("Урок 1. Профиль и уведомления")
        }
    }, [])
    useEffect(() => {
       
    }, [selectedVideo])
    const selectLesson = (id, name, link, description, isLesson) => {
        if (isLesson) {
            localStorage.setItem("currentLesson", JSON.stringify({ link: link, description: description, name: name }))
            setselectedVideo(link)
            setselectedDesc(description)
            setselectedName(name)
            setisAutoplay(true)
        }
    };
    const renderLessonsTree = (nodes, level = 1) => {
        return (
            <CustomTreeItem expandedItems={expandedItems} key={nodes.id} itemId={nodes.id} label={nodes.name} selectLesson={() => selectLesson(nodes.id, nodes.name, nodes.link, nodes.description, !!nodes.children?.length == 0)} paddingItems={`${level * 24}px`} >
                {!!nodes.children?.length > 0
                    ? nodes.children.map((node) => renderLessonsTree(node, level + 1))
                    : null}
            </CustomTreeItem>
        );
    };
    return (
        <Box className="lessons-bg">
            <Typography mb="24px" className="header-text">Видео-уроки</Typography>
            <Typography mb="16px" className="header-text">{selectedName}</Typography>
            <Box display="flex" height="650px">

                <VideoPlayer isAutoplay={isAutoplay} videoLink={selectedVideo} />
                <Box className="tree-box">
                    <Typography mb="24px" className={"tree-header"}>Список всех видео-уроков</Typography>
                    <SimpleTreeView
                        expandedItems={expandedItems}
                        onItemExpansionToggle={handleItemExpansionToggle}
                        // onItemSelectionToggle={handleItemExpansionToggle}
                        slots={{
                            collapseIcon: CollapseIcon,
                            expandIcon: ExpandIcon,
                        }}
                    >
                        {
                            lessonTree.map((lesson) => renderLessonsTree(lesson))
                        }
                    </SimpleTreeView>
                </Box>

            </Box>
            {/* <Typography mt="16px" className="description-text">{selectedDesc}</Typography> */}
        </Box>

    );
};

export default VideosTree;
