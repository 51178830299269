const SuccessRequestIcon = () => {
  return (
    <svg width="77" height="68" viewBox="0 0 77 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31 20V40.1695L42 51" stroke="#5BCF48" stroke-width="4" stroke-linecap="round" />
      <path
        d="M47.6851 18.6101L53.4577 24.8263C53.8515 25.2503 54.522 25.2526 54.9186 24.8311L70.1855 8.61017"
        stroke="#5BCF48"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M42.5 8.5C42.5 8.5 38.2528 6.00389 31.9995 6.00001C15.4312 5.98971 2 19.4315 2 36C2 52.5685 15.4312 66 31.9995 66C48.5678 66 62.1316 52.568 61.999 36"
        stroke="#5BCF48"
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SuccessRequestIcon;
