import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import "../styles/style.css";

import { PATHS } from "../../../../constants";
import {
  convertToValuesArray,
  getPositiveKeys,
  getRequestDataFilesForAdd,
  getRequestNewData,
  updateValuesWithFilter,
} from "../helpers";
import { useAction } from "../../../../hooks/actions";

import { WarningValidationForm } from "../../../components/Notifications";

import ButtonsField from "../ButtonsField";

import {
  useDeleteFacilityMutation,
  useEditFacilityMutation,
  useGetFacilityQuery,
} from "../../../../middleware/facilitiesApi";
import { useGetOrganizationsSelectQuery } from "../../../../middleware/apiOrganizations";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";

import { BasicTabs } from "../BasicTabs";
import { useGetEquipmentSystemsQuery } from "../../../../middleware/equipmentsApi";
import { INIT_VALUE_TAB } from "../constants";

const EditObjectPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const readonly = getParams.has("readOnly") ? true : false;
  console.log("readonly", readonly);
  const {
    changeTitlePage,
    setIsErrorRequest,
    setIsErrorSubmit,
    setIsSuccessSubmit,
  } = useAction();
  const {
    data: object,
    isLoading: isLoadingObject,
    error: errorGetObject,
    refetch: refetchFacility,
  } = useGetFacilityQuery(id, { skip: !id });
  const [editFacility, { error: isErrorEdit, isSuccess: isSuccessEdit }] =
    useEditFacilityMutation();
  const [manipulateFiles, { isError: isErrorFile, isSuccess: isSuccessFile }] =
    useManipulateFilesMutation();
  const [deleteFacility] = useDeleteFacilityMutation();
  const { data: EquipmentSystems = [] } = useGetEquipmentSystemsQuery("");
  const [value, setValue] = useState(INIT_VALUE_TAB);
  const handleStep = (step) => {
    setValue(step);
  };

  // const isError = isErrorEdit || isErrorFile;
  const isSuccess = isSuccessEdit && isSuccessFile;

  const methods = useForm({
    mode: "onChange",
  });

  const [isValid, setIsValid] = useState(methods.formState.isValid);
  const isWarningValidation =
    !!Object.keys(methods.formState.errors).length ||
    (!isValid && value !== INIT_VALUE_TAB);
  useEffect(() => {
    value === INIT_VALUE_TAB && setIsValid(methods.formState.isValid);
  }, [value, methods.formState.isValid]);

  const onSubmit = async (data) => {
    const { entities, files } = getRequestNewData(data);

    const values = convertToValuesArray(EquipmentSystems);

    const filterFacilitySystems = getPositiveKeys(entities?.facilitySystems);

    const updatedValues = updateValuesWithFilter(
      values,
      filterFacilitySystems
    ).map((updatedValue) => ({
      ...updatedValue,
      id: entities?.facilitySystemsAvailabilites.find(
        ({ equipmentKindId }) =>
          equipmentKindId === updatedValue.equipmentKindId
      )?.id,
    }));

    const newData = {
      ...entities,
      facilitySystemsAvailabilites: updatedValues,
      fireCompartments: entities?.buildingCharacteristics?.fireCompartments,
    };

    editFacility(newData).then((result) => {
      console.log("result", result);
      if (!("error" in result)) {
        const id = result?.data?.buildingPassport.id;
        const fireCompartments = result?.data?.fireCompartments;
        const requestDataFiles = getRequestDataFilesForAdd(
          id,
          fireCompartments,
          files
        );

        manipulateFiles(requestDataFiles).then((response) => {
          if ("error" in response) {
            const errorsData = {
              data: { Message: response.error.data?.errors[0]?.error },
              status: 461,
            };
            setIsErrorSubmit(errorsData);
          } else {
            refetchFacility();
            navigate(PATHS.LIST_OBJECTS);
          }
        });
      }
    });
  };

  const handleCancel = () => navigate(-1);

  useEffect(() => {
    object && changeTitlePage(object?.buildingPassport?.facilityName || "");
  }, [object, changeTitlePage]);

  useEffect(() => () => changeTitlePage(""), [changeTitlePage]);

  useEffect(() => {
    if (object) {
      const { fieldsFiles } = object || {};
      const files =
        fieldsFiles &&
        fieldsFiles.reduce((acc, item) => {
          let fileName = item.fileName;
          let fileLink = item.fileLink;

          if (fileName === "default.png") {
            fileName = "";
            fileLink = "/unnamed.png";
          }

          return {
            ...acc,
            [item.field]: {
              filesPrev: acc[item.field]?.filesPrev
                ? [
                    ...acc[item.field].filesPrev,
                    { ...item, fileName, fileLink },
                  ]
                : [{ ...item, fileName, fileLink }],
              filesToAdd: [],
              filesToDelete: [],
            },
          };
        }, {});

      const getFloorSchemaFile = ({
        floorSchemeFileName,
        floorSchemeFileId,
      }) => {
        let fileName = floorSchemeFileName;
        let fileLink = floorSchemeFileId;

        if (fileName === "default.png") {
          fileName = "";
          fileLink = "/unnamed.png";
        }

        return {
          filesPrev: [{ fileName, fileLink }],
          filesToAdd: [],
          filesToDelete: [],
        };
      };

      const updatedFireCompartments = object?.fireCompartments.map((el) => ({
        ...el,
        floors: el?.floors.map((e) => ({
          ...e,
          floorSchema: getFloorSchemaFile(e),
        })),
      }));

      const updateData = {
        ...object,
        buildingPassport: {
          ...object?.buildingPassport,
          organizations: object?.organizations,
        },
        buildingCharacteristics: {
          ...object?.buildingCharacteristics,
          fireCompartments: updatedFireCompartments,
        },
        facilitySystems: {
          ...object?.facilitySystems,
          //detectorsType: object?.facilitySystems?.detectorsType?.split(",") || [],
        },
        files,
      };

      object?.facilitySystemsAvailabilites.forEach((system) => {
        updateData.facilitySystems[system.name] = system.isAvailable;
      });

      const asptSystems = object?.facilitySystemsAvailabilites.filter(
        (system) =>
          [4, 5, 6, 7].includes(system.equipmentKindId) &&
          system.isAvailable === true
      );

      if (asptSystems.length > 0) {
        const asptObject = asptSystems.reduce((accumulator, system) => {
          accumulator[system.name] = system.isAvailable;
          return accumulator;
        }, {});
        updateData.facilitySystems["АСПТ"] = asptObject;
      }
      console.log("updateData",updateData)
      methods.reset(updateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, methods.reset]);

  useEffect(() => {
    setIsErrorRequest(errorGetObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorGetObject]);

  useEffect(() => {
    setIsErrorSubmit(isErrorEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorEdit]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useGetOrganizationsSelectQuery();

  useEffect(() => {
    refetchFacility();
  }, [refetchFacility]);

  return (
    <Box className="object">
      <Box className="object-header">
        <Typography className="title-page">
          {object?.buildingPassport?.facilityName || ""}
        </Typography>
        {!!object && (
          <ButtonsField
            handleCancel={handleCancel}
            handleSubmit={methods.handleSubmit(onSubmit)}
            isSubmitDisabled={!isValid && value !== INIT_VALUE_TAB}
            readonly={readonly}
            value={value}
            handleStep={handleStep}
          />
        )}
      </Box>
      {!isLoadingObject && !!object ? (
        <FormProvider {...methods}>
          <form
            id="myform"
            className="object-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <BasicTabs
              isEdit={true}
              equipmentSystems={EquipmentSystems}
              handleSubmit={methods.handleSubmit(onSubmit)}
              value={value}
              readonly={readonly}
              setValue={setValue}
            />
          </form>
        </FormProvider>
      ) : (
        <Typography className="object-content-empty_text">
          {t("text_empty_page:edit_object")}
        </Typography>
      )}

      {isWarningValidation && (
        <WarningValidationForm isWarning={isWarningValidation} />
      )}
    </Box>
  );
};

export default EditObjectPage;
