import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import FormItem from "../../../components/FormItem";
import { config } from "./formConfig";
import {
  useAddControllerMutation,
  useLazyCheckControlQuery,
} from "../../../../middleware/controllersApi";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants";
import { useAction } from "../../../../hooks/actions";
import { useEffect } from "react";
import { WarningValidationForm } from "../../../components/Notifications";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DownloadIcon from "@mui/icons-material/Download";

const Header = ({ handleCancel, isSubmitDisabled }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { setIsErrorSubmit, setIsSuccessSubmit, setIsErrorRequest } =
    useAction();
  const [isConnected, setIsConnected] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [uuid, setUuid] = useState(null);

  const testConnection = () => {
    checkControl({ uuid }).then((response) => setIsConnected(response.data));
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const [addController, { isError: isErrorAdd,isLoading:isLoadingAdd, isSuccess: isSuccessAdd }] =
    useAddControllerMutation();
  const [checkControl, { error: errorCheckControl }] =
    useLazyCheckControlQuery();

  const onSubmit = async (data) => {
    data.type = data.type == "Contact ID" ? 0 : 1
    addController(data).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setUuid(result?.data?.id);
        return;
      }
    });
  };

  useEffect(() => {
    setIsErrorSubmit(isErrorAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAdd]);

  useEffect(() => {
    setIsSuccessSubmit(isSuccessAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  useEffect(() => {
    setIsErrorRequest(errorCheckControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCheckControl]);

  const isWarningValidation = !!Object.keys(errors).length;

  return (
    <>
      <Box className="template_page-header">
          <Typography className="title-page">
            {t("title_page:add_controller")}
          </Typography>
          <Box className="template_page-header-buttons">
          {uuid && (
              <Button
                className="btn btn-primary-fill"
                onClick={testConnection}
              >
                {isConnected
                  ? t("button:check_connection")
                  : t("button:try_once_more")}
              </Button>
            )}
            {uuid && (
              <Button
                className="btn btn-primary-fill"
                onClick={() =>
                  navigate(PATHS.GET_CONTROLLER_MESSAGE_TEMPLATE(uuid))
                }
              >
                {t("button:go_message_code")}
              </Button>
            )}
            <Button className="btn btn-outlined-grey" onClick={handleCancel}>
              {t("button:comeback")}
            </Button>
            <Button
              form="add-controller"
              className="btn btn-primary-fill"
              type="submit"
              disabled={isLoadingAdd || isSuccessAdd}
            >
              {t("button:save")}
            </Button>
          </Box>
        </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "center",
          }}
        >
          <Typography className="form-section-label">
            {t("label:link_text")}
          </Typography>
          <Link
            target="_blank"
            to="https://storage.yandexcloud.net/prod-bucket-dozor-1/uploaded_files/ControlConsoleIntegration.zip"
          >
            <Button
              sx={{ p: 0, marginLeft: "20px" }}
              variant="text"
              endIcon={<DownloadIcon />}
            >
              Control Console
            </Button>
          </Link>
        </Box>

        <form
          id="add-controller"
          className="modal select_columns-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {config.map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              control={control}
              name={name}
              errors={errors}
              label={label}
              input={input}
              {...rest}
            />
          ))}
        </form>
        <Typography className="form-section-label">
          {t("label:info_text")}
        </Typography>
        <Typography
          style={{ color: "red", marginTop: "20px" }}
          className="form-section-label"
        >
          {uuid}
        </Typography>
        <div>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            
          </Box>
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={isConnected ? "success" : "error"}
              sx={{ width: "100%", marginTop: "100px" }}
            >
              {isConnected
                ? t("notification:success_connection")
                : t("notification:error_connection")}
            </Alert>
          </Snackbar>
        </div>

        {isWarningValidation && (
          <WarningValidationForm isWarning={isWarningValidation} />
        )}
      </Box>
    </>
  );
};

export default Header;
