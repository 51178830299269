const EquipmentCountIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 10.1429C15 14.7142 11.5 17 8.00001 17C4.5 17 1 14.7142 1 10.1429C1 6.71428 2.75001 5 3.91667 4.42857C3.91667 6.14285 4.50001 7.28571 5.08334 7.85713C8.58334 6.71428 9.16668 3.28572 9.16668 1C11.5 2.71428 15 5.57142 15 10.1429Z"
        stroke="#4D5C6E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EquipmentCountIcon;
