import React from "react";
import { Box, Button, Dialog } from "@mui/material";
import { ArrowInfoCenter } from "../../../components/icons/ArrowInfoCenter";
import CloseIcon  from "../../../components/icons/CloseIcon";
import priorityNewIcon from "./../../../../image/priorityNews.png";
import "./styles.css";

const NewsModal = ({
  isVisible,
  handleClose,
  newsDataForModal,
  showOtherNews,
}) => {
  const { date, title, content, city, priority, id, photoFileId } =
    newsDataForModal;
  const createMarkup = () => {
    return { __html: content };
  };

  const renderDescription = () => {
    return <Box dangerouslySetInnerHTML={createMarkup()} />;
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="news-modal">
        <Box className="news-modal-header">
          <Box>
            <Box className="news-modal-address">
              {new Date(date).toLocaleDateString("ru-RU")}, {city}{" "}
              {priority === 1 && (
                <Box className="news-panel-important-news">
                  <img src={priorityNewIcon} alt="Важная новость" />
                </Box>
              )}
            </Box>
            <Box className="news-modal-title">{title}</Box>
          </Box>

          <Box onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        {photoFileId && (
          <Box className="news-modal-image">
            <img src={photoFileId} alt="Изображение новости" />
          </Box>
        )}

        {renderDescription()}

        <Box className="news-modal-btn-container">
          <Button
            onClick={() => showOtherNews("prev", id)}
            className="btn btn-outlined-primary magazine_events-select_container-btn right-margin"
          >
            <ArrowInfoCenter rotate={180} />
            Назад
          </Button>
          <Button
            onClick={() => showOtherNews("next", id)}
            className="btn btn-primary-fill magazine_events-select_container-btn left-margin"
          >
            Дальше
            <ArrowInfoCenter />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default NewsModal;
