import { Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { t } from "i18next";
import TextEditor from "./TextEditor";
import { formConfigOperativeInformation } from "./form-config";
import FormItem from "../../../components/FormItem";

const AddOperativeNewsForm = ({
  onSubmit,
  editorState,
  setEditorState,
  isLoading,
  isEmptyEditoreText,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  return (
    <form
      className="add-news-form"
      id="addNews"
      onSubmit={handleSubmit(onSubmit)}
    >
      {formConfigOperativeInformation.map(
        ({ name, label, input, ...rest }, i) => (
          <FormItem
            key={name}
            control={control}
            errors={errors}
            name={name}
            label={label}
            input={input}
            {...rest}
          />
        )
      )}

      <TextEditor
        editorState={editorState}
        setEditorState={setEditorState}
        isEmptyEditoreText={isEmptyEditoreText}
      />

      <Button
        form="addNews"
        className="btn addNews"
        type="submit"
        disabled={isLoading}
      >
        {t("button:save")}
      </Button>
    </form>
  );
};

export default AddOperativeNewsForm;
