import { selectFacilitiesSelect } from "../ResponsibleOrganizationsList/helpers";
import SelectSimple from "../../components/SelectSimple";
import { useGetFacilitiesNotInTestQuery } from "../../../middleware/facilitiesApi";
import { ListOfLinks } from "./Components";

export const formFields = {
  protectionObjectId: "protectionObjectId",
  searchParams: "searchParams",
  facilitySearch: "facilitySearch",
};

export const DEFAULT_PARAMS = {
  facilitySearch: "",
};

export const fields = {
  functionality_check: "functionality_check",
  documents: "documents",
};

export const OrganizationsSelect = (props) => {
  const { data: organizationsSelect = [] } = useGetFacilitiesNotInTestQuery();

  return (
    <SelectSimple
      {...props}
      options={selectFacilitiesSelect(organizationsSelect)}
      sx={{
        ".MuiTypography-root": { maxWidth: "180px", overflowX: "hidden" },
      }}
    />
  );
};

export const modalConfig = [
  {
    id: 1,
    name: formFields.protectionObjectId,
    label: "title_page:objects",
    type: "select-text",
    flexBasis: "100%",
    rules: {
      required: true,
    },
    input: (props) => <OrganizationsSelect {...props} />,
  },
];

export const sections = [
  {
    id: 0,
    title: fields.functionality_check,
    Component: ListOfLinks,
  },
 /* {
    id: 1,
    title: fields.documents,
    Component: TableOfDocuments,
  },*/
]