import { Box, Typography } from "@mui/material";
import DefaultUserAvatar from "../../../../components/icons/Cabinet/DefaultUserAvatar";
import "./styles.css";

const EmployeeCard = ({ user }) => {
  const {fullName, jobTitle, roleName, photoLink } = user

  return (
    <Box className="employee-card">
      {photoLink ? (
        <Box className="employee-card-image">
          <img src={photoLink} alt="Аватар" />
        </Box>
      ) : (
        <Box className="employee-card-default-avatar">
          <DefaultUserAvatar />
        </Box>
      )}
      <Box className="employee-card-info">
        <Typography>{fullName}</Typography>
        <Typography>{jobTitle}; {roleName}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeCard;
