import { useEffect, useState } from "react";
import { Box, Tab, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAction } from "../../../../hooks/actions";
import { useAddNewsMutation } from "../../../../middleware/usersApi";
import { useManipulateFilesMutation } from "../../../../middleware/apiUploadFile";
import { getRequestDataFilesForAdd } from "./helpers";
import AddOperativeNewsForm from "./AddOperativeNewsForm";
import AddNewsForm from "./AddNewsForm";
import { formFields } from "./form-config";
import "./styles.css";

const AddInformationBlock = ({ refetchNewsList }) => {
  const [editorState, setEditorState] = useState("");
  const [isEmptyEditoreText, setIsEmptyEditorText] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [addNews, { isLoading }] = useAddNewsMutation();
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();
  const [
    manipulateFiles,
    // { error: errorFile, isSuccess: isSuccessFile, isLoading: loadingFiles },
  ] = useManipulateFilesMutation();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      [formFields.priority]: 2,
    },
  });

  useEffect(() => {
    const defaultTypeValue = tabValue === "1" ? 0 : 1;
    methods.setValue(formFields.type, defaultTypeValue);
  }, [tabValue]);

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
    methods.reset();
    setEditorState("");
  };

  const prepareData = (formData) => {
    return {
      ...formData,
      content: editorState,
      type: formData.type || 0,
      city: formData.city || "",
      title: formData.title || "",
      priority: formData.priority || 2,
    };
  };

  function onSubmit(data) {
    if (!editorState) {
      setIsEmptyEditorText(true);
      return;
    }
    const preparedData = prepareData(data);
    addNews(preparedData).then((response) => {
      if ("error" in response) {
        setIsErrorSubmit(response.error);
        return;
      }

      if (data.image) {
        const requestDataFiles = getRequestDataFilesForAdd(
          response.data.id,
          "News",
          data.image
        );
        manipulateFiles(requestDataFiles).then((response) => {
          if ("error" in response) {
            setIsErrorSubmit(response.error);
          }
          setIsSuccessSubmit(true);
          setEditorState("");
          methods.reset();
          refetchNewsList();
          return;
        });
      }
      setIsSuccessSubmit(true);
      setEditorState("");
      methods.reset();
      refetchNewsList();
    });
  }

  return (
    <Box className="add-news">
      <Typography className="add-news-title">Добавление информации</Typography>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="add-information">
              <Tab label="Новости" value="1" />
              <Tab label="Оперативная информация МЧС" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <FormProvider {...methods}>
              <AddNewsForm
                onSubmit={onSubmit}
                editorState={editorState}
                setEditorState={setEditorState}
                isLoading={isLoading}
                isEmptyEditoreText={isEmptyEditoreText}
              />
            </FormProvider>
          </TabPanel>
          <TabPanel value="2">
            <FormProvider {...methods}>
              <AddOperativeNewsForm
                onSubmit={onSubmit}
                editorState={editorState}
                setEditorState={setEditorState}
                isLoading={isLoading}
                isEmptyEditoreText={isEmptyEditoreText}
              />
            </FormProvider>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default AddInformationBlock;
