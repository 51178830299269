import React from "react";
import { t } from "i18next";
import { SimpleTextCell, SimpleHeader, PriorityCell, WorkCompletedDownload } from "./Components";
import FormItem from "../../../components/FormItem";
import { SearchInbuilt } from "../../../components/SearchInbuilt";
import DateRangePickerNew from "../../../components/DateRangePickerNew/DateRangePickerNew";
import { formFields } from "./form-config";
import SelectSimpleInbuilt from "../../../components/SelectSimpleInbuilt";

export const priorityOptions = [
  { value: true, name: "Оплачено" },
  { value: false, name: "Не оплачено" },
];

export const getColumns = ({
  control,
  navigate,
  columnsFromStorage,
  searchObject,
  setsearchObject,
  daterange,
  setdaterange,
  setworkCompletedAct,
  workCompletedAct
}) =>
  [
    {
      id: formFields.orderNumber,
      numeric: false,
      enableSorting: false,
      size: "60px",
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.index],
      RowCell: (props) => <SimpleTextCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.orderNumber}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:orderNumber")}
              className="select_in_built small-light"
              searchValue={searchObject}
              changeSearchValue={setsearchObject}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.isPaid,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: true, // columnsFromStorage[formFields.priority],
      RowCell: (props) => <PriorityCell {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.isPaid}`}
          label={t("tableHeader:paymentBilling")}
          options={priorityOptions}
          sx={{
            padding: "13px 5px 13px 16px",
            width: "100%",
          }}
          input={(props) => <SelectSimpleInbuilt {...props} className="select_in_built small-light" />}
        />
      ),
    },
    {
      id: formFields.paymentDate,
      numeric: false,
      enableSorting: false,
      label: t("label:objects_available_employee"),
      size: "180px",
      clickable: false,
      isVisible: true, // columnsFromStorage[formFields.debitingDate],
      RowCell: (props) => <SimpleTextCell type="date" {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.paymentDate}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
            "& .date-picker-input": {
              padding: "0"
            }
          }}
          input={(props) => (
            <DateRangePickerNew
              placeholder={t("tableHeader:pay_date")}
              setdaterange={setdaterange}
              daterange={daterange}
              {...props}
            />
          )}
        />
      ),
    },
    {
      id: formFields.workCompletedActNumber,
      numeric: false,
      enableSorting: false,
      size: "180px",
      clickable: false,
      isVisible: true, //columnsFromStorage[formFields.objectName],
      RowCell: (props) => <WorkCompletedDownload {...props} />,
      HeaderCell: () => (
        <FormItem
          control={control}
          name={`${formFields.searchParams}.${formFields.workCompletedActNumber}`}
          sx={{
            padding: "9px 0 9px 16px",
            width: "100%",
          }}
          input={(props) => (
            <SearchInbuilt
              placeholder={t("tableHeader:certificateCompletionWork")}
              className="select_in_built small-light"
              searchValue={workCompletedAct}
              changeSearchValue={setworkCompletedAct}
              {...props}
            />
          )}
        />
      ),
    }
  ].filter(({ isVisible }) => isVisible);
