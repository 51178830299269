import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useGetRegionsQuery } from "../../../../../middleware/usersApi";
import { useGetFacilitiesGetTariffsQuery } from "../../../../../middleware/facilitiesApi";
import SelectSimpleSearch from "../../../../components/SelectSimple/SelectSimpleSearch";
import SelectSimple from "../../../../components/SelectSimple";
import DefaultObjectIcon from "../../../../components/icons/Cabinet/DefaultObjectIcon";
import EditPen from "../../../../components/icons/Cabinet/EditPen";
import "./styles.css";

export const SelectSimpleGetRegoinsSearch = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const { data = [] } = useGetRegionsQuery({ name: searchValue });
  const options = data.map(({ name, id }) => ({
    name,
    value: id,
  }));

  return (
    <SelectSimpleSearch
      sx={{ ".MuiTypography-root": { padding: 0 } }}
      {...props}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={options}
    />
  );
};

export const SelectSimpleGetTariffs = (props) => {
  const { data: tariffsInfo } = useGetFacilitiesGetTariffsQuery("");

  const options = tariffsInfo?.map(({ name, tariff }) => ({
    value: tariff,
    name,
  }));

  return (
    <>
      {options?.length && <SelectSimple sx={{ ".MuiTypography-root": { padding: 0 } }} {...props} options={options} />}
    </>
  );
};

export const InputObjectImgFileSingle = (props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const filePicker = useRef(null);

  useEffect(() => {
    props.setValue(props.name, file);
  }, [file]);

  const handleChange = (event) => {
    const newFile = event.target.files[0];
    setFile(newFile);
    console.log(newFile, "newFile");
  };

  const handlePick = () => {
    filePicker.current.value = "";
    filePicker.current.click();
  };

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box className="add-object-upload-file">
        {file instanceof File ? (
          <Box className="object-avatar">
            <img src={URL.createObjectURL(file)} alt="Объект" />
          </Box>
        ) : (
          <Box className="tariff-default-icon">
            <DefaultObjectIcon />
          </Box>
        )}
        <input type="file" className="hidden" ref={filePicker} multiple={false} onChange={handleChange} />
        <Box className="add-object-default-icon icon_button" onClick={handlePick}>
          <EditPen />
        </Box>
        {props.error && (
          <Typography sx={{ position: "absolute", left: 0, right: 0, top: "160px" }} className="error-text">
            {t(`error_message:${props.error.message}`)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
