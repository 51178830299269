import React from "react";

const CheckBoxChecked = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="checkbox-border"
        x="0.5"
        y="0.5"
        width="27"
        height="27"
        rx="3.5"
        stroke="currentColor"
      />
      <path
        className="checkbox-checked-icon"
        d="M20.8609 10.0413C21.0731 9.77193 21.0387 9.37162 20.7841 9.14716C20.5295 8.9227 20.1512 8.95909 19.9391 9.22844L15.6265 14.7041C14.7603 15.8039 14.1509 16.5753 13.6223 17.0803C13.1061 17.5735 12.7498 17.7303 12.4 17.7303C12.0502 17.7303 11.6939 17.5735 11.1777 17.0803C10.6491 16.5753 10.0397 15.8039 9.17348 14.704L8.06095 13.2915C7.84881 13.0221 7.47047 12.9857 7.2159 13.2102C6.96134 13.4346 6.92694 13.835 7.13908 14.1043L8.28249 15.5561C9.11071 16.6077 9.77398 17.4499 10.3735 18.0227C10.9927 18.6143 11.6257 19 12.4 19C13.1743 19 13.8073 18.6143 14.4265 18.0227C15.026 17.4499 15.6893 16.6077 16.5175 15.5561L20.8609 10.0413Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckBoxChecked;
