import { Box } from "@mui/material";

export function getRiskCategory(RiskCategory) {
  switch (RiskCategory) {
    case "Низкий": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--succes);",
            backgroundColor: "var(--succesBg)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    case "Умеренный": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--succes);",
            backgroundColor: "var(--succesBg)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    case "Средний": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--main);",
            backgroundColor: "var(--bgListItem)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    case "Значительный": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--yellow);",
            backgroundColor: "var(--yellowBg)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    case "Высокий": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--orange);",
            backgroundColor: "var(--orangeBg)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    case "Чрезвычайно-высокий": {
      return (
        <Box
          className="panel-risk-category"
          sx={{
            color: "var(--error);",
            backgroundColor: "var(--errorBg)",
          }}
        >
          {RiskCategory}
        </Box>
      );
    }
    default: {
      <Box
        sx={{
          color: "var(--lilac);",
          padding: "8px 12px",
          backgroundColor: "var(--lilacBg)",
          borderRadius: "20px",
        }}
      >
        {RiskCategory}
      </Box>;
    }
  }
}
