// Функции для экспорта данных в формате ПДФ
const base64ToArrayBuffer = (data) => {
  var bString = window.atob(data);
  var bLength = bString.length;
  var bytes = new Uint8Array(bLength);
  for (var i = 0; i < bLength; i++) {
    var ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const base64toPDF = (name, content, setLoading) => {
  var bufferArray = base64ToArrayBuffer(content);
  var blobStore = new Blob([bufferArray], { type: "application/pdf" });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blobStore);
    return;
  }
  var data = window.URL.createObjectURL(blobStore);
  var link = document.createElement("a");
  document.body.appendChild(link);
  link.href = data;
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(data);
  link.remove();
  setLoading(false);
};

export const printBase64 = (content, setPrintLoading) => {
  var bufferArray = base64ToArrayBuffer(content);
  var blob = new Blob([bufferArray], { type: "application/pdf" }); //this make the magic
  var blobURL = URL.createObjectURL(blob);

  let iframe = document.createElement("iframe"); //load content in an iframe to print later
  document.body.appendChild(iframe);

  iframe.style.display = "none";
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
  setPrintLoading(false);
};

// Функция для формирования query строки для фильтрации данных в таблице
export const getQueryString = (filtersRes) => {
  return `${
    "&" +
    Object.keys(filtersRes)
      .map((key) => `${key}=${filtersRes[key]}`)
      .join("&")
  }`;
};
