import React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from "../../../components/icons/CloseIcon";
import "./styles.css";

const ConfirmDeleteNewsModal = ({
  isVisible,
  handleClose,
  newsData,
  deleteNews,
}) => {
  const { title, date, city, id } = newsData || {};

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className="edit-news-modal">
        <Box className="edit-news-modal-header">
          <Typography>Вы уверены что хотите удалить новость?</Typography>

          <Box onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            color: "var(--mainText)",
            mb: "20px"
          }}
        >
          <Typography>
            {new Date(date).toLocaleDateString("ru-RU")}, {city}
          </Typography>
          <Typography>{title}</Typography>
        </Box>

        <Button
          onClick={() => deleteNews(id)}
          className="btn btn-red-fill"
        >
          Удалить
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteNewsModal;
