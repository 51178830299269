import React from "react";

const ArrowCancelBack = ({ isActive }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 20.9995C24.9993 21.7692 24.0729 22.2495 23.3326 21.8641C22.9896 21.6856 22.7782 21.3561 22.7779 20.9995C22.7733 17.1358 19.2939 14.0047 15.0004 14.0006H8.797L11.345 16.2927C11.9509 16.8379 11.6737 17.7689 10.8461 17.9684C10.462 18.061 10.0522 17.9622 9.77104 17.7092L5.32679 13.7098C4.89107 13.3191 4.89107 12.6841 5.32679 12.2934L9.77104 8.29397C10.3769 7.74878 11.4114 7.99823 11.6331 8.74297C11.736 9.08861 11.6262 9.4574 11.345 9.71042L8.797 12.0009H15.0004C20.5205 12.0064 24.9939 16.032 25 20.9995Z"
        fill={isActive ? "#1876d2" : "#d1d8e0"}
      />
    </svg>
  );
};

export default ArrowCancelBack;
