import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Line, Group, Image, Text, Rect, Transformer } from "react-konva";
import { useWatch } from "react-hook-form";
import { Box ,CircularProgress} from "@mui/material";

import { loadImg } from "../../../helpers/helper";
import { getParamsText, getParamsTextWithScale } from "./helpers";
import { ICON_SIZE } from "../../../constants";
import { getEquipmentIcon } from "../../../helpers/getEquipmentIcon";
import { useChartsIcons } from "../../pages/EquipmentDetails/hooks";

import alarm from "../../components/icons/alarm.svg";
import ModalFullScreen from "../../pages/Plume/ImageSchema/ModalFullScreen";
import DonutChart from "../DonutChart";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants";
import { useAction } from "../../../hooks/actions";
import { useAppSelector } from "../../../hooks/redux";
import { debounce } from 'lodash';
let timeScale = null
export const StagingToCanvas = ({
  name,
  width,
  height,
  schemaImg,
  roomAddresses = [],
  groupClick,
  setValue,
  control,
  draggable = false,
  equipGroup = false,
  widthWorkplace,
  heightWorkplace,
  canvasRef,
  imageRef,
  image,
  setImage,
  maxScale,
  minScale,
  title,
  loadScheme,
  fullscreen = false,
  scalecanvas,
  selectedEvent = {},
  setFullScreen,
  scale,
  setScale,
  stageposition,
  setstageposition,
  stageRef,
  setzoomOut,
  zoomOut,
  isChangeFloor,
  setopenEquipDialog,
  setSelectedEquipId,
  roomsColors,
  lineColor
}) => {

  //const canvasRef = useRef(null);
  const { equipmentsCoordinates: data } = useWatch({
    control,
  });
  const [isScrolling, setisScrolling] = useState(false);
  const [isran, setisran] = useState(false);
  const [icons, setIcons] = useState([]);
  const [iconsSize, seticonsSize] = useState({ width: 25, height: 25 });
  const [ChartSize, setChartSize] = useState({ width: 22, height: 22 });
  const [alarmSize, setalarmSize] = useState({ width: 17, height: 17 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [transformCanvasData, setTransformCanvasData] = useState({
    x: 5,
    y: 5
  })
  const navigate = useNavigate();
  const { SCHEMA_CANVAS } = useAppSelector((state) => state.settings);
  const { setCanvasSize } = useAction();
  let timer;
  let wheelTimeout;
  let timeout;
  var prevPosition = -1;
  var scrollStatus = null;
  var result = 0;
  var elements = ["child1", "child2", "child3"];
  var positionn = 0;
  var isInProgress = false;

  const [ChartsList, getSrcSVG] = useChartsIcons({
    Chart: DonutChart,
    equipments: data,
  });

  const handleDragMove = (e) => {
    const stage = canvasRef.current;
    const newX = e.target.x();
    const newY = e.target.y();

    // Ограничиваем перемещение по горизонтали
    const maxX = 0;
    const minX = -(image.width * scale - widthWorkplace);

    // Ограничиваем перемещение по вертикали
    const maxY = 0;
    const minY = -(image.height * scale - heightWorkplace);

    // Если новые координаты выходят за границы, оставляем их на границе
    const clampedX = Math.min(Math.max(newX, minX), maxX);
    const clampedY = Math.min(Math.max(newY, minY), maxY);

    setTransformCanvasData({
      x: clampedX,
      y: clampedY,
    });

  }

  useEffect(() => {
    seticonsSize({ width: 25 / window.devicePixelRatio, height: 25 / window.devicePixelRatio })
    setalarmSize({ width: 17 / window.devicePixelRatio, height: 17 / window.devicePixelRatio })
    setChartSize({ width: 22 / window.devicePixelRatio, height: 22 / window.devicePixelRatio })
  }, [window.devicePixelRatio])
  const drawIcons = async (
    data,
    height = SCHEMA_CANVAS.HEIGHT,
    width = SCHEMA_CANVAS.WIDTH,
    ischangeData
  ) => {
    const stage = stageRef.current;
    if (scale >= minScale && icons.length == 0 || ischangeData || selectedEvent) {

      if (scale >= minScale && data?.length || selectedEvent) {
        const response = await Promise.all(
          data.map(
            async (
              {
                id,
                floorSchemeCoordinateX: x,
                floorSchemeCoordinateY: y,
                typeId,
                workingCorrectly,
                jobChart
              },
              index
            ) => {
              return loadImg(getEquipmentIcon(typeId)).then(async (icon) => {
                const scaleX = width / SCHEMA_CANVAS.WIDTH;
                const scaleY = height / SCHEMA_CANVAS.HEIGHT;
                icon.width = iconsSize.width// Math.min(Math.max(countEquip,8),12);
                icon.height = iconsSize.height //Math.min(Math.max(countEquip,8),12)
                //   console.log("icon",icon.width,icon.height,(scale-minScale))
                // icon.width = icon.width > 7 ? icon.width :7
                // icon.height = icon.height > 7 ? icon.height : 7
                const sizeIcon = icon.width;
                return loadImg(getSrcSVG(index)).then(async (chartIcons) => {
                  return loadImg(alarm).then(async (alarmIcon) => {
                    alarmIcon.width = alarmSize.width;
                    alarmIcon.height = alarmSize.height;
                    let chartWidth = ChartSize.width;
                    let chartHeight = ChartSize.height;
                    const iconWithChart = {
                      id,
                      x: x * scaleX,
                      y: y * scaleY,
                      img: icon,
                      chart: {
                        x: icon.width - icon.width / 5,
                        y: -(icon.width * 2 / 5),
                        img: chartIcons,
                        width: jobChart.totalJobsAmount > 0 ? chartWidth : 0,
                        height: jobChart.totalJobsAmount > 0 ? chartHeight : 0,
                        //  width: jobChart.totalJobsAmount > 0 ? ((scale >= 1) ? 17 / scale * 1.7 : 17 * scale * 1.7) : 0,
                        // height: jobChart.totalJobsAmount > 0 ? ((scale >= 1) ? 17 / scale * 1.7 : 17 * scale * 1.7) : 0,
                      },
                    };

                    const alarm = {
                      x: -(alarmIcon.width / 2),
                      y: sizeIcon - alarmIcon.width / 1.5,
                      img: alarmIcon,
                    };

                    return !workingCorrectly
                      ? { ...iconWithChart, alarm }
                      : iconWithChart;
                  });
                });
              });
            }
          )
        );
        setIcons(response);
      } else {

        setIcons([]);
      }
    } else if (scale < minScale && !ischangeData) {

      setIcons([]);
    }
  };
  const handleShapeMouseMove = (e, text, coordinates) => {

    const mousePos = e.target.getStage().getPointerPosition();
    setTooltipText(text);
    setTooltipPosition({ x: mousePos.x + 5, y: mousePos.y + 5 }); // Обновляем позицию tooltip
    setTooltipVisible(true);


  };

  const handleShapeMouseOut = () => {
    setTooltipVisible(false);
  };
  const drawScheme = async (schemaImg, height, width) => {
    await loadImg(schemaImg).then((image) => {
      setCanvasSize({
        WIDTH: image.width,
        HEIGHT: image.height,
      });
      image.width = width;
      image.height = height;
      setImage(image);
    });
  };

  useEffect(() => {
    schemaImg && drawScheme(schemaImg, height, width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaImg, height, width]);

  const adjustOverlap = (images) => {
    const minimumDistance = 23;
    let adjustedImages = images.map((image) => ({ ...image }));

    for (let i = 0; i < adjustedImages.length; i++) {
      for (let j = 0; j < adjustedImages.length; j++) {
        if (i !== j) {
          let dx = adjustedImages[j].floorSchemeCoordinateX - adjustedImages[i].x;
          let dy = adjustedImages[j].floorSchemeCoordinateY - adjustedImages[i].y;
          let distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < minimumDistance) {
            let angle = Math.atan2(dy, dx);
            let overlap = minimumDistance - distance;
            let offsetX = overlap * Math.cos(angle);
            let offsetY = overlap * Math.sin(angle);

            adjustedImages[j].floorSchemeCoordinateX += offsetX / 2;
            adjustedImages[j].floorSchemeCoordinateY += offsetY / 2;
            adjustedImages[i].floorSchemeCoordinateX -= offsetX / 2;
            adjustedImages[i].floorSchemeCoordinateY -= offsetY / 2;
          }
        }
      }
    }

    return adjustedImages;
  };

  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      drawIcons(newData, height, width, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, SCHEMA_CANVAS]);

  useEffect(() => {
    if (data) {
      const newData = data?.filter(({ floorSchemeCoordinateX, floorSchemeCoordinateY }) =>
        floorSchemeCoordinateX !== null && floorSchemeCoordinateY !== null
      );
      SCHEMA_CANVAS.WIDTH && drawIcons(newData, height, width, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, minScale, data]);

  const handleMouseOver = () => {
    if (draggable) document.body.style.cursor = "pointer";
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
    handleShapeMouseOut()
  };

  const handleDragEnd = ({ id }) => {
    const { x, y } = position || {};
    const scaleX = SCHEMA_CANVAS.WIDTH / width;
    const scaleY = SCHEMA_CANVAS.HEIGHT / height;

    const requestData = data.map((item) => {
      return item.id === id
        ? {
          ...item,
          floorSchemeCoordinateX: x * scaleX,
          floorSchemeCoordinateY: y * scaleY,
        }
        : item;
    });

    setValue && setValue(name, requestData);
  };
  const handleWheel = (e) => {

    const stage = stageRef.current;
    const canvas = canvasRef.current;

    let newScale = scale;
    let stepzoom = 0.1
    if (scale > minScale + 5 / 3 + 0.1) stepzoom = 0.5
    if (scale > 10) stepzoom = 1
    if (scale > 50) stepzoom = 5

    if (e.evt.deltaY < 0) {
      newScale = scale <= minScale + 5 ? scale + stepzoom : scale; // Ограничение максимального зума (2 в данном случае)
    } else {
      newScale = scale >= 0.5 ? scale - stepzoom : scale; // Ограничение минимального зума (0.5 в данном случае)

    }
    const pointTo = {
      x: stage.getPointerPosition().x / scale - canvas.x() / scale,
      y: stage.getPointerPosition().y / scale - canvas.y() / scale,
    };

    setstageposition({
      x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
    //seticonsSize({width:25/,height:25})
    setScale((prev) => newScale);
    clearTimeout(timeScale)
    timeScale = setTimeout(() => {
      if (newScale <= 1 && scale > 1)
        loadScheme(1, 0, 0, false, false)
      if (newScale >= minScale) {
        const coordinates = [-canvas.x() / newScale, -canvas.y() / newScale, (widthWorkplace / newScale - canvas.x() / newScale), (heightWorkplace / newScale - canvas.y() / scale)];
        groupClick(coordinates, null, scalecanvas, height, width, false, true);
      } else if (newScale < minScale && data?.length > 0) {
        loadScheme()
      }
    }, 250)

    /* if (!isScrolling) {
       setisScrolling(true)
 
       // Устанавливаем задержку для вызова API после окончания прокрутки
       setTimeout(() => {
         // Здесь происходит вызов вашего API
         
         setisScrolling(false)// После вызова API сбрасываем флаг
       }, 2000); // Установите желаемую задержку здесь
     }
     
         const stage = stageRef.current;
         let newScale = scale;
         if (e.evt.deltaY < 0 ) {
             newScale = scale <= 5 ? scale + 0.1 : scale; // Ограничение максимального зума (2 в данном случае)
         } else {
           newScale = scale >= 0.5 ? scale - 0.1 : scale; // Ограничение минимального зума (0.5 в данном случае)
         }
         
         
         const pointTo = {
           x: stage.getPointerPosition().x / scale - stage.x() / scale,
           y: stage.getPointerPosition().y / scale - stage.y() / scale,
         };
         setstageposition({
           x: -(pointTo.x - stage.getPointerPosition().x / newScale) * newScale,
           y: -(pointTo.y - stage.getPointerPosition().y / newScale) * newScale,
         }); 
         setScale((prev) => newScale);
     
         
           if (selectedEvent == null) {
             if (newScale <= 1 && scale > 1)
               loadScheme(1, 0, 0, false)
             if (newScale > 2) {
               const coordinates = [-stage.x() / scale, -stage.y() / scale, (width - stage.x()) / scale, (height - stage.y()) / scale];
               groupClick(coordinates, null, scalecanvas, height, width, false);
             } else if(newScale<2){
               loadScheme(undefined, undefined, undefined, false)
             }
           }
        */
  };
  const handleOpenEquipment = (id) => {
    setopenEquipDialog(true);
    setSelectedEquipId(id);
  };
  const handleDragEndStage = (e) => {
    if (scale < minScale) return
    const stage = stageRef.current;
    const canvas = canvasRef.current;

    const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
    groupClick(coordinates, null, scalecanvas, image.height, image.width, false, true);
  }
  const handleClick = (coordinates, id, scalecanvas, height, width) => {
    groupClick(coordinates, id, scalecanvas, heightWorkplace, widthWorkplace, true, true);
  }
  const handleDbl = (e) => {

    clearTimeout(timer);
    setzoomOut(false);
    loadScheme(1, 0, 0, false, false)
  }
  const handleScrollbarMouseUp = (e, typeScroll) => {
    if (typeScroll == "vertical") {
      setstageposition({ ...stageposition, y: -e.target.y() * scale });
    } else {
      setstageposition({ ...stageposition, x: -e.target.x() * scale });
    }
    const canvas = canvasRef.current;

    const coordinates = [-canvas.x() / scale, -canvas.y() / scale, (widthWorkplace / scale - canvas.x() / scale), (heightWorkplace / scale - canvas.y() / scale)];
    groupClick(coordinates, null, scalecanvas, image.height, image.width, false, true);
  };
  const handleScrollbarMouseMove = (e, typeScroll) => {
    const canvas = canvasRef.current
    if (typeScroll == "vertical") {
      canvas.position({ x: canvas.x(), y: -e.target.y() * scale })
    } else {
      canvas.position({ y: canvas.y(), x: -e.target.x() * scale })
    }
  };

  const handleScrollbarMouseDown = (e, direction) => {
    setIsDraggingScrollbar(direction);
    setDragOffset({
      x: e.clientX,
      y: e.clientY,
    });
  };
  const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    // throw new Error('Bad Hex');
  }

  return (
    <Box sx={{ position: "relative", border: "1px solid #CFD9E8", borderRadius: "16px", padding: "10px", width: widthWorkplace - 20, height: heightWorkplace - 20 }}>


      <ChartsList />

      {!!setFullScreen && (
        <ModalFullScreen
          open={fullscreen}
          setOpen={setFullScreen}
          title={title || ""}
          // canvasRef={canvasRef.current?.canvas._canvas || null}
          SCHEMA_CANVAS={SCHEMA_CANVAS}
        />
      )}
      {isChangeFloor ?
        <Box sx={{ height: "100%", alignContent: "center", textAlign: "center" }}><CircularProgress /></Box>
        :
        <Stage
          width={widthWorkplace - 20}
          ref={stageRef}
          onMouseEnter={e => {
            const container = e.target.getStage().container();
            container.style.cursor = 'grab';

          }}

          height={heightWorkplace - 20}
        >
          <Layer
            ref={canvasRef}
            draggable
            onWheel={handleWheel}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEndStage}
            dragBoundFunc={pos => {
              //   console.log("pos",pos)
              const offsetX = (widthWorkplace - image.width * scale)
              const offsetY = (heightWorkplace - image.height * scale)
              //  console.log("stage",pos,image.width*scale,offsetX)

              if (offsetX <= 0) {
                if (pos.x <= offsetX) pos.x = offsetX;
                if (pos.x > 0) pos.x = 0;

              } else {
                if (pos.x > offsetX) pos.x = offsetX;
                if (pos.x <= 0) pos.x = 0;
              }

              if (offsetY <= 0) {
                if (pos.y <= offsetY) pos.y = offsetY;
                if (pos.y > 0) pos.y = 0;

              } else {
                if (pos.y > offsetY) pos.y = offsetY;
                if (pos.y <= 0) pos.y = 0;
              }
              return pos;
            }}

            x={stageposition.x}
            y={stageposition.y}
          >
            <Group
              scaleX={scale}
              scaleY={scale}
            >
              <Image ref={imageRef} image={image} x={0} y={0}

              />

              {roomAddresses?.map(({ id, coordinates, name, plumes, borderColor, color }, index) => {
                let StrokeColor = "green"
                let FillColor = "green"
                if (selectedEvent) {

                  if (selectedEvent?.roomAddressId == id) {
                    StrokeColor = lineColor
                    if (selectedEvent?.color == "white")
                      FillColor = "rgba(212,212,212,0.5)"
                    else FillColor = selectedEvent?.color
                  }
                  else {
                    const ColorZone = roomsColors?.find((item) => item.id == id)
                    if (ColorZone) {
                      StrokeColor = ColorZone.borderColor
                      FillColor = hexToRgbA(ColorZone.color, '0.5')
                    }
                    else {
                      StrokeColor = "#1876D2"
                      FillColor = "rgba(24,118,210,0.3)"
                    }
                  }
                } else {

                  const ColorZone = roomsColors?.find((item) => item.id == id)
                  if (ColorZone) {
                    StrokeColor = ColorZone.borderColor
                    FillColor = hexToRgbA(ColorZone.color, '0.5')
                  }
                  else {
                    StrokeColor = "#1876D2"
                    FillColor = "rgba(24,118,210,0.3)"
                  }
                }


                return (
                  <Group key={id || index}
                    name={`group${id}`}
                    onDblClick={() => handleDbl()}

                    onMouseEnter={e => {

                      const container = e.target.getStage().container();
                      container.style.cursor = 'pointer';

                    }}
                    onMouseLeave={e => {
                      const container = e.target.getStage().container();
                      container.style.cursor = 'grab';

                    }}
                    onMouseMove={(e) => handleShapeMouseMove(e, `${name}`, coordinates)}
                    onMouseOut={handleShapeMouseOut}
                    id={`group${id}`}
                    onClick={(e) => handleClick(coordinates, id, scalecanvas, height, width)}//{ if (!clicked) { groupClick(coordinates, id, scalecanvas, height, width) } }}
                  >
                    <Line
                      points={coordinates}
                      stroke={StrokeColor}
                      fill={FillColor}  //{selectedEvent?.roomAddressId == id ? selectedEvent?.messageCodeTypeColor == "white" ? "rgba(212,212,212,0.5)" : selectedEvent?.messageCodeTypeColor : "rgba(212,212,212,0.5)"}
                      closed={true}
                      strokeWidth={3 / scale}
                      width={3}
                    />
                    {/*<Text {...getParamsTextWithScale({ coordinates, text: name, line: 0, scale })} />
                  <Text {...getParamsTextWithScale({ coordinates, text: plume, line: 1, scale })} />*/}

                  </Group>
                )
              })}
            </Group>

            {!!icons?.length &&
              icons.map(({ id, x, y, img, chart, alarm }, index) => (
                <Group
                  key={id || index}
                  scaleX={1}
                  scaleY={1}
                  onDragEnd={handleDragEnd.bind(null, { id })}
                  // onMouseOut={handleMouseOut}
                  // onMouseOver={handleMouseOver}
                  // onMouseMove={(e) => handleShapeMouseMove(e, name)}
                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'grab';

                  }}
                  dragBoundFunc={(pos) => {
                    if (pos.y <= 0) pos.y = 10;
                    if (pos.y > height - ICON_SIZE.HEIGHT)
                      pos.y = height - ICON_SIZE.HEIGHT;

                    if (pos.x <= 0) pos.x = 1;
                    if (pos.x > width - ICON_SIZE.WIDTH)
                      pos.x = width - ICON_SIZE.WIDTH;

                    setPosition(pos);
                  }}
                  x={x * scale}
                  y={y * scale}
                >
                  <Rect
                    x={0}
                    y={0}
                    width={img.width}
                    height={img.height}
                    fill="rgba(57,57,242,0.4)"

                  />
                  <Image image={img} x={0} y={0} scaleX={1} scaleY={1} onClick={() => { handleOpenEquipment(id) }} />
                  <Image
                    image={chart.img}
                    onClick={() => { navigate(PATHS.LIST_TASKS + `?equip=${id}`) }}
                    x={!isNaN(chart.x) ? chart.x : 0}
                    y={!isNaN(chart.y) ? chart.y : 0}
                    width={chart.width}
                    height={chart.height}
                  />
                  {!!alarm &&
                    <Image
                      image={alarm.img}
                      x={!isNaN(alarm.x) ? alarm.x : 0}
                      y={!isNaN(alarm.y) ? alarm.y : 0}
                    />}
                </Group>
              ))}
          </Layer>
          <Layer>
            <Group>
              {image?.height * scale > heightWorkplace &&
                <Rect
                  width={25}
                  height={heightWorkplace - (image.height * scale - heightWorkplace) / scale}
                  fill="#1876D2"
                  opacity={0.8}
                  x={widthWorkplace - 15 - 20}
                  y={-(transformCanvasData.y) / scale}
                  draggable
                  dragBoundFunc={(pos) => {
                    pos.x = widthWorkplace - 15 - 20;
                    const offsetY = heightWorkplace - (image.height * scale - heightWorkplace) / scale
                    if (pos.y + offsetY > heightWorkplace) pos.y = heightWorkplace - offsetY - 5
                    if (pos.y < 5) pos.y = 5
                    return pos;
                  }}
                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'grab';

                  }}
                  onDragMove={(e) => {
                    handleScrollbarMouseMove(e, "vertical")
                  }}
                  onDragEnd={(e) => { handleScrollbarMouseUp(e, "vertical") }}
                />
              }
              {image?.width * scale > widthWorkplace &&
                <Rect
                  width={widthWorkplace - 20 - (image.width * scale - widthWorkplace - 20) / scale}
                  height={25}
                  fill="#1876D2"
                  opacity={0.8}
                  y={heightWorkplace - 20 - 15}
                  x={-(transformCanvasData.x) / scale}
                  draggable
                  dragBoundFunc={(pos) => {
                    pos.y = heightWorkplace - 20 - 15;
                    const offsetX = widthWorkplace - (image.width * scale - widthWorkplace) / scale
                    if (pos.x + offsetX > widthWorkplace) pos.x = widthWorkplace - offsetX - 5
                    if (pos.x < 5) pos.x = 5
                    return pos;
                  }}
                  onMouseEnter={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';

                  }}
                  onMouseLeave={e => {

                    const container = e.target.getStage().container();
                    container.style.cursor = 'grab';

                  }}
                  onDragMove={(e) => {
                    handleScrollbarMouseMove(e, "horizontal")
                  }}
                  onDragEnd={(e) => { handleScrollbarMouseUp(e, "horizontal") }}
                />
              }
            </Group>

          </Layer>
          <Layer>
            {tooltipVisible && (
              <Text
                text={tooltipText}
                fontSize={14}
                padding={10}
                textFill="white"
                fill="black"
                fontStyle="bold"
                alpha={1}
                visible={tooltipVisible}
                x={tooltipPosition.x} // Устанавливаем позицию по x
                y={tooltipPosition.y} // Устанавливаем позицию по y
              />
            )}

          </Layer>
        </Stage>
      }
    </Box>

  );
};
