import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import EquipmentDialog from "../EquipmentList/EquipDialog";
import { ModalSelectColumnsServer } from "../../components/Modals/ModalSelectColumnsServer";
import TableBasic from "../../components/TableBasic";
import { useLazyGetRegulationsListQuery } from "../../../middleware/regulationsApi";
import EditButton from "../../components/EditButton";
import { ModalEquipSchema } from "../../components/Modals/ModalEquipSchema";
import { useAppSelector } from "../../../hooks/redux";
import { PATHS } from "../../../constants";
import { getColumns } from "./columns";
import { useLazyGetFloorOptionsSelectByFacilityQuery } from "../../../middleware/facilitiesApi";
import {
  useGetUserGridSettingQuery,
  useLazyGetUserGridSettingQuery,
} from "../../../middleware/usersApi";
import { formFields, getConfigFormModalSelect } from "./form-config";
import PageheaderNav from "../../components/PageheaderNav";
import plusicon from "../../../image/icons/plusBtnWhite.png";
import { getTableHieght } from "../../../helpers/getTableHieght";
import useScreenSize from "../../../hooks/useScreenSize";
import { itemsNav } from "./constants";

const ReglamentList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getParams] = useSearchParams();
  const objectId = getParams.has("objectId") ? getParams.get("objectId") : null;
  const { regulations } = useAppSelector((state) => state.regulations);
  const { control, setValue, watch, reset } = useForm();
  const [pageNumber, changePageNumber] = useState(0);
  const [ranOnce2, setRanOnce2] = useState(false);
  const [openSchema, setOpenSchema] = useState(false);
  const [openEquipDialog, setopenEquipDialog] = useState(false);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [selectedEquipId, setSelectedEquipId] = useState(null);

  const {
    searchEquipmentCountStorage,
    searchStandardNameStorage,
    searchPeriodNameStorage,
    searchEquipmentNameStorage,
    searchRegulationNameStorage,
    searchWorkDetailesStorage,
    searchWorkscopeNameStorage,
  } = JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};

  const [searchEquipmentCount, setSearchEquipmentCount] = useState(
    searchEquipmentCountStorage
  );
  const [searchStandardName, setSearchStandardName] = useState(
    searchStandardNameStorage
  );
  const [searchPeriodName, setSearchPeriodName] = useState(
    searchPeriodNameStorage
  );
  const [searchEquipmentName, setSearchEquipmentName] = useState(
    searchEquipmentNameStorage
  );
  const [searchRegulationName, setSearchRegulationName] = useState(
    searchRegulationNameStorage
  );
  const [searchWorkDetailes, setSearchWorkDetailes] = useState(
    searchWorkDetailesStorage
  );
  const [searchWorkscopeName, setSearchWorkscopeName] = useState(
    searchWorkscopeNameStorage
  );
  const screenSize = useScreenSize();

  const [getUserGrid] = useLazyGetUserGridSettingQuery();
  const [getRegulationsTable, { isLoading }] = useLazyGetRegulationsListQuery();
  const [getFloorOptions] = useLazyGetFloorOptionsSelectByFacilityQuery();
  const { data: gridcolumns } = useGetUserGridSettingQuery({
    gridSettingName: "EquipmentList",
  });

  const columnsFromStorage = (() => {
    const storageData = gridcolumns?.columnSettings || [];
    return storageData?.reduce(
      (acc, { name, visible }) => ({ ...acc, [name]: visible }),
      {}
    );
  })();

  const params = useWatch({
    control,
    name: formFields.searchParams,
  });

  const facilityId = watch(
    `${formFields.searchParams}.${formFields.protectionObjectId}`
  );

  function writeSearchDataToLocalStorage(newSearchParam, searchValue) {
    const getStorage =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    const searchParamsForLocalStorage = {
      ...getStorage,
      [newSearchParam]: searchValue,
    };
    sessionStorage.setItem(
      "reglamentsListSearchParams",
      JSON.stringify(searchParamsForLocalStorage)
    );
  }

  useEffect(() => {
    const { protectionObjectIdStorage, isCustomReglamentStorage, equipmentKindIdStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
      setValue(`${formFields.searchParams}.${formFields.protectionObjectId}`, protectionObjectIdStorage);
      setValue(`${formFields.searchParams}.${formFields.isCustom}`, isCustomReglamentStorage);
      setValue(`${formFields.searchParams}.${formFields.equipmentKindId}`, equipmentKindIdStorage);
  }, []);

  function clearFiltersHandler() {
    setSearchEquipmentCount();
    setSearchStandardName();
    setSearchPeriodName();
    setSearchEquipmentName();
    setSearchRegulationName();
    setSearchWorkDetailes();
    setSearchWorkscopeName();
    reset()
    sessionStorage.removeItem("reglamentsListSearchParams");
  }

  useEffect(() => {
    const { searchEquipmentCountStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.equipmentCount`,
      searchEquipmentCountStorage
    );
  }, [searchEquipmentCount, setValue]);

  useEffect(() => {
    writeSearchDataToLocalStorage('protectionObjectIdStorage', facilityId);
  }, [facilityId])

  useEffect(() => {
    const { searchStandardNameStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.StandardName`,
      searchStandardNameStorage
    );
  }, [searchStandardName, setValue]);

  useEffect(() => {
    const { searchPeriodNameStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(`${formFields.searchParams}.PeriodName`, searchPeriodNameStorage);
  }, [searchPeriodName, setValue]);

  useEffect(() => {
    const { searchEquipmentNameStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.EquipmentName`,
      searchEquipmentNameStorage
    );
  }, [searchEquipmentName, setValue]);

  useEffect(() => {
    const { searchRegulationNameStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.RegulationName`,
      searchRegulationNameStorage
    );
  }, [searchRegulationName, setValue]);

  useEffect(() => {
    const { searchWorkDetailesStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.WorkRequirementsDetails`,
      searchWorkDetailesStorage
    );
  }, [searchWorkDetailes, setValue]);

  useEffect(() => {
    const { searchWorkscopeNameStorage } =
      JSON.parse(sessionStorage.getItem("reglamentsListSearchParams")) || {};
    setValue(
      `${formFields.searchParams}.WorkscopeName`,
      searchWorkscopeNameStorage
    );
  }, [searchWorkscopeName, setValue]);

  useEffect(() => {
    let filtersRes = {};
    if (!params) return;

    Object.keys(params).forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] !== undefined)
        if (key === "equipmentKindId") {
          if (JSON.parse(params[key])?.isType) {
            filtersRes["EquipmentKindId"] = Number(
              JSON.parse(params[key]).id.replace("parent", "")
            );
          } else {
            filtersRes["EquipmentTypeId"] = JSON.parse(params[key]).id;
          }
        } else if (key === "lifeTimeDate" || key === "workingCorrectly") {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else {
          filtersRes[key] = params[key];
        }
    });

    const queryString =
      "&" +
      Object.keys(filtersRes)
        .map((key) => `${key}=${filtersRes[key] || ""}`)
        .join("&");

    getRegulationsTable({
      PageNumber: 0,
      filters: queryString,
    });
  }, [params]);

  useEffect(() => {
    let filtersRes = {};
    if (!params) return;

    Object.keys(params)?.forEach((key) => {
      if (params[key] !== "все" && params[key] !== "" && params[key] !== undefined)
        if (key === "equipmentKindId") {
          filtersRes[key] = JSON.parse(params[key]).id;
        } else if (
          key === "explotationExceeded" ||
          key === "workingCorrectly"
        ) {
          filtersRes[key] = params[key] == "true" ? true : false;
        } else {
          filtersRes[key] = params[key];
        }
    });

    const queryString =
      "&" +
      Object.keys(filtersRes)
        .map((key) => `${key}=${filtersRes[key] || ""}`)
        .join("&");

    getRegulationsTable({
      PageNumber: pageNumber,
      filters: queryString,
    });
  }, [pageNumber]);

  useEffect(() => {
    if (facilityId) {
      if (objectId && !ranOnce2) {
        setRanOnce2(true);
        setValue(
          `${formFields.searchParams}.${formFields.protectionObjectId}`,
          objectId
        );
      }
      if (facilityId === "все") {
        setValue(`${formFields.searchParams}.${formFields.floorId}`, "все");
      } else {
      }
    }
  }, [facilityId, objectId, getFloorOptions, setValue]);

  const columns = useMemo(
    () =>
      getColumns({
        control,
        searchEquipmentCount,
        setSearchEquipmentCount,
        searchStandardName,
        setSearchStandardName,
        searchPeriodName,
        setSearchPeriodName,
        searchEquipmentName,
        setSearchEquipmentName,
        searchRegulationName,
        setSearchRegulationName,
        searchWorkDetailes,
        setSearchWorkDetailes,
        searchWorkscopeName,
        setSearchWorkscopeName,
        writeSearchDataToLocalStorage,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsFromStorage]
  );

  const rows = useMemo(() => {
    const { data } = regulations;
    return data?.length
      ? data.map(
          ({
            id,
            equipmentCount,
            protectionObjectId,
            regulationId,
            equipmentKindId,
            equipmentKindName,
            regulationDescription,
            periodName,
            equipmentName,
            equipmentTypeName,
            equipmentTypeId,
            equipmentGroup,
            isCustom,
            protectionObjectName,
            regulationName,
            workingProperly,
            standardName,
            standardParagraphName,
            workRequirementsDetails,
            workscopeName,
          }) => {
            return {
              id,
              equipmentCount,
              equipmentKindName: `${equipmentKindName}, ${equipmentTypeName}`,
              equipmentName: {
                id,
                name: equipmentName,
                action: () =>
                  navigate(
                    PATHS.LIST_EQUIPMENT + `?equipmentGroupId=${equipmentGroup}`
                  ),
              },
              equipmentTypeName,
              isCustom: isCustom ? "Пользователь" : "Стандарт",
              regulationDescription,
              periodName,
              protectionObjectName,
              regulationName,
              workingProperly,
              standardName,
              standardParagraphName,
              workRequirementsDetails,
              workscopeName,
              edit: (
                <EditButton
                  handleClick={() =>
                    navigate(
                      PATHS.GET_EDIT_EQUIPMENT_REGLAMENTS(
                        id,
                        regulationId,
                        protectionObjectId,
                        equipmentKindId,
                        equipmentTypeId,
                        equipmentGroup
                      )
                    )
                  }
                />
              ),
            };
            /* return {
          id,
          integrationNumber,
          number,
          name:{
            id,
            name,
            action: () => handleOpenEquipment(id),
          },
          plume,
          floorNumber: {
            id,
            floorNumber,
            action: () => handleOpenSchema(id),
          },
          protectionObjectName,
          lifeTimeDate: <Typography sx={{ color: lefeTimeOutdated ? "red" : "black", fontSize: "14px !important" }}>{lifeTimeDate ? dayjs(lifeTimeDate).format(dateFormat) : ""}</Typography>,
          lastServiceDate: lastServiceDate ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>{dayjs(lastServiceDate).format(dateFormat)}</Typography> : <></>,
          workingProperly: workingProperly ? <Typography sx={{ color: "green", fontSize: "14px !important" }}>Да</Typography> : <Typography sx={{ color: "red", fontSize: "14px !important" }}>Нет</Typography>,
          equipmentKindName,
          edit: enableForEdit ? <EditButton handleClick={() => navigate(PATHS.GET_EQUIPMENT_DETAILS(id))} /> : <></>,
          tasks: getDataToChart({ jobChart, action: `?facilityName=${protectionObjectId}&equipmentId=${id}&equipmentName=${name}` }),
        };*/
          }
        )
      : [];
  }, [regulations]);

  const recordsCount = useMemo(
    () => regulations && regulations?.recordsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regulations]
  );

  return (
    <Box className="template_page">
      <Typography className="title-page">
        {t("title_page:digitization")}
      </Typography>
      <Box className="template_page-header">
        <PageheaderNav itemsNav={itemsNav} />
        <Box>
          <Button
            className="btn btn-primary-fill"
            variant="outlined"
            onClick={() => {
              navigate(PATHS.ADD_EQUIPMENT_REGLAMENTS);
            }}
          >
            <Box component="img" src={plusicon} sx={{ marginRight: "16px" }} />
            {t("button:add")}
          </Button>
        </Box>
      </Box>
      <TableBasic
        columns={columns}
        rows={rows}
        handleClickCell={() => {}}
        withSearchField={false}
        isLoading={isLoading}
        pageNumber={pageNumber}
        changePageNumber={changePageNumber}
        clearFiltersHandler={clearFiltersHandler}
        count={recordsCount}
        minHeight={getTableHieght(screenSize).min}
        maxHeight={getTableHieght(screenSize).max}
      />

      {openEquipDialog && (
        <EquipmentDialog
          open={openEquipDialog}
          setOpen={setopenEquipDialog}
          equipId={selectedEquipId}
          refetch={() => getRegulationsTable({ pageNumber })}
        />
      )}

      {openSchema && (
        <ModalEquipSchema
          open={openSchema}
          setOpen={setOpenSchema}
          control={control}
          setValue={setValue}
          equipId={selectedEquipId}
          refetch={() => getRegulationsTable({ pageNumber })}
        />
      )}

      {openSelectModal && (
        <ModalSelectColumnsServer
          open={openSelectModal}
          data={columnsFromStorage}
          nameStorage={"EquipmentList"}
          setOpen={setOpenSelectModal}
          config={getConfigFormModalSelect(gridcolumns?.columnSettings || [])}
          refetch={() => getUserGrid({ gridSettingName: "EquipmentList" })}
        />
      )}
    </Box>
  );
};

export default ReglamentList;
