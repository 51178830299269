import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import AttentionIcon from "../../../../components/icons/AttentionIcon";
import CloseIcon from "../../../../components/icons/CloseIcon";
import InputTextarea from "../../../../components/InputTextarea";
import { useDeleteFacilityByRequestMutation } from "../../../../../middleware/facilitiesApi";
import { useAction } from "../../../../../hooks/actions";
import SuccessRequestIcon from "../../../../components/icons/Cabinet/SuccessRequestIcon";

export const DeleteObjectModal = ({ isVisible, handleClose, facilityId }) => {
  const { setIsErrorRequest, setIsSuccessSubmit } = useAction();
  const [isSendRequestOnDelete, setIsSendRequestOnDelete] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [deleteReason, setDeleateReason] = useState("");
  const [deleteFacilityByRequest, { isError: isErrorDeleteObject, isSuccess: isSuccessDeleteObject }] =
    useDeleteFacilityByRequestMutation();

  function reasonTextHandler(value) {
    setDeleateReason(value);
  }

  function sendRequest() {
    deleteFacilityByRequest({ facilityId: facilityId, reason: deleteReason }).then((_) => setIsSuccessMessage(true));
  }

  useEffect(() => {
    setIsSuccessSubmit(isSuccessDeleteObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleteObject]);

  useEffect(() => {
    setIsErrorRequest(isErrorDeleteObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorDeleteObject]);

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ backgroundColor: "rgba(216, 224, 233, .8)", padding: "24px 24px 35px 24px" }}>
        <Box sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }} onClick={handleClose}>
          <CloseIcon />
        </Box>

        {!isSuccessMessage ? (
          !isSendRequestOnDelete ? (
            <>
              <DialogContent sx={{ padding: "16px 0 32px 0" }}>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ textAlign: "center", color: "var(--error)", width: "331px" }}
                >
                  Удаляя объект из счета, вы отключаете его от обслуживания по окончании оплаченного периода
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ padding: "0" }}>
                <Button
                  onClick={() => setIsSendRequestOnDelete(true)}
                  className="btn btn-primary-fill magazine_events-select_container-btn"
                  sx={{ margin: "auto" }}
                >
                  Подтвердить
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogContent sx={{ padding: "16px 0 32px 0" }}>
                <DialogContentText
                  id="alert-dialog-description"
                  sx={{ textAlign: "center", color: "var(--mainText)", marginBottom: "32px", width: "400px" }}
                >
                  Для удаления объекта, пожалуйста, подробно опишите причину удаления:
                </DialogContentText>
                <Box sx={{"& .MuiInputBase-root": { borderRadius: '16px !important' }, "& textarea": {width: '98% !important'}}}>
                  <InputTextarea
                    label="label:deleteReason"
                    placeholder="placeholder:enterText"
                    type="text"
                    onChange={(e) => reasonTextHandler(e.target.value)}
                    value={deleteReason}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ padding: "0" }}>
                <Button
                  onClick={sendRequest}
                  className="btn btn-primary-fill magazine_events-select_container-btn"
                  sx={{ margin: "auto" }}
                >
                  Отправить
                </Button>
              </DialogActions>
            </>
          )
        ) : (
          <>
            <DialogContent sx={{ padding: "0 0 24px 0" }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                <SuccessRequestIcon />
              </Box>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ textAlign: "center", color: "var(--mainText)", width: "400px" }}
              >
                Ваша заявка отправлена на рассмотрение администратору системы. Мы свяжемся с Вами в самое ближайшее
                время
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: "0" }}>
              <Button
                onClick={handleClose}
                className="btn btn-primary-fill magazine_events-select_container-btn"
                sx={{ margin: "auto" }}
              >
                Закрыть
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export const NotAccessDeleteObjectModal = ({ isVisible, handleClose }) => {
  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ backgroundColor: "rgba(216, 224, 233, .8)", padding: "24px 24px 42px 24px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "end", cursor: "pointer", color: "var(--mainText)" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Box>

        <DialogContent sx={{ padding: "8px 0 0 0" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "9px",
              width: "331px",
              marginBottom: "24px",
              textAlign: "center",
              color: "var(--error)",
              fontWeight: 600,
              "& svg": { width: "18px", height: "18px" },
            }}
          >
            <AttentionIcon />
            Удаление объекта невозможно
          </DialogContentText>

          <DialogContentText
            id="alert-dialog-description2"
            sx={{ textAlign: "center", color: "var(--mainText)", width: "250px", margin: "auto" }}
          >
            Обратитесь к <span style={{ color: "var(--accent)" }}>админисратору</span> системы ВДПО PRO
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
