import { useEffect, useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";

import { useGenerateFullfillBillingDocumentMutation, useLazyGetBalanceQuery } from "../../../../middleware/billingApi";
import { useAction } from "../../../../hooks/actions";
import CloseIcon from "../../../components/icons/CloseIcon";
import FormItem from "../../../components/FormItem";
import { amountNumberField } from "./form-config";
import "./styles.css";

const PayBox = ({ isOpenPayForm, toggleVisiblePayform }) => {
  const [getBalance] = useLazyGetBalanceQuery();
  const [balaceInfo, setBalanceInfo] = useState();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const { setIsErrorSubmit, setIsSuccessSubmit } = useAction();

  const base64toPDF = (data) => {
    const { name, contentType, content } = data
    var bufferArray = base64ToArrayBuffer(content);
    var blobStore = new Blob([bufferArray], { type: `application/${contentType}` });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    setLoading(false);
  };

  const base64ToArrayBuffer = (data) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const [generateFullfillBillingDocument, { isError: isErrorBill, isLoading: isLoadingBill, isSuccess: isSuccessBill }] =
    useGenerateFullfillBillingDocumentMutation();

  useEffect(() => {
    setIsSuccessSubmit(isSuccessBill);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessBill]);

  useEffect(() => {
    setIsErrorSubmit(isErrorBill);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorBill]);

  const onSubmit = (data) => {
    setLoading(true);
    generateFullfillBillingDocument({ amount: +data.amount }).then(({ data }) => {
      toggleVisiblePayform(false);
      base64toPDF(data)
      reset();
    });
  };

  useEffect(() => {
    getBalance()
      .then((data) => setBalanceInfo(data.data))
      .catch((error) => console.error(error));

    const interval = setInterval(() => {
      getBalance().then((data) => setBalanceInfo(data.data));
    }, 15000); // Вызов функции callApi каждые 15 секунд

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function convertData(isoDate) {
    const normalDate = new Date(isoDate);
    return normalDate.toLocaleDateString("ru-Ru");
  }

  const numberFormat = new Intl.NumberFormat("ru-RU");

  return (
    <>
      {balaceInfo && (
        <Box className="pay_box">
          {!isOpenPayForm ? (
            <>
              <Box className="pay_box_upblock">
                <Box>
                  <Typography className="pay_box_label">Баланс</Typography>
                  <Typography className="pay_box_balance">{numberFormat.format(balaceInfo.balance)} ₽</Typography>
                </Box>
                <Button
                  className="btn btn-primary-fill magazine_events-select_container-btn"
                  variant="outlined"
                  onClick={() => toggleVisiblePayform(true)}
                  sx={{ height: "45px", padding: "13px 24px !important" }}
                >
                  Пополнить
                </Button>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box>
                  {balaceInfo.writeOffDate && <Typography className="pay_box_label" sx={{ p: "12px 0px 10px 0" }}>
                    {convertData(balaceInfo.writeOffDate)} спишем {numberFormat.format(balaceInfo.writeOffAmount)} ₽
                  </Typography>
                  }
                  <Typography sx={{ color: "var(--error)", fontSize: "14px" }}>
                    Минимальная сумма пополнения {numberFormat.format(balaceInfo.fullFillAmount)} ₽
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="pay_box_upblock">
                <Box sx={{ display: "flex", alignItems: "flex-end", gap: "9px" }}>
                  <Box>
                    <Typography className="pay_box_label" sx={{ marginBottom: "6px" }}>
                      Счет на
                    </Typography>
                    <form id="amountForm" onSubmit={handleSubmit(onSubmit)} className="pay_box_form">
                      {amountNumberField.map(({ id, name, input, ...rest }) => (
                        <FormItem key={id} control={control} errors={errors} name={name} input={input} {...rest} />
                      ))}
                    </form>
                  </Box>
                  <Typography sx={{ color: "var(--error)", fontSize: "12px" }}>
                    Минимальная сумма {numberFormat.format(balaceInfo.fullFillAmount)} ₽
                  </Typography>
                </Box>

                <Box className="pay_box_close cursor" onClick={() => toggleVisiblePayform(false)}>
                  <CloseIcon />
                </Box>
              </Box>
              <Box sx={{ width: "100%", paddingTop: "12px" }}>
                <Button
                  className="btn btn-primary-fill magazine_events-select_container-btn"
                  variant="outlined"
                  type="submit"
                  form="amountForm"
                  disabled={loading || isLoadingBill}
                  sx={{ height: "39px", width: "100%", fontSize: "14px !important" }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Скачать счет"
                  )}

                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default PayBox;
