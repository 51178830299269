import React from "react";

const EditPen = () => {
  return (
    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9944 1.74194C13.0003 1.15153 12.1713 0.659198 11.4621 0.359385C10.7136 0.0429311 9.96782 -0.118193 9.17289 0.100898C8.37797 0.319988 7.81045 0.843067 7.31601 1.5021C6.84757 2.12648 6.36898 2.97919 5.79505 4.00178L0.843413 12.8234C0.510508 13.4153 0.23857 13.8989 0.10112 14.4182C0.0814843 14.4924 0.0645934 14.5673 0.0506613 14.6433C-0.0607931 15.2518 0.0301568 15.8627 0.145181 16.6352L0.176176 16.8441C0.388501 18.2783 0.563512 19.4605 0.827471 20.3569C1.10353 21.2945 1.52791 22.106 2.36255 22.6017C3.1972 23.0974 4.09265 23.0696 5.02007 22.8468C5.90683 22.6337 6.98967 22.1985 8.30338 21.6705L8.4947 21.5937C9.20268 21.3099 9.76247 21.0854 10.219 20.6819C10.6756 20.2784 10.9756 19.743 11.355 19.0658L16.3065 10.2441C16.8805 9.22157 17.3591 8.36888 17.6506 7.63941C17.9583 6.86945 18.1149 6.10237 17.9019 5.28472C17.6889 4.46706 17.1804 3.88331 16.5397 3.37473C15.9326 2.8929 15.1036 2.40062 14.1095 1.81028L13.9944 1.74194ZM14.1345 10.1712L6.94242 5.90017L2.5685 13.6927C2.092 14.5416 1.97736 14.7715 1.93398 15.0083C1.8906 15.2451 1.91604 15.5022 2.05954 16.4713C2.28599 18.0007 2.44241 19.041 2.66165 19.7856C2.87344 20.5048 3.09183 20.7586 3.32078 20.8945C3.54973 21.0305 3.87257 21.0982 4.58406 20.9272C5.32058 20.7502 6.27468 20.3694 7.67557 19.8064C8.56324 19.4497 8.79238 19.3438 8.97009 19.1868C9.14779 19.0297 9.28403 18.8127 9.76054 17.9637L14.1345 10.1712ZM15.0907 8.46288C15.4498 7.81202 15.7087 7.31379 15.878 6.89005C16.1042 6.32409 16.1117 6.02886 16.0508 5.79492C15.9898 5.56097 15.8398 5.30917 15.3688 4.93534C14.8758 4.54403 14.1604 4.11671 13.0937 3.48327C12.0271 2.84983 11.3095 2.42614 10.7336 2.18266C10.1834 1.95005 9.89635 1.9423 9.66891 2.00499C9.44147 2.06767 9.19667 2.22199 8.83323 2.70642C8.56112 3.06911 8.2711 3.5489 7.90265 4.1942L15.0907 8.46288Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditPen;
