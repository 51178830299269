import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { getDataToChart } from "../../../../helpers/helper";
import FormItem from "../../../components/FormItem";
import { useTranslation } from "react-i18next";
import TaskStateList from "../../../components/TaskStateList/TaskStateList";

import { settingsEquipment } from "../form-config";

const BasicInformationForm = ({ control, errors, config, setValue, getValues, data }) => {
  const { t } = useTranslation();

  const dataToolTip = useMemo(() =>
    data ? getDataToChart({
      jobChart: data?.jobChart,
      action: `?equipmentId=${data?.id}&equipmentName=${data?.name}`,
    }) : [],
    [data],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "baseline",
          gap: '20px'
        }}
      >
        {config.map(({ name, title, label, input, Component, flexBasis, ...rest }, i) => (
          Component
            ? <Component
              key={i}
              data={data}
              control={control}
              setValue={setValue}
            />
            : ((name != "plume" && name != "integrationNumber" && name != "keyType" && name != "techniсalConnection") || ((name == "integrationNumber" || name == "plume" || name == "keyType" || name == "techniсalConnection") && data?.hasIntegration)) && (
              <Box key={i} className="resizing_basic_forms">
                {!!title && <Typography className="label">{t(title)}</Typography>}
                <FormItem
                  control={control}
                  errors={errors}
                  name={name}
                  setValue={setValue}
                  getValues={getValues}
                  label={label}
                  input={input}
                  {...rest}
                />
              </Box>
            )
        ))}
        {settingsEquipment.map(({ name, title, label, input, Component, flexBasis, ...rest }, i) => (
          Component
            ? <Component
              key={i}
              data={data}
              control={control}
              setValue={setValue}
            />
            : (name != "techniсalConnection" && name != "generateTasks") || ( (name == "generateTasks" || name == "techniсalConnection") && data?.hasIntegration)) && (
            <Box key={i} sx={{width:'100%',ml:"10px"}}>
              {!!title && <Typography className="label">{t(title)}</Typography>}
              <FormItem
                control={control}
                errors={errors}
                name={name}
                setValue={setValue}
                getValues={getValues}
                label={label}
                input={input}
                {...rest}
              />
            </Box>
          )
        )}
      </Box>
      <TaskStateList
        data={dataToolTip}
        lineHeight="24px"
        height="8px"
        width="8px"
        fontWeight="500"
        isShowAlternativeName={false}
      />
    </>
  );
};

export default BasicInformationForm;
